import styled from "styled-components";

const ChartAnimateProgress = styled.input`
	flex: 1;
	height: 4px;
	margin: 0 16px;
	background: rgba(0, 0, 0, 0.15);
	border: 0;
	border-radius: 4px;
	-webkit-appearance: none;

	&::-webkit-slider-runnable-track,
	&::-webkit-slider-thumb,
	&::-moz-range-thumb,
	&::-moz-range-progress,
	&::-moz-range-track,
	&::-ms-fill-lower,
	&::-ms-fill-upper {
		width: 20px;
		height: 20px;
		-webkit-appearance: none;
		cursor: pointer;
		background: red !important;
	}

	&:focus {
		outline: none;
	}
`;

export default ChartAnimateProgress;
