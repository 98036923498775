import styled from 'styled-components';
import { transitionDefault, transitionOpacity } from 'styles/Styles';

const UnorderedListStartingText = styled.p`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};
	/*margin: 0;*/
	/*margin: 0 0 24px 0;*/
	margin: 20px 0 0px 0; // double check these margins
	/*border: 1px solid blue;*/
	
	padding: 0;

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};

	${transitionOpacity}

	a {
		text-decoration: none;
		font-weight: 600;
		color: inherit;
		border-bottom: 1px solid transparent;

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.33);
		}

		${transitionDefault}
	}
`;

export default UnorderedListStartingText;