import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const Input = styled.div`
	position: relative;
	margin: 0 0 32px 0;
	opacity: ${(props) => (props.isFaded ? 0.4 : 1)};

	${transitionDefault}

	&:last-of-type {
		margin: 0;
	}

	/*background: rgba(255, 0, 0, 0.33);*/
`;

export default Input;
