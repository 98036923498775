import { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";
import axios from "util/axios/server-instance";
import { auth } from "services/firebase";

import { transitionSpring } from "Constants";
import { transitionDefault } from "styles/Styles";
import { Spacer24, Spacer48 } from "components/Spacer";

import ContainerSidebar from "./ContainerSidebar";
import SidebarContent from "./SidebarContent";
import SidebarLogo from "./SidebarLogo";
import SidebarItemActive from "./SidebarItemActive";
import SidebarItemSingle from "./SidebarItemSingle";
import SidebarItemGroup from "./SidebarItemGroup";
import SidebarCurrentUser from "./SidebarCurrentUser";
import ValidationTool from "./ValidationTool";

function Sidebar() {
  const theme = useContext(ThemeContext);
  const animateItemActive = useAnimation();
  const [dataIndex, setDataIndex] = useState(0);
  const [pathname, setPathname] = useState("/");
  const [name, setName] = useState("");
  const location = useLocation();

  let isActive = null,
    colorActive = null;

  const data = [
    { route: "/summary", colorActive: null, isGroup: false },
    { route: "/growth", colorActive: theme.colors.accentGrowth, isGroup: true },
    {
      route: "/stickiness",
      colorActive: theme.colors.accentStickiness,
      isGroup: true
    },
    {
      route: "/retention",
      colorActive: theme.colors.accentRetention,
      isGroup: true
    },
    // { route: "/dashboard", colorActive: null, isGroup: false },
    // { route: "/discussion", colorActive: null, isGroup: false },
    { route: "/company-settings", colorActive: null, isGroup: false }
    // { route: '/personal-settings', colorActive: null, isGroup: true }, // <- don't uncomment this, this breaks
  ];

  const indexOfRoute = pathname => {
    let routeIndex = -1;
    data.map((item, index) => {
      if (item.route == pathname || pathname.includes(item.route))
        routeIndex = index;
    });
    return routeIndex;
  };

  useEffect(() => {
    auth.currentUser.getIdToken(true).then(IdToken => {
      console.log(IdToken);
      return axios
        .get("user", {
          headers: { Authorization: `Bearer ${IdToken}` }
        })
        .then(response => {
          // console.log("res", response);
          // alert(response.data.name);
          // alert(response.data.vertical);
          // alert(response.data.size);
          setName(response.data.name);
          // setRole(response.data.role);
        })
        .catch(error => {
          console.log("error", error);
        });
    });
  });

  useEffect(() => {
    if (pathname != location.pathname) {
      setPathname(location.pathname);

      let routeIndex = indexOfRoute(location.pathname);
      if (dataIndex != routeIndex && routeIndex >= 0) {
        setDataIndex(routeIndex);

        animateItemActive.start({
          y: routeIndex * 44,
          transition: transitionSpring
        });
      }
    }
  });

  return (
    <ContainerSidebar>
      <SidebarLogo company="picsart" />
      <SidebarContent>
        <motion.div
          animate={animateItemActive}
          initial={{ position: "absolute", top: 0 }}
        >
          <SidebarItemActive
            isActive={isActive}
            colorActive={data[dataIndex].colorActive}
            isGroup={
              location.pathname == "/personal-settings" ||
              data[dataIndex].isGroup
            }
          />
        </motion.div>
        <SidebarItemSingle
          title="Executive Summary"
          route={data[0].route}
          colorActive={data[0].colorActive}
        />
        <SidebarItemGroup
          title="Growth"
          route={data[1].route}
          colorActive={data[1].colorActive}
        />
        <SidebarItemGroup
          title="Stickiness"
          route={data[2].route}
          colorActive={data[2].colorActive}
        />
        <SidebarItemGroup
          title="Retention"
          route={data[3].route}
          colorActive={data[3].colorActive}
        />
        {/* <SidebarItemSingle */}
        {/*   title="Dashboard View" */}
        {/*   route={data[4].route} */}
        {/*   colorActive={data[4].colorActive} */}
        {/* /> */}
        {/* <SidebarItemSingle */}
        {/*   title="Discussion Forum" */}
        {/*   route={data[5].route} */}
        {/*   colorActive={data[5].colorActive} */}
        {/* /> */}
        <SidebarItemSingle
          title="Company Settings"
          route={data[4].route}
          colorActive={data[4].colorActive}
        />
      </SidebarContent>
      <ValidationTool />
      <Spacer24 />
      <SidebarCurrentUser location={location} name={name}/>
      <Spacer48 />
    </ContainerSidebar>
  );
}

export default Sidebar;
