// import auth axios instance
import axios from "util/axios/server-instance";
import { auth } from "services/firebase";
/**
 * this function fetches csv data for charts and tables from the Sundial API.
 * TODO: add functionality to change company.
 * @param path - relative path of the CSV as per the story JSON
 * @param company - right now defaulted as houseparty. TODO: add support for custom
 */
async function fetchData(path, company) {
  return auth.currentUser
    .getIdToken(/* forceRefresh */ true)
    .then((IdToken) => {
      return axios
        .get("data/chart-or-table-data", {
          headers: { Authorization: `Bearer ${IdToken}` },
          params: {
            path: path,
            company: "picsart",
          },
        })
        .then(function (response) {
          //console.log(response);
          return response.data;
        })
        .catch(function (error) {
          //console.log(error);
          return { error: "something went wrong!" };
        });
    });
}

export default fetchData;
