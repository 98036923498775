import styled from 'styled-components';

const ContentDivider = styled.hr`
	width: 100%;
	height: 1px;
	margin: 32px 0;
	padding: 0;
	background: #000000;
	border-radius: 2px;
	opacity: 0.2;
`;

export default ContentDivider;
