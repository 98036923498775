import styled from 'styled-components';
import ContainerOrderedList from './ContainerOrderedList';
import OrderedListStartingText from './OrderedListStartingText';

function ContentOrderedList({ startingText, children }) {
	return (
		<ContainerOrderedList>
			{startingText && <OrderedListStartingText>{startingText}</OrderedListStartingText>}
			{children}
		</ContainerOrderedList>
	);
}

export default ContentOrderedList;
