import { useState } from 'react';
import styled from 'styled-components';
import { H1, H2, H5, P } from 'components/Content/ContentTypography';
import {
	Spacer8,
	Spacer12,
	Spacer16,
	Spacer20,
	Spacer24,
	Spacer32,
	Spacer40,
	Spacer48,
} from 'components/Spacer';
import { sentiment } from 'Constants';

import WrapperContainerSubsection from './WrapperContainerSubsection';
import ContainerSubsection from './ContainerSubsection';
import SubsectionSideTrail from './SubsectionSideTrail';
import SubsectionSideTrailLine from './SubsectionSideTrailLine';
import SubsectionSideTrailIcon from './SubsectionSideTrailIcon';
import SubsectionContent from './SubsectionContent';

function ContentSubsection({ subtitle, title, config, description, children }) {
	console.log('SUBSECTION', subtitle, title, config, config.sentiment);

	// const sentiment = {
	// 	POSITIVE: 'POSITIVE',
	// 	NEUTRAL: 'NEUTRAL',
	// 	NEGATIVE: 'NEGATIVE',
	// 	NONE: 'NONE',
	// };

	return (
		<WrapperContainerSubsection>
			<H5 opacity={0.33}>{subtitle}</H5>
			<Spacer8 />
			<ContainerSubsection>
				<SubsectionSideTrail>
					<SubsectionSideTrailIcon>
						{config.sentiment == sentiment.NEGATIVE && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								fill="none"
								viewBox="0 0 24 24"
							>
								<g clipPath="url(#clip0)">
									<path fill="#fff" d="M0 0H24V24H0z" />
									<path
										fill="#FF2A68"
										d="M7.528 3.944C9.37.26 14.63.26 16.472 3.944l5.91 11.82C24.044 19.088 21.627 23 17.91 23H6.09c-3.717 0-6.134-3.912-4.472-7.236l5.91-11.82z"
									/>
								</g>
								<defs>
									<clipPath id="clip0">
										<path fill="#fff" d="M0 0H24V24H0z" />
									</clipPath>
								</defs>
							</svg>
						)}
						{config.sentiment == sentiment.NEUTRAL && (
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="24" height="24" rx="6" fill="#FF9500" />
							</svg>
						)}
						{config.sentiment == sentiment.POSITIVE && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								fill="none"
								viewBox="0 0 24 24"
							>
								<rect width="24" height="24" fill="#0CD319" rx="12" />
							</svg>
						)}
					</SubsectionSideTrailIcon>
					<SubsectionSideTrailLine fullLine={sentiment == 'NONE'} />
				</SubsectionSideTrail>
				<SubsectionContent>
					{config.sentiment == sentiment.POSITIVE && <H2 color="#0CD319">{title}</H2>}
					{config.sentiment == sentiment.NEUTRAL && <H2 color="#FF9500">{title}</H2>}
					{config.sentiment == sentiment.NEGATIVE && <H2 color="#FF2A68">{title}</H2>}
					{config.sentiment == null && (
						<H2 color="#000000" opacity={0.75}>
							{title}
						</H2>
					)}
					{false && description && (
						<>
							<Spacer8 />
							<P opacity={0.75}>{description}</P>
						</>
					)}
					{config.sentiment == sentiment.NONE ? <Spacer16 /> : <Spacer8 />}
					{children}
				</SubsectionContent>
			</ContainerSubsection>
		</WrapperContainerSubsection>
	);
}

export default ContentSubsection;
