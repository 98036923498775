import styled from "styled-components";
import { Link } from "react-router-dom";
import { H3, P1 } from "components/Content/ContentTypography";
import { transitionDefault } from "styles/Styles";
import { Spacer8 } from "components/Spacer";

const Preview = styled.div`
  flex: 0 50%;
  /*margin: 0 16px 16px 0;*/
  background: rgba(0, 0, 0, 0);
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ${transitionDefault}

  a {
    color: inherit;
    text-decoration: none;
    background: none;
  }
`;

function SegmentsPreview(props) {
  const { segment, base, title } = props;
  return (
    <Preview>
      <Link to={`/segment?seg=${segment}&view=${base ? base : "growth"}`}>
        <H3>{title.charAt(0).toUpperCase() + title.slice(1)}</H3>
        <Spacer8 />
        {/* we are not showing descriptions for now */}
        {/* <P1>{description}</P1> */}
      </Link>
    </Preview>
  );
}

export default SegmentsPreview;
