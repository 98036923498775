import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import GridContainerAside from "layouts/PrivateLayout/GridContainerAside";
import ContentHeader from "components/Content/ContentHeader";
import BaseSwitcher from "components/BaseSwitcher";
import getStoryJson from "util/fetchData/getStoryJson";
import { type, sentiment, loading } from "Constants";
import StoryEngine from "components/Engines/StoryEngine";
import TableOfContents from "components/TableOfContents";
import { useValidationTool } from "contexts/ValidationTool";
import * as _ from "lodash";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Segment({ match }) {
  let query = useQuery();
  let segment = query.get("seg");
  let view = query.get("view");

  const [contentData, setContentData] = useState(loading.overallStory);
  const [fetched, setFetched] = useState(false);
  const [segmentTitle, setSegmentTitle] = useState(segment);

  const { validationToolConfig } = useValidationTool();

  useEffect(() => {
    console.log(segment);
    console.log(view);
    //fetch the data
    async function fetchData() {
      let result = await getStoryJson(
        `${view}_module/statements/cs_view/${segment}.json`
      );
      console.log(result);
      setContentData(result.content);
    }

    fetchData();
    setFetched(true);
  }, []);

  useEffect(() => {
    let tempSegmentTitle =
      _.find(validationToolConfig.filteredArr, function (o) {
        return o.value === segment;
      })?.label ?? segment;

    setSegmentTitle(tempSegmentTitle);
  }, [validationToolConfig]);

  if (!fetched) {
    return <div>hello</div>;
  }
  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader
          subtitle="Segments"
          title={segmentTitle.charAt(0).toUpperCase() + segmentTitle.slice(1)}
        />
        <BaseSwitcher base={view} segment={segment} />
        <StoryEngine data={contentData} title={segment} />
      </GridContainerContent>
      <GridContainerAside>
        <TableOfContents data={contentData} />
      </GridContainerAside>
    </GridContainer>
  );
}

export default Segment;
