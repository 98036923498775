import styled from 'styled-components';
import { stylesCardDefault } from 'styles/Styles';

const ContentCard = styled.section`
	${(props) => props.formContent && 'max-width: 624px;'}

	margin: 0;
	padding: 40px;
	${stylesCardDefault}
`;

export default ContentCard;
