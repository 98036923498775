import styled from 'styled-components';

const GridContainerFull = styled.div`
	grid-column-start: content-start;
	grid-column-end: end;

	width: 100%;
	height: 100%;
	margin: 0;
	padding: 64px 0;
	/*background: rgba(255, 0, 0, 0.1);*/
`;

export default GridContainerFull;