import styled from 'styled-components';

const SubsectionSideTrailIcon = styled.div`
	position: absolute;
	top: 3px;
	left: 0;
	z-index: 100;

	width: 24px;
	height: 24px;
	margin: 0;
`;

export default SubsectionSideTrailIcon;
