import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";

import LayoutGrid from "./LayoutGrid";
import LayoutGridSidebar from "./LayoutGridSidebar";
import LayoutGridContainer from "./LayoutGridContainer";
import Sidebar from "components/Sidebar";
import Background from "components/Background";

function PrivateLayout({ children, isSidebarHidden }) {
	const [viewportWidth, setViewportWidth] = useState(0);
	const [shouldFocusContent, setShouldFocusContent] = useState(false);
	const [isFocusedContentEnabled, setIsFocusedContentEnabled] = useState(false);

	const animateContainer = useAnimation();
	const location = useLocation();

	useEffect(() => {
		onWindowResize();
		setViewportWidth(window.innerWidth);

		if (location.pathname != "/summary") {
			setIsFocusedContentEnabled(true);
		}

		// if (window.history.scrollRestoration) {
		// 	window.history.scrollRestoration = "manual";
		// }

		// window.addEventListener('wheel', onWheelThrottled);
		// return () => {
		// 	window.removeEventListener('wheel', onWheelThrottled);
		// };
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		window.addEventListener("resize", onWindowResize);
		return () => window.removeEventListener("resize", onWindowResize);
	});

	const onWindowResize = () => {
		if (window.innerWidth >= 1536 && viewportWidth < 1536) {
			animateContainer.start({
				width: 1392,
				transition: { type: "spring", stiffness: 1000, damping: 100 }
			});
		} else if (window.innerWidth < 1536 && viewportWidth >= 1536) {
			animateContainer.start({
				width: "100%",
				transition: { type: "spring", stiffness: 1000, damping: 100 }
			});
		}

		setViewportWidth(window.innerWidth);
	};

	// const onWheel = (e, x) => {
	// 	console.log('onWheel PrivateLayout', e, x);
	// };

	// const throttledWheel = useCallback(throttle((e, x) => onWheel(e, x), 400), []);

	// const onWheelThrottled = (e, x) => {
	// 	throttledWheel(e, x);
	// };

	return (
		<>
			<Background />
			<motion.div
				animate={animateContainer}
				initial={{
					maxWidth: "100%",
					width: "100%",
					height: "100%",
					margin: "0 auto"
				}}
			>
				<LayoutGrid>
					<LayoutGridSidebar>
						{!isSidebarHidden && (
							<Sidebar shouldFocusContent={shouldFocusContent} />
						)}
					</LayoutGridSidebar>
					<LayoutGridContainer>
						{children}
						{/* <br /> */}
						{/* {window.innerWidth + ' : ' + viewportWidth} */}
					</LayoutGridContainer>
				</LayoutGrid>
			</motion.div>
		</>
	);
}

export default PrivateLayout;
