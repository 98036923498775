import React from "react";
import { useState, cloneElement } from "react";
import styled from "styled-components";
import axios from "util/axios/server-instance";
import { auth } from "services/firebase";

const Form = styled.form`
  margin: 0;
  padding: 0;
`;

function FormOmnipresent({ onSubmit, handleSubmit, children }) {
  const [focusIndex, setFocusIndex] = useState(-1);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [messageSuccess, setMessageSuccess] = useState("Successfully saved!");

  const handleOnSubmit = e => {
    e.preventDefault();

    if (onSubmit != null) {
      console.log("Form onSubmit");
      console.log(formData);

      auth.currentUser.getIdToken(true).then(IdToken => {
        console.log(IdToken);
        return axios
          .post(onSubmit, formData, {
            headers: { Authorization: `Bearer ${IdToken}` }
          })
          .then(function(response) {
            console.log("submitted", response);
            setHasSubmitted(true);
            return response.data;
          })
          .catch(function(error) {
            console.log(error);
            return { error: "something went wrong!" };
          });
      });

      // axios
      //   .get(onSubmit)
      //   .then(response => {
      //     console.log("response", response);
      //     setHasSubmitted(true);
      //     // alert('form submitted!');
      //   })
      //   .catch(error => {
      //     // alert('form error!');
      //   });
    } else if (handleSubmit != null) {
      handleSubmit(formData);
      setHasSubmitted(true);
    }

    return false;
  };

  const isComponent = component => {
    return (
      (typeof component === "function" &&
        !!component.prototype.isReactComponent) ||
      (typeof component === "function" &&
        String(component).includes("return React.createElement")) ||
      React.isValidElement(component)
    );
  };

  const onChange = (name, value) => {
    let formDataCopy = formData;
    formDataCopy[name] = value;
    setFormData(formDataCopy);

    setHasSubmitted(false);

    let errorDataCopy = errorData;
    errorDataCopy[name] = ``;
    // errorDataCopy[name] = `error message for ${name} if it exists`;
    //; move this into axios call
    setErrorData(errorDataCopy);
    // console.log('formData', name, value, formDataCopy);
  };

  const onFocus = index => {
    // setHasSubmitted(true);
    setFocusIndex(index);
  };

  const onBlur = index => {
    if (index == focusIndex) {
      // setHasSubmitted(false);
      setFocusIndex(-1);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      {children.map((child, index) => {
        // console.log('iscomponent?', child, isComponent(child));
        if (isComponent(child)) {
          // console.log('index', child);
          let childProps = {
            onChange: onChange,
            onFocus: onFocus,
            onBlur: onBlur,
            index: index,
            // isFaded: index == focusIndex ? false : focusIndex != -1,
            isFormValid: isFormValid,
            isFormElement: true,
            hasSubmitted: hasSubmitted,
            messageSuccess: messageSuccess
          };

          if (onSubmit != null && handleSubmit == null) {
            childProps["error"] = hasSubmitted
              ? errorData[child.props.name]
              : null;
          }

          return cloneElement(child, childProps);
        }

        return child;
        // ADD ELEMENT CHECK FOR BUTTONS VS INPUTS
        // SO WE CAN PREFILL BUTTON ONCLICK
        // AND ALSO SET WHEN IT SHOULD BE DISABLED
      })}
    </Form>
  );
}

export default FormOmnipresent;
