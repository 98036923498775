import styled from 'styled-components';
import {transitionOpacity} from 'styles/Styles';

const PointText = styled.p`
	/*margin: 0;*/
	margin: 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};

	&:last-of-type {
		margin: 0;
	}

	${transitionOpacity}
`;

export default PointText;