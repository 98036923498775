import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import ContainerItemSingle from './ContainerItemSingle';
import ItemSingle from './ItemSingle';

function SidebarItemSingle({ title, route, activeIndex, itemIndex, colorActive }) {
	let location = useLocation();
	let isActive = location && location.pathname && route == location.pathname;

	return (
		<ContainerItemSingle>
			<Link to={route}>
				<ItemSingle isActive={isActive} colorActive={colorActive}>
					{title}
				</ItemSingle>
			</Link>
		</ContainerItemSingle>
	);
}

export default SidebarItemSingle;
