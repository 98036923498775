import styled from 'styled-components';

const ContainerOrderedList = styled.div`
	margin: 0 0 24px 0;

	&:last-child {
		margin: 0;
	}
`;

export default ContainerOrderedList;
