import { useEffect, useState } from "react";
import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import GridContainerContentOnboarding from "layouts/PrivateLayout/GridContainerContentOnboarding";

import { useValidationTool } from "contexts/ValidationTool";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import { H1, H2, P } from "components/Content/ContentTypography";
import FormOmnipresent from "components/Form/FormOmnipresent";
import InputText from "components/Input/InputText";
import InputEmail from "components/Input/InputEmail";
import InputPassword from "components/Input/InputPassword";
import InputSelectVertical from "components/Input/InputSelectVertical";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Spacer48 } from "components/Spacer";

function Onboarding({ match }) {
	const dataContent = {
		personal: {
			title: "Tell us about yourself",
			description:
				"The better we know you, the better we can tailor Sundial to your needs. What you tell us will be strictly between us, and we will never share or sell it to any third party."
		},
		company: {
			title: "Tell us about your company and product",
			description:
				"Sundial seeks to give you the most reliable, understandable and actionable insights based on what you tell us. The better we know your product, the shaper our analysis."
		}
	};

	const [isGWEnabled, setIsGWEnabled] = useState(true);
	const [hasGWConnected, setHasGWConnected] = useState(false);

	// alert(match.params.step);
	return (
		<GridContainer>
			<GridContainerContent>
				<GridContainerContentOnboarding>
					<H1>{dataContent[match.params.step].title}</H1>
					{/* <ContentHeader subtitle={null} title={dataContent[match.params.step].title} /> */}
					<P>{dataContent[match.params.step].description}</P>
					<Spacer48 />
					{match.params.step == "personal" && (
						<FormOmnipresent onSubmit={"user"}>
							<InputEmail
								name="email"
								label="Your company email"
								placeholder="What's your work email?"
								defaultValue="jay@inspirit.work"
								disabled={true}
								isGWEnabled={isGWEnabled}
								hasGWConnected={hasGWConnected}
								isTransparent={true}
								required
							/>
							<InputText
								name="role"
								label="Your job title"
								placeholder="What's your role?"
								isTransparent={true}
								required
							/>
							<InputPassword
								name="password"
								label="Your password"
								placeholder="Create a secret password"
								description="We require at minimum of 8 characters, 1 capital letter, 1 symbol and no spaces."
								isTransparent={true}
								required
							/>
							<InputText
								name="name"
								label="Your name"
								placeholder="What's your name?"
								isTransparent={true}
								required
							/>
							<InputSelectVertical
								name="familiarity"
								label="How familiar are you with data concepts?"
								data={[
									{ text: "Little to no familiarity", value: "beginner" },
									{ text: "Some familiarity", value: "intermediate" },
									{ text: "Extremely familiar", value: "expert" }
								]}
								isTransparent={true}
								required
							/>
							<InputText
								name="familiarity"
								label="Things you want to learn through Sundial"
								placeholder="What do you want to learn through Sundial?"
								isTransparent={true}
								required
							/>
							<ButtonPrimary action="submit">Create an Account</ButtonPrimary>
						</FormOmnipresent>
					)}
					{match.params.step == "company" && (
						<FormOmnipresent onSubmit={"/API/URL/GOES/HERE"}>
							<InputText
								name="productName"
								label="Your product name"
								placeholder="What's the name of your product?"
								isTransparent={true}
							/>
							<InputText
								name="vertical"
								label="Company Vertical"
								placeholder="What vertical is your product in?"
								isTransparent={true}
							/>
							<InputText
								name="tam"
								label="Total addressable market size"
								placeholder="How big is the TAM?"
								isTransparent={true}
							/>
							<br />
							// ** CURRENTLY IMPORTING CHECKBOXES ** //
							<br />
							<ButtonPrimary action="submit">Continue</ButtonPrimary>
						</FormOmnipresent>
					)}
				</GridContainerContentOnboarding>
			</GridContainerContent>
		</GridContainer>
	);
}

export default Onboarding;
