import { useState } from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { transitionDefault } from 'styles/Styles';
import { H3, H5 } from 'components/Content/ContentTypography';
import { Spacer4 } from 'components/Spacer';
import { IconDown } from 'components/Icons';

import JumpCard from './JumpCard';
import JumpCardContent from './JumpCardContent';
import JumpCardIcon from './JumpCardIcon';

function ContentPreviewJump({ subtitle, text, href }) {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <JumpCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} isHovering={isHovering}>
      <AnchorLink href={href}>
        <JumpCardContent>
          <H5 opacity={isHovering ? 0.75 : 0.33}>{subtitle}</H5>
          <Spacer4 />
          <H3>{text}</H3>
          {/* <H3 opacity={isHovering ? 0.75 : 0.66}>{text}</H3> */}
        </JumpCardContent>
        <JumpCardIcon>
          <IconDown width={16} height={16} color="#000000" opacity={isHovering ? 0.75 : 0.33} />
        </JumpCardIcon>
      </AnchorLink>
    </JumpCard>
  );
}

export default ContentPreviewJump;
