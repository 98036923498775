import { useState } from 'react';
import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100%;
  height: 48px;
  /*margin: 40px 0 0 0;*/
  margin: 0;
  padding: 0 24px;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  /*background: rgba(42, 0, 12, 0.04);*/
  /*color: #ffffff;*/
  /*color: rgba(0, 0, 0, 0.66);*/
  color: rgba(0, 0, 0, 1);
  background: rgba(42, 0, 12, 0.08);
  /*background: rgba(42, 0, 12, 0.06);*/
  /*background: #ffeded;*/
  /*background: url('/button-primary.jpg') no-repeat center center;*/
  /*background: linear-gradient(180deg, #FF7557 0%, #F1649A 100%);*/
  /*background: url('/button-primary-blue.jpg') no-repeat center center;*/
  /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.33), rgba(255, 255, 255, 0.33)),
    linear-gradient(180deg, #57ffc2 0%, #63f26d 100%);*/
  /*background-size: cover;*/

  border: none;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important;

  opacity: ${(props) => (props.isHovering ? 0.75 : 0.66)};

  cursor: pointer;
  ${transitionDefault};

  &:hover {
    background: rgba(42, 0, 12, 0.13);
  }
`;

const ButtonIcon = styled.div`
  margin: 0 12px 0 0;
  border: 1px solid red;
`;

const ButtonContent = styled.div`
  margin: 0 0 0 12px;
  /*border: 1px solid red;*/
`;

function ButtonGoogle({ children, action, onClick }) {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Button action={action} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.6345 7.78641L16.5551 7.44926H9.22711V10.5507H13.6055C13.1509 12.7093 11.0415 13.8456 9.31852 13.8456C8.06484 13.8456 6.74332 13.3182 5.86863 12.4706C5.40715 12.0163 5.03981 11.4754 4.78765 10.8789C4.53548 10.2824 4.40345 9.64197 4.3991 8.99437C4.3991 7.68797 4.98621 6.38121 5.84051 5.52164C6.6948 4.66207 7.98504 4.18113 9.26789 4.18113C10.7371 4.18113 11.79 4.96125 12.1837 5.31703L14.3877 3.12469C13.7412 2.55656 11.9651 1.125 9.19688 1.125C7.06113 1.125 5.01328 1.94309 3.51633 3.43512C2.03906 4.9043 1.27441 7.02879 1.27441 9C1.27441 10.9712 1.99793 12.9895 3.42949 14.4703C4.95914 16.0495 7.12547 16.875 9.35613 16.875C11.3857 16.875 13.3095 16.0798 14.6805 14.637C16.0284 13.2166 16.7256 11.2514 16.7256 9.19125C16.7256 8.32395 16.6384 7.80891 16.6345 7.78641V7.78641Z"
          fill="black"
          opacity={1}
        />
      </svg>
      <ButtonContent>{children}</ButtonContent>
    </Button>
  );
}

export default ButtonGoogle;
