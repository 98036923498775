import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';
import { IconGoogle } from 'components/Icons';

const ContainerConnectButton = styled.div`
	position: absolute;
	top: 28px;
	right: 0px;
	height: 56px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: auto;
	height: 56px;
	margin: 0;
	padding: 0 12px;

	/*border: 1px solid red;*/
`;

const ConnectButton = styled.button`
	display: flex;
	align-items: center;
	/*position: absolute;*/
	/*top: 0px;*/
	/*right: 20px;*/

	width: auto;
	height: 32px;
	padding: 0 16px 0 14px;
	/*background: rgba(255, 255, 255, 1);*/
	background: rgba(42, 0, 12, 0.06);
	color: rgba(0, 0, 0, 0.75);
	/*background: rgba(42, 0, 12, 0.06);*/
	border: 0;
	border-radius: 6px;
	/*box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.07), 0px 1.8px 2.7px -6px rgba(0, 0, 0, 0.042),
		0px 0.5px 0.8px -6px rgba(0, 0, 0, 0.028);*/

	font-size: 14px;
	font-weight: 500;

	/*transform: translateY(12px);*/
	outline: none;
	cursor: pointer;

	${transitionDefault} /*&:hover {
		background: rgba(42, 0, 12, 0.1);
	}*/

	&:hover {
		background: rgba(42, 0, 12, 0.1);
	}

	a {
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;
	}
`;

const ConnectButtonIcon = styled.span`
	margin: 0 8px 0 0;
`;

function GoogleWorkspaceConnectButton({ email }) {
	const onConnect = () => {
		// do something with email
		console.log('Attempt account connection with', email);
	};

	return (
		<ContainerConnectButton>
			<ConnectButton onClick={onConnect}>
				<ConnectButtonIcon>
					<IconGoogle width={15} height={15} color="#000" opacity={0.66} />
				</ConnectButtonIcon>
				Connect With Google Workspace
			</ConnectButton>
		</ContainerConnectButton>
	);
}

export default GoogleWorkspaceConnectButton;
