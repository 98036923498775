import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const ContainerSidebar = styled.div`
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	${transitionDefault}
	padding: 0 32px;
`;

export default ContainerSidebar;
