import { useEffect, useState } from "react";
import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import { useAuth } from "contexts/AuthContext";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import { H1, H4 } from "components/Content/ContentTypography";
import styled from "styled-components";

import FormOmnipresent from "components/Form/FormOmnipresent";
import InputText from "components/Input/InputText";
import InputPassword from "components/Input/InputPassword";
import ButtonPrimary from "components/Button/ButtonPrimary";

function ChangePassword() {
  const { updatePassword } = useAuth();
  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader subtitle={"Change Password"} title="Personal Settings" />
        <ContentCard formContent>
          <FormOmnipresent handleSubmit={() => {}}>
            {/* <InputPassword */}
            {/*   name="password-old" */}
            {/*   label="Your current password" */}
            {/*   placeholder="Enter your current password" */}
            {/*   defaultValue={null} */}
            {/*   disabled={false} */}
            {/*   allowChangePassword={false} */}
            {/*   required */}
            {/* /> */}
            <InputPassword
              name="password"
              label="Your new password"
              placeholder="Choose a secret password"
              description="We require at minimum of 8 characters, 1 capital letter, 1 symbol and no spaces."
              defaultValue={null}
              disabled={false}
              allowChangePassword={false}
              required
            />
            <ButtonPrimary onClick={() => updatePassword("password")}>
              Update Your Password
            </ButtonPrimary>
          </FormOmnipresent>
        </ContentCard>
      </GridContainerContent>
    </GridContainer>
  );
}

export default ChangePassword;

export const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const Input = styled.input`
  font-size: 2rem;
  border-radius: 5px;
  outline: none;
  border: 2px solid #ffe0e9;
  margin-right: 10px;
  &:focus {
    border: 2px solid pink;
  }
`;
export const Button = styled.button`
  font-size: 1.2rem;
  color: white;
  background-color: black;
  padding: 0px 20px;
`;
