import styled from "styled-components";
import { transitionDefault } from "styles/Styles";

const ExpandCollapseIcon = styled.div`
	margin: 0 0 0 8px;
	transform: ${props =>
		props.isCollapsed
			? "translateY(-1px) rotate(0deg)"
			: "translateY(0px) rotate(180deg)"};
	${transitionDefault}
`;

export default ExpandCollapseIcon;
