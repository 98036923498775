import styled from 'styled-components';

const GridContainer = styled.div`
	width: 100%;
	margin: 0 auto;
	padding: 0;

	display: grid;
	grid-template-columns: [content-start] 752px [content-end] 40px [aside-start] 1fr [aside-end];
	
	// THIS IS DIFFERENT CONTAINER-START, FROM PARENT LayoutGrid.js
	grid-column-start: container-start;
	grid-column-end: container-end;
`;

export default GridContainer;
