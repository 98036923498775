import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

const ContainerContentNumber = styled.div`
	display: flex;
	align-items: flex-start;
	/*margin: 8px 0 0 0;*/
	margin: 12px 0 0 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};

	${transitionOpacity}

	.content-number__text {
		.content-number__content__text {
			margin: 0;
		}
	}
`;
	// opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
// &::before {
// 	content: ${(props) => props.number};
// }

export default ContainerContentNumber;
