import styled from "styled-components";
import { transitionDefault } from "styles/Styles";

const Zoom = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	width: 100%;
	margin: 24px 0 0 0;
	padding: 0 28px 0 0;
	/*border: 1px solid red;*/
`;

const ZoomButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 144px;
	flex: 0 144px;
	height: 40px;
	margin: 0 16px 0 0;
	/*padding: 0 16px;*/

	/*background: rgba(42, 0, 12, 0.04);*/
	background: rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.75);
	border: none;
	border-radius: 4px;
	outline: none !important;
	box-shadow: none !important;

	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	text-align: center;

	cursor: pointer;
	${transitionDefault};

	&:hover {
		background: rgba(0, 0, 0, 0.09);
	}
`;

const ZoomTracker = styled.div`
	position: relative;
	flex: 1;
	height: 24px;
	border-radius: 4px;
	/*background: rgba(0, 0, 0, 0.05);*/
	background: transparent;
	border: 1px solid rgba(0, 0, 0, 0.15);
`;

const ZoomTrackerBefore = styled.div`
	position: absolute;
	top: 5px;
	left: 8px;
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: left;
	color: rgba(0, 0, 0, 0.33);
`;
const ZoomTrackerAfter = styled.div`
	position: absolute;
	top: 5px;
	right: 8px;
	font-size: 12px;
	font-weight: 500;
	line-height: 12px;
	text-align: right;
	color: rgba(0, 0, 0, 0.33);
`;

const ZoomTrackerCurrent = styled.div`
	position: absolute;
	top: 0px;
	left: calc(-1px + ${props => (props.left ? props.left : 0)});
	width: ${props => (props.width ? props.width : "0%")};
	height: 22px;
	background: rgba(0, 0, 0, 0.06);
	border-radius: 4px;
	${transitionDefault}
`;

function ChartZoom({ xDomainState, chartZoom, setChartZoom }) {
	// console.log("scale", xDomainState);
	return (
		<Zoom onClick={() => setChartZoom(prev => !prev)}>
			<ZoomButton>{chartZoom ? "Zoom To Scale" : "Zoom To Fit"}</ZoomButton>
			<ZoomTracker>
				<ZoomTrackerCurrent
					left={`${xDomainState[0] * 100}%`}
					width={`${(xDomainState[1] - xDomainState[0]) * 100}%`}
				/>
				<ZoomTrackerBefore>0</ZoomTrackerBefore>
				<ZoomTrackerAfter>1</ZoomTrackerAfter>
				{/* <ZoomTrackerAfter>{xDomainState[1].toFixed(3)}</ZoomTrackerAfter> */}
			</ZoomTracker>
		</Zoom>
	);
}

export default ChartZoom;
