import { useEffect, useState } from 'react';
import GridContainer from 'layouts/PrivateLayout/GridContainer';
import GridContainerFull from 'layouts/PrivateLayout/GridContainerFull';
import { useValidationTool } from 'contexts/ValidationTool';
import { type, sentiment, loading } from 'Constants';
import ContentHeader from 'components/Content/ContentHeader';
import SummaryEngine from 'components/Engines/SummaryEngine';
import getStoryJson from 'util/fetchData/getStoryJson';
function ExecutiveSummary() {
  const [summaryData, setSummaryData] = useState(loading.executiveSummary);
  let { validationToolConfig } = useValidationTool();

  useEffect(() => {
    console.log(validationToolConfig.currentSegment);
    //fetch the data
    async function fetchData() {
      if (validationToolConfig.currentSegment == 'none') {
        // draw default
        let result = await getStoryJson(`exec_module/statements/pm_view/Overall.json`);
        console.log(result);
        setSummaryData(result.content);
      } else {
        // draw default
        let result = await getStoryJson(
          `exec_module/statements/pm_view/${validationToolConfig.currentSegment}.json`
        );
        console.log(result);
        setSummaryData(result.content);
      }
    }

    fetchData();
  }, [validationToolConfig]);

  return (
    <GridContainer>
      <GridContainerFull>
        <ContentHeader subtitle={null} title="Overview" />
        <SummaryEngine data={summaryData} subtitle={null} title="Overview" />
      </GridContainerFull>
    </GridContainer>
  );
}

export default ExecutiveSummary;
