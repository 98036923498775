import { useState, useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { motion, useAnimation } from "framer-motion";
// import { theme } from 'contexts/Theme';
//import { H3 } from "components/Content/ContentTypography";

import Table from "./Table";
import TableHeader from "./TableHeader";
import TableHeaderItem from "./TableHeaderItem";
import TableContent from "./TableContent";
import TableContentRow from "./TableContentRow";
import TableContentRowItem from "./TableContentRowItem";
import TableExpandCollapse from "../TableExpandCollapse";
import { getStringFromLargeNumber } from "components/Chart/functions";
import { transitionSpring } from "Constants";
import SparkLine from "./SparkLine";
import { IconLeftArrow, IconDown } from "components/Icons";
import ReactSelect, { components } from "react-select";
import * as _ from "lodash";
var randomColor = require("randomcolor");

function DriverTable({ data: tempData, config: tempConfig }) {
  // initial data is as recieved
  const [data, setData] = useState(tempData);
  const [config, setConfig] = useState(tempConfig);
  const [sortConfig, setSortConfig] = useState({ sortBy: null, asc: false });
  const [rowFilter, setRowFilter] = useState({
    breakdownBy: "Country",
    timeDelta: 364,
    interval: 1,
  });
  const [showRowArray, setShowRowArray] = useState({});
  const [searchBar, setSearchBar] = useState("");

  useEffect(() => {
    // we want to check if we will need to parse the config.columnStyling

    if (config.columnStyling && typeof config.columnStyling === "string") {
      // we need to parse it
      let newJson = config.columnStyling.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
      newJson = newJson.replace(/'/g, '"');

      config.columnStyling = JSON.parse(newJson);
    }
  }, []);

  useEffect(() => {
    // run on change in sort config
    // if filterBy is null, keep the original sorting
    switch (sortConfig["sortBy"]) {
      case null:
        // if null reset sorting to CSV, this happens the first time
        setData(tempData);
        break;
      case "contribution":
        // if contribution
        setData(
          _.orderBy(
            data,
            (o) => Math.abs(o.contribution),
            sortConfig["asc"] ? "asc" : "desc"
          )
        );
        break;

      default:
        setData(
          _.orderBy(
            data,
            sortConfig["sortBy"],
            sortConfig["asc"] ? "asc" : "desc"
          )
        );
        break;
    }

    console.log("changed sorting order");
  }, [sortConfig]);

  let TopLevelSegmentList = _.uniq(
    _.map(tempData, config.breakdownLevelCol ?? "breakdown_level")
  );
  useEffect(() => {
    // console.log(
    //   rowFilter.breakdownBy ===
    //     tempData[0][config.breakdownCol ?? "breakdown_level"]
    // );
    setData(
      _.filter(tempData, function (o) {
        return (
          o[config.analysisForSegmentCol ?? "analysis_for_segment"] ===
            "Overall" &&
          o[config.breakdownCol ?? "breakdown_level"] ===
            rowFilter.breakdownBy &&
          o[config.timeDeltaCol ?? "change_time_days"] ===
            rowFilter.timeDelta &&
          // this one is for intervals in case of retention drivers
          (Object.keys(tempData[0]).includes(config.intervalCol ?? "interval")
            ? o[config.intervalCol ?? "interval"] === rowFilter.interval
            : true) &&
          // this is for the searchbar
          (searchBar.length > 0
            ? JSON.stringify(o).toLowerCase().includes(searchBar.toLowerCase())
            : true)
        );
      })
    );
  }, [rowFilter, searchBar]);

  const theme = useContext(ThemeContext);
  const flexMap = [1, 1, 1.2, 1, 1, 1, 1, 1, 1, 1];
  const [rowCount, setRowCount] = useState(6);
  const animateTableRows = useAnimation();

  const valueManager = (value, index) => {
    return (
      <>
        <TableContentRowItem
          justifyContent={
            config.showCols[index] === config.currentValueCol
              ? "flex-end"
              : undefined
          }
          fontWeight={
            config.showCols[index] === config.currentValueCol ? 600 : undefined
          }
          color={
            config.columnStyling[index]?.color
              ? value >= 0
                ? "green"
                : "red"
              : undefined
          }
          flex={flexMap[index]}
        >
          <div
            style={{
              width: config.columnStyling[index]?.sparkline ? "100%" : "auto",
            }}
          >
            {getStringFromLargeNumber(
              value,
              config.columnStyling[index]?.factor ?? null
            )}

            {config.columnStyling[index]?.sparkline && (
              <SparkLine data={value} />
            )}
          </div>
        </TableContentRowItem>
        {config.showCols[index] === config.currentValueCol && (
          <TableContentRowItem flex={"min-content"}>
            <IconLeftArrow
              style={{ marginRight: "10px", marginLeft: "10px" }}
              width={25}
              height={25}
              color="#000"
              opacity={0.66}
            />
          </TableContentRowItem>
        )}
      </>
    );
  };

  const handleRowExpand = () => {
    setRowCount(data.length);
    animateTableRows.start({
      maxHeight: 60 * data.length - 4,
      transition: transitionSpring,
    });
  };

  const handleRowCollapse = () => {
    setRowCount(6);
    animateTableRows.start({
      maxHeight: 60 * 6 - 4,
      transition: transitionSpring,
    });
  };

  function SecondLevelTable(props) {
    //we need to filter the secondLevelData
    let secondLevelSegmentList = TopLevelSegmentList.filter(
      (segmentItem) =>
        segmentItem.includes(rowFilter.breakdownBy) &&
        segmentItem.length > rowFilter.breakdownBy.length
    );

    const [secondLevelSegment, setSecondLevelSegment] = useState(
      secondLevelSegmentList[0]
    );
    const [secondLevelData, setSecondLevelData] = useState(
      _.filter(tempData, function (o) {
        return (
          o[config.breakdownCol ?? "breakdown_level"] === secondLevelSegment &&
          o[config.timeDeltaCol ?? "change_time_days"] ===
            rowFilter.timeDelta &&
          (Object.keys(tempData[0]).includes(config.intervalCol ?? "interval")
            ? o[config.intervalCol ?? "interval"] === rowFilter.interval
            : true) &&
          o[config.analysisForSegmentCol ?? "analysis_for_segment"] ===
            props.segment
        );
      })
    );

    useEffect(() => {
      setSecondLevelData(
        _.filter(tempData, function (o) {
          return (
            o[config.breakdownCol ?? "breakdown_level"] ===
              secondLevelSegment &&
            o[config.timeDeltaCol ?? "change_time_days"] ===
              rowFilter.timeDelta &&
            (Object.keys(tempData[0]).includes(config.intervalCol ?? "interval")
              ? o[config.intervalCol ?? "interval"] === rowFilter.interval
              : true) &&
            o[config.analysisForSegmentCol ?? "analysis_for_segment"] ===
              props.segment
          );
        })
      );
    }, [secondLevelSegment]);

    if (secondLevelSegmentList.length === 0) {
      return <div>no subdivisions possible</div>;
    }

    return (
      <SubTable
        segment={secondLevelSegment}
        onClick={() => console.log(secondLevelData)}
      >
        <ReactSelect
          onChange={(NewValue) => {
            if (NewValue) {
              setSecondLevelSegment(NewValue.value);
            }
          }}
          styles={customSelectStyles}
          placeholder={"Select Sub segment"}
          className="driver-second-level-select "
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          maxMenuHeight={200}
          name="color"
          //options={[{ value: "test", label: "test" }]}
          options={secondLevelSegmentList.map((x) => {
            return { label: x, value: x };
          })}
        />
        {secondLevelData.map((element, index) => {
          return (
            <TableContentRow
              as={motion.div}
              initial={{ opacity: 0, x: -30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ type: "just", duration: 0.2 * index }}
            >
              {Object.values(_.pick(element, config.showCols)).map(
                (val, index) => {
                  return valueManager(val, index);
                }
              )}
            </TableContentRow>
          );
        })}
      </SubTable>
    );
  }

  const handleTimeDeltaChange = (newValue) => {
    if (newValue) {
      setRowFilter({ ...rowFilter, timeDelta: newValue.value });
      console.log("changed Time delta to: " + newValue.value);
      //console.log(typeof newValue.value);
    }
  };

  const handleIntervalChange = (newValue) => {
    if (newValue) {
      setRowFilter({ ...rowFilter, interval: newValue.value });
      console.log("changed interval to: " + newValue.value);
      //console.log(typeof newValue.value);
    }
  };

  const handleBreakdownByChange = (newValue) => {
    if (newValue) {
      setRowFilter((prev) => ({
        ...prev,
        breakdownBy: newValue.value,
      }));
      console.log("changed breakdown level to: " + newValue.value);
    }
  };
  return (
    <Table>
      <div>
        <Input
          type="text"
          placeholder="Search and Filter Rows"
          value={searchBar}
          onChange={(e) => setSearchBar(e.target.value)}
        />
        <span
          style={{ marginLeft: "-25px", opacity: 0.4, cursor: "pointer" }}
          onClick={() => setSearchBar("")}
        >
          {searchBar.length > 0 ? "X" : "🔍"}
        </span>
      </div>
      <div style={{ display: "flex" }}>
        {Object.keys(tempData[0]).includes(
          config.intervalCol ?? "interval"
        ) && (
          <ReactSelect
            onChange={handleIntervalChange}
            styles={customSelectStyles}
            placeholder={"Select Interval"}
            className="driver-top-select"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            maxMenuHeight={200}
            name="color"
            options={_.uniq(_.map(tempData, config.intervalCol ?? "interval"))
              .sort(function (a, b) {
                return a - b;
              })
              .map((x) => {
                return { label: x, value: x };
              })}
          />
        )}

        <ReactSelect
          onChange={handleTimeDeltaChange}
          styles={customSelectStyles}
          placeholder={"Select Time Delta"}
          className="driver-top-select"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          maxMenuHeight={200}
          name="color"
          options={_.uniq(
            _.map(tempData, config.timeDeltaCol ?? "change_time_days")
          )
            .sort(function (a, b) {
              return a - b;
            })
            .map((x) => {
              return { label: x, value: x };
            })}
        />
        <ReactSelect
          onChange={handleBreakdownByChange}
          styles={customSelectStyles}
          placeholder={"Select Top Level Breakdown"}
          className="driver-top-select"
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          maxMenuHeight={200}
          name="color"
          options={TopLevelSegmentList.map((x) => {
            return { label: x, value: x };
          })}
        />
      </div>

      <TableHeader>
        {config.showCols.map((element, index) => (
          <TableHeaderItem
            onClick={() =>
              setSortConfig((prev) => {
                if (element === prev.sortBy) {
                  // the element is the same
                  return { sortBy: element, asc: !prev["asc"] };
                } else {
                  // new element clicked
                  return { sortBy: element, asc: false };
                }
              })
            }
            flex={flexMap[index]}
          >
            {config.labels
              ? index === 2
                ? `${rowFilter.timeDelta} days ago`
                : config.labels[index]
              : element}{" "}
            {sortConfig.asc !== null && (
              <IconDown
                width={16}
                height={16}
                color={"#000000"}
                style={{
                  marginLeft: "6px",
                  transform: sortConfig.asc ? "" : "rotate(-180deg)",
                }}
                opacity={element === sortConfig.sortBy ? 0.8 : 0.3}
              />
            )}
          </TableHeaderItem>
        ))}
      </TableHeader>
      <TableContent>
        <motion.div
          animate={animateTableRows}
          initial={{ maxHeight: 60 * 6 - 4, overflow: "hidden" }}
        >
          {data
            .map((element, index) => (
              <div key={"top_level_row_" + index}>
                <TableContentRow
                  as={motion.div}
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ type: "just", duration: 0.2 * index }}
                  onClick={() =>
                    setShowRowArray({
                      ...showRowArray,
                      ["top_level_row_" + index]:
                        !showRowArray["top_level_row_" + index] ?? true,
                    })
                  }
                >
                  {config.showCols.map((el, index) => {
                    // now each element needs to be shown according to the order
                    return valueManager(element[el], index);
                  })}
                  {/* {Object.values(_.pick(element, config.showCols)).map(
                    (val, index) => {
                      return valueManager(val, index);
                    }
                  )} */}
                </TableContentRow>
                {showRowArray["top_level_row_" + index] && (
                  <SecondLevelTable
                    segment={element[config.segmentCol ?? "segment"]}
                  />
                )}
              </div>
            ))
            .filter((ele) => typeof ele !== "undefined")
            .slice(0, rowCount)}
        </motion.div>
      </TableContent>
      {data.length > 6 && (
        <TableExpandCollapse
          isCollapsed={rowCount <= 6}
          handleRowExpand={handleRowExpand}
          handleRowCollapse={handleRowCollapse}
        />
      )}
    </Table>
  );
}

export default DriverTable;

// remaining constant stuff
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgb(0,0,0,0.2)",
    background: state.isSelected ? "black" : "white",
    fontColor: state.isSelected ? "white" : "black",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "black";
    return { ...provided, opacity, transition, color };
  },
};

const SubTable = styled.div`
  padding: 10px 20px 10px 50px;
  background-color: ${(props) =>
    props.segment === "Country-Platform"
      ? "rgb(234,66,107,.04)"
      : "rgb(240,152,59, 0.04)"};
  border-left: 10px solid
    ${(props) =>
      props.segment === "Country-Platform"
        ? "rgb(234,66,107, 0.7)"
        : "rgb(240,152,59, 0.7)"};
`;

const Input = styled.input`
  font-size: 20px;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  color: #262626;
  padding: 7px 33px;
  border-radius: 3px;
  color: #999;
  cursor: text;
  font-size: 14px;
  font-weight: 300;
  background: #fafafa;
  margin-left: 10px;
  text-align: left;
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
`;
