import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

const ContentBulletContentText = styled.p`
	/*display: flex;
	align-items: flex-start;
	flex-direction: column;*/
	/*margin: 8px 0 0 0;*/
	margin: 0;
	padding: 0;

	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	${transitionOpacity}

	/*p {
		margin: 0 0 16px 0;
	}*/
`;

export default ContentBulletContentText;
