import styled from "styled-components";

const PlayPauseButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;
	background: none;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.25);
	outline: none;
`;

const PlayPauseButtonIcon = styled.div`
	display: flex;
	align-items: center;
	transform: ${props => (props.isIconPlay ? "translateX(1px)" : "")};
	opacity: 0.75;
`;

function ChartAnimatePlay({ playAnimation, onClick }) {
	return (
		<PlayPauseButton onClick={onClick}>
			{!playAnimation ? (
				<PlayPauseButtonIcon isIconPlay={true}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						fill="none"
						viewBox="0 0 18 18"
					>
						<path
							fill="#000"
							d="M4.676 15.469c-.216 0-.428-.057-.615-.164a1.382 1.382 0 01-.685-1.207V3.902c0-.505.263-.967.685-1.207a1.235 1.235 0 011.257.016l8.714 5.216a1.266 1.266 0 010 2.145l-8.715 5.217c-.194.117-.415.179-.641.18z"
						/>
					</svg>
				</PlayPauseButtonIcon>
			) : (
				<PlayPauseButtonIcon isIconPlay={false}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="none"
						viewBox="0 0 20 20"
					>
						<path
							fill="#000"
							d="M8.125 16.875H6.25a.625.625 0 01-.625-.625V3.75a.625.625 0 01.625-.625h1.875a.625.625 0 01.625.625v12.5a.625.625 0 01-.625.625zm5.625 0h-1.875a.624.624 0 01-.625-.625V3.75a.625.625 0 01.625-.625h1.875a.625.625 0 01.625.625v12.5a.624.624 0 01-.625.625z"
						/>
					</svg>
				</PlayPauseButtonIcon>
			)}
		</PlayPauseButton>
	);
}

export default ChartAnimatePlay;
