import styled from 'styled-components';
import { transitionDefault, transitionOpacity } from 'styles/Styles';

const H0 = styled.h1`
	margin: 0;
	padding: 0;
	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 40px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
	text-align: ${(props) => (props.alignment ? props.alignment : 'inherit')};
`;

const H1 = styled.h1`
	/*margin: 0;*/
	margin: 0 0 20px 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	text-align: ${(props) => (props.alignment ? props.alignment : 'inherit')};
	${transitionOpacity}
`;

const H2 = styled.h2`
	margin: 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: 1;

	${transitionOpacity}
`;

const H3 = styled.h3`
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
	text-align: ${(props) => (props.alignment ? props.alignment : 'left')};

	${transitionOpacity}
`;

const H4 = styled.h4`
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
	text-align: ${(props) => (props.alignment ? props.alignment : 'left')};

	${transitionOpacity}
`;

const H5 = styled.h5`
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	${transitionOpacity}

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid transparent;
		${transitionDefault}
		/*background: rgba(255, 0, 0, 0.1);*/

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.33);
		}
	}
`;

const H6 = styled.h6`
	margin: 0;
	padding: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	${transitionOpacity}
`;

const P = styled.p`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};
	/*margin: 0;*/
	margin: 0 0 24px 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};

	&:last-of-type {
		margin: 0;
	}

	${transitionOpacity}

	a {
		text-decoration: none;
		font-weight: 600;
		color: inherit;
		border-bottom: 1px solid transparent;

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.33);
		}

		${transitionDefault}
	}
`;

const P1 = styled.p`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};
	/*margin: 0;*/
	margin: 0 0 20px 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};

	&:last-of-type {
		margin: 0;
	}

	${transitionOpacity}

	a {
		text-decoration: none;
		font-weight: 600;
		color: inherit;
		border-bottom: 1px solid transparent;

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.33);
		}

		${transitionDefault}
	}
`;

const P2 = styled.p`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};
	/*margin: 0;*/
	margin: 0 0 16px 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};
	text-align: ${(props) => (props.alignment ? props.alignment : 'inherit')};

	&:last-of-type {
		margin: 0;
	}

	${transitionOpacity}

	a {
		padding: 0 0 2px 0;
		text-decoration: none;
		font-weight: 500;
		color: inherit;
		border-bottom: 1px solid rgba(0, 0, 0, 0.33);

		&:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.66);
		}

		${transitionDefault}
	}
`;

const ContentBulletBullet = styled.p`
	display: flex;
	align-items: flex-start;
	margin: 8px 0 0 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	${transitionOpacity}

	&::before {
		content: ' ';
		display: inline-block;
		/*width: 18px;*/
		/*height: 18px;*/
		flex: 0 6px;
		min-width: 6px;
		width: 6px;
		height: 6px;
		/*margin: 13px 16px 0 16px;*/
		margin: 13px 16px 0 32px;
		background: #000000;
		border-radius: 50%;
		opacity: 0.66;
	}
`;

const ContentNumberText = styled.p`
	display: flex;
	align-items: flex-start;
	margin: 8px 0 0 0;
	padding: 0;

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	${transitionOpacity}

	&::before {
		content: ${(props) => props.number};
	}
`;

const ContentNumberIndex = styled.div`
	display: inline-block;
	width: 24px;
	/*background: rgba(255, 0, 0, 0.1);*/
	margin: 0 7px 0 7px;
	opacity: 0.66;
	text-align: center;

	${transitionOpacity}
`;

const ContentNumber = ({ number, children }) => (
	<ContentNumberText>
		<ContentNumberIndex>{number}</ContentNumberIndex>
		<div>{children}</div>
	</ContentNumberText>
);

export { H0, H1, H2, H3, H4, H5, H6, P, P1, P2 };