import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const ItemGroupActive = styled.div`
	/*position: absolute;*/
	z-index: 10;
	top: ${(props) => (props.currentRouteIndex ? `${props.currentRouteIndex * 30}px` : 0)};
	left: 1px;

	width: 1px;
	height: 26px;
	background: ${(props) => (props.colorActive ? props.colorActive : '#000000')};
	border-radius: 2px;
	overflow: hidden;

	opacity: ${(props) => (props.isActive ? 1 : 0)};

	${transitionDefault}
`;

export default ItemGroupActive;
