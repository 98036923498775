import { useState } from 'react';
import styled from 'styled-components';
import { Spacer8, Spacer48 } from 'components/Spacer';
import { H1, H5 } from '../ContentTypography';
import ContentChapterBase from './ContentChapterBase';

function ContentChapter({ subtitle, title, children }) {
	return (
		<>
			{subtitle && <Spacer48 id={subtitle.replace(' ', '-').toLowerCase()} />}
			{subtitle == null && title == 'Overview' && <Spacer48 id="introduction" />}
			<ContentChapterBase>
				{subtitle && (
					<>
						<H5 opacity={0.33}>{subtitle}</H5>
						<Spacer8 />
					</>
				)}
				<H1>{title}</H1>
				{children}
			</ContentChapterBase>
		</>
	);
}

export default ContentChapter;
