import styled from 'styled-components';

const WrapperContainerSubsection = styled.div`
	margin: 48px 0;
	padding: 0;

	&:last-of-type {
		margin: 48px 0 0 0;
	}
`;

export default WrapperContainerSubsection;
