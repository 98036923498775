import styled from 'styled-components';

const GridContainerAside = styled.div`
	grid-column-start: aside-start;
	grid-column-end: aside-end;

	margin: 0;
	/*padding: 64px 0;*/
	padding: 166px 0;
`;

export default GridContainerAside;
