import React, { useState } from "react";

import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import GridContainerAside from "layouts/PrivateLayout/GridContainerAside";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import Table from "components/Table";
import ReactSelect from "react-select";
function StickinessTopDrivers() {
  const [driverTable, setDriverTable] = useState("DAU/MAU");
  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader
          key="contentHeader"
          subtitle="Stickiness"
          title="Top Drivers"
        />
        <ContentCard>
          <ReactSelect
            onChange={newValue => {
              if (newValue) {
                setDriverTable(newValue.value);
              }
            }}
            styles={customSelectStyles}
            placeholder={"Select Driver Table"}
            className="driver-second-level-select "
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            maxMenuHeight={200}
            name="color"
            options={[
              { value: "DAU/MAU", label: "DAU/MAU Driver" },
              { value: "DAU/WAU", label: "DAU/WAU Driver" },
              { value: "WAU/MAU", label: "WAU/MAU Driver" },
              { value: "ALL", label: "Show All Drivers" }
            ]}
          />
          {(driverTable === "DAU/MAU" || driverTable === "ALL") && (
            <Table
              config={{
                breakdownLevelCol: "breakdown_level",
                analysisForSegmentCol: "analysis_for_segment",
                segmentCol: "segment",
                currentValueCol: "dau_mau_inst_t1",
                oldValueCol: "dau_mau_inst_t2",
                breakdownCol: "breakdown_level",
                timeDeltaCol: "change_time_days",
                showCols: [
                  "full_segment",
                  "dau_mau_inst_t1",
                  "dau_mau_inst_t2",
                  "contribution"
                ],
                labels: [
                  "Segments",
                  "DAU/MAU current",
                  "DAU/MAU 1 Year old",
                  "contribution"
                ],
                subtype: "DRIVER_TABLE",
                path:
                  "stickiness_module/drivers/complete_dau_mau_stickiness_driver_data.csv",
                columnStyling:
                  "[{}, {}, {}, { factor: '%', color: true , sparkline: 'true' }, {}]"
              }}
              text={"DAU/MAU Drivers for PicsArt"}
            />
          )}
          {(driverTable === "DAU/WAU" || driverTable === "ALL") && (
            <Table
              config={{
                breakdownLevelCol: "breakdown_level",
                analysisForSegmentCol: "analysis_for_segment",
                segmentCol: "segment",
                currentValueCol: "dau_wau_inst_t1",
                oldValueCol: "dau_wau_inst_t2",
                breakdownCol: "breakdown_level",
                timeDeltaCol: "change_time_days",
                showCols: [
                  "full_segment",
                  "dau_wau_inst_t1",
                  "dau_wau_inst_t2",
                  "contribution"
                ],
                labels: [
                  "Segments",
                  "DAU/WAU current",
                  "DAU/WAU 1 Year old",
                  "contribution"
                ],
                subtype: "DRIVER_TABLE",
                path:
                  "stickiness_module/drivers/complete_dau_wau_stickiness_driver_data.csv",
                columnStyling:
                  "[{}, {}, {}, { factor: '%', color: true , sparkline: 'true' }, {}]"
              }}
              text={"DAU/WAU Drivers for PicsArt"}
            />
          )}
          {(driverTable === "WAU/MAU" || driverTable === "ALL") && (
            <Table
              config={{
                breakdownLevelCol: "breakdown_level",
                analysisForSegmentCol: "analysis_for_segment",
                segmentCol: "segment",
                currentValueCol: "wau_mau_inst_t1",
                oldValueCol: "wau_mau_inst_t2",
                breakdownCol: "breakdown_level",
                timeDeltaCol: "change_time_days",
                showCols: [
                  "full_segment",
                  "wau_mau_inst_t1",
                  "wau_mau_inst_t2",
                  "contribution"
                ],
                labels: [
                  "Segments",
                  "WAU/MAU current",
                  "WAU/MAU 1 Year old",
                  "contribution"
                ],
                subtype: "DRIVER_TABLE",
                path:
                  "stickiness_module/drivers/complete_wau_mau_stickiness_driver_data.csv",
                columnStyling:
                  "[{}, {}, {}, { factor: '%', color: true , sparkline: 'true' }, {}]"
              }}
              text={"WAU/MAU Drivers for PicsArt"}
            />
          )}
        </ContentCard>
      </GridContainerContent>
      <GridContainerAside>
        <></>
      </GridContainerAside>
    </GridContainer>
  );
}

export default StickinessTopDrivers;
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgb(0,0,0,0.2)",
    background: state.isSelected ? "black" : "white",
    fontColor: state.isSelected ? "white" : "black"
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "black";
    return { ...provided, opacity, transition, color };
  }
};
