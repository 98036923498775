import { IconEye, IconEyeOff } from 'components/Icons';
import PeekPasswordIcon from './PeekPasswordIcon';

function PeekPassword({ onClick, isPasswordVisible }) {
	return (
		<PeekPasswordIcon onClick={onClick}>
			{isPasswordVisible ? (
				<IconEyeOff width={20} height={20} color="#000" opacity={1} />
			) : (
				<IconEye width={20} height={20} color="#000" opacity={1} />
			)}
		</PeekPasswordIcon>
	);
}

export default PeekPassword;
