import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

// max-height: ${(props) => (props.error ? '100%' : '0%')};
const InputError = styled.div`
	/*min-height: 16px;*/
	/*position: relative;*/
	max-height: ${(props) => (props.error ? '24px' : '0px')};
	/*margin: 0 0 -16px 0;*/
	/*border: 1px solid blue;*/
	/*overflow: hidden;*/
	/*padding: 8px 0 0 0;*/
	opacity: ${(props) => (props.error ? 1 : 0)};
	/*background: rgba(0, 255, 0, 0.2);*/
	${transitionDefault}
`;
// opacity: ${(props) => (props.error ? 1 : 0)};

export default InputError;
