import styled from 'styled-components';

const LayoutAuth = styled.main`
	display: inline-flex;
	flex-direction: row;

	max-width: calc(602px + 32px);
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;

	transform: translateX(-88px); // 176 / 2 px
	/*border: 1px solid red;*/
`;

export default LayoutAuth;
