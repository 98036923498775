import { useEffect, useState } from "react";
import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerFull from "layouts/PrivateLayout/GridContainerFull";
import { useValidationTool } from "contexts/ValidationTool";

import axios from "util/axios/server-instance";
import { auth } from "services/firebase";
//import { type, sentiment, loading } from "Constants";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import { P } from "components/Content/ContentTypography";
import FormOmnipresent from "components/Form/FormOmnipresent";
import InputText from "components/Input/InputText";
import ButtonPrimary from "components/Button/ButtonPrimary";

function CompanySettings() {
	const [productName, setProductName] = useState("");
	const [vertical, setVertical] = useState("");
	const [tam, setTam] = useState("");

	useEffect(() => {
		auth.currentUser.getIdToken(true).then(IdToken => {
			console.log(IdToken);
			return axios
				.get("company", {
					headers: { Authorization: `Bearer ${IdToken}` }
				})
				.then(response => {
					console.log("res", response);
					// alert(response.data.name);
					// alert(response.data.vertical);
					// alert(response.data.size);
					setProductName(response.data.name);
					setVertical(response.data.vertical);
					setTam(response.data.size);
				})
				.catch(error => {
					console.log("error", error);
				});
		});
	}, []);

	return (
		<GridContainer>
			<GridContainerFull>
				<ContentHeader subtitle={null} title="Company Settings" />
				<ContentCard formContent>
					<FormOmnipresent onSubmit={"company"}>
						<InputText
							// name="productName"
							name="name"
							label="Your product name"
							placeholder="What's the name of your product?"
							// defaultValue="PicsArt"
							defaultValue={productName}
							required
						/>
						<InputText
							name="vertical"
							label="Company Vertical"
							placeholder="What vertical is your product in?"
							// defaultValue="Consumer Subscription"
							defaultValue={vertical}
							required
						/>
						<InputText
							// name="tam"
							name="size"
							label="Total addressable market size"
							placeholder="How big is the TAM?"
							// defaultValue="$3 billion"
							defaultValue={tam}
							required
						/>
						<ButtonPrimary action="submit">
							Update Company Settings
						</ButtonPrimary>
						{/* //add disabled button state */}
					</FormOmnipresent>
				</ContentCard>
				{/* <ContentCard title="Manage members"> */}
				{/* 	CREATE MEMBER COMPONENTS */}
				{/* </ContentCard> */}
				{/* <ContentCard title="Manage data"> */}
				{/* CREATE MEMBER COMPONENTS */}
				{/* </ContentCard> */}
			</GridContainerFull>
		</GridContainer>
	);
}

export default CompanySettings;
