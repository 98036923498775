import { useState, useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { motion, useAnimation } from "framer-motion";
// import { theme } from 'contexts/Theme';
//import { H3 } from "components/Content/ContentTypography";

import Table from "./Table";
import TableHeader from "./TableHeader";
import TableHeaderItem from "./TableHeaderItem";
import TableContent from "./TableContent";
import TableContentRow from "./TableContentRow";
import TableContentRowItem from "./TableContentRowItem";
import TableExpandCollapse from "../TableExpandCollapse";
import { getStringFromLargeNumber } from "components/Chart/functions";

import { stylesNoSelect, transitionDefault } from "styles/Styles";
import { transitionSpring } from "Constants";

function SimpleTable({ data, config }) {
  useEffect(() => {
    // we want to check if we will need to parse the config.columnStyling

    if (config.columnStyling && typeof config.columnStyling === "string") {
      // we need to parse it
      let newJson = config.columnStyling.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
      newJson = newJson.replace(/'/g, '"');

      config.columnStyling = JSON.parse(newJson);
    }
  }, []);

  const theme = useContext(ThemeContext);
  const flexMap = [1, 1, 1.2, 1, 1, 1, 1, 1];
  const [rowCount, setRowCount] = useState(6);
  const animateTableRows = useAnimation();

  const valueManager = (value, index) => {
    // use the config to manage values
    //manage color
    let stringColor = null;

    if (config?.columnStyling && config.columnStyling[index]?.color) {
      stringColor = value >= 0 ? "green" : "red";
    }

    if (config?.columnStyling && config.columnStyling[index]?.factor) {
      return (
        <TableContentRowItem color={stringColor} flex={flexMap[0]}>
          {getStringFromLargeNumber(value, config.columnStyling[index]?.factor)}
        </TableContentRowItem>
      );
    } else {
      return (
        <TableContentRowItem color={stringColor} flex={flexMap[0]}>
          {getStringFromLargeNumber(value)}
        </TableContentRowItem>
      );
    }
  };

  const handleRowExpand = () => {
    setRowCount(data.length);
    animateTableRows.start({
      maxHeight: 60 * data.length - 4,
      transition: transitionSpring,
    });
  };

  const handleRowCollapse = () => {
    setRowCount(6);
    animateTableRows.start({
      maxHeight: 60 * 6 - 4,
      transition: transitionSpring,
    });
  };

  return (
    <Table>
      <TableHeader>
        {Object.keys(data[0]).map((element, index) => (
          <TableHeaderItem flex={flexMap[index]}>
            {config.labels ? config.labels[index] : element}
          </TableHeaderItem>
        ))}
      </TableHeader>
      <TableContent>
        <motion.div
          animate={animateTableRows}
          initial={{ maxHeight: 60 * 6 - 4, overflow: "hidden" }}
        >
          {data.map((element, index) => (
            <TableContentRow>
              {Object.values(element).map((val, index) => {
                return valueManager(val, index);
              })}
            </TableContentRow>
          ))}
        </motion.div>
      </TableContent>
      {data.length > 6 && (
        <TableExpandCollapse
          isCollapsed={rowCount <= 6}
          handleRowExpand={handleRowExpand}
          handleRowCollapse={handleRowCollapse}
        />
      )}
    </Table>
  );
}

export default SimpleTable;
