//experimental
import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { theme } from "contexts/Theme";
import * as _ from "lodash";
import { Group } from "@visx/group";
import { Line, Circle } from "@visx/shape";
import { withParentSize } from "@visx/responsive";
import { scaleLinear, updateScale } from "@visx/scale";
import { AxisBottom, AxisLeft, Orientation } from "@visx/axis";
import {
  getMaxValueInMatrix,
  getStringFromLargeNumber
} from "components/Chart/functions";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { LinearGradient } from "@visx/gradient";
import { MarkerArrow } from "@visx/marker";
import { AnimatedAxis } from "@visx/react-spring";
import ChartZoom from "./ChartZoom";

var randomColor = require("randomcolor");

const ChartToRender = ({ data, config, parentWidth, parentHeight }) => {
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;
  let InnerWidth = chartWidth - chartML - chartMR;
  let InnerHeight = chartHeight - chartMT - chartMB;
  // scales
  const [chartZoom, setChartZoom] = useState(true);
  const [xDomainState, setXDomainState] = useState([0, 1]);
  const axisLeftOffset = 16;

  const scales = useMemo(() => {
    let xDomain = [];
    let yDomain = [];
    if (chartZoom) {
      // give the zoomed in scales
      xDomain = [
        _.minBy(data, function(o) {
          return o[config.xAxisCol];
        })[config.xAxisCol],

        _.maxBy(data, function(o) {
          return o[config.xAxisCol];
        })[config.xAxisCol]
      ];

      yDomain = [
        _.minBy(data, function(o) {
          return o[config.yAxisCol];
        })[config.yAxisCol],

        _.maxBy(data, function(o) {
          return o[config.yAxisCol];
        })[config.yAxisCol]
      ];
    } else {
      // not zoomed in scales
      xDomain = [
        Math.min(
          0,
          _.minBy(data, function(o) {
            return o[config.xAxisCol];
          })[config.xAxisCol]
        ),
        _.maxBy(data, function(o) {
          return o[config.xAxisCol];
        })[config.xAxisCol]
      ];

      yDomain = [
        Math.min(
          0,
          _.minBy(data, function(o) {
            return o[config.yAxisCol];
          })[config.yAxisCol]
        ),
        _.maxBy(data, function(o) {
          return o[config.yAxisCol];
        })[config.yAxisCol]
      ];

      // xDomain = [0, 2];
      // yDomain = [0, 2];
    }

    setXDomainState(xDomain);

    return {
      xScale: scaleLinear({
        domain: xDomain,
        range: [0, InnerWidth - axisLeftOffset],
        nice: true
      }),
      yScale: scaleLinear({
        domain: yDomain,
        range: [InnerHeight, 0],
        nice: true
      })
    };
  }, [chartZoom]);

  // tooltip systems
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white"
  };
  let tooltipTimeout;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true
  });

  // animating the scatterchart

  const [numberOfCircles, setNumberOfCircles] = useState(0);

  useEffect(() => {
    const AnimationInterval = setInterval(() => {
      // add one more circle to show
      setNumberOfCircles(prev => (prev >= data.length ? 0 : prev + 1));
    }, 1000);
    return () => {
      clearInterval(AnimationInterval);
    };
  }, []);

  function giveHeadingValue() {
    if (Object.keys(data[0]).includes("labels")) {
      return tooltipData["labels"];
    } else if (config.labelCol) {
      return tooltipData[config.labelCol];
    } else if (config.dateCol) {
      return tooltipData[config.dateCol];
    } else {
      return "heading";
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <svg ref={containerRef} width={chartWidth} height={chartHeight}>
        <Group top={chartMT} left={chartML}>
          {/* <LinearGradient */}
          {/*   from="lime" */}
          {/*   to="red" */}
          {/*   fromOpacity={0.3} */}
          {/*   toOpacity={0.8} */}
          {/*   rotate="45" */}
          {/*   id={"test-grad"} */}
          {/* /> */}
          {/* <rect */}
          {/*   fill="url(#test-grad)" */}
          {/*   opacity={1} */}
          {/*   width={chartWidth - chartML - chartMR} */}
          {/*   height={chartHeight - chartMT - chartMB} */}
          {/* /> */}
          <AnimatedAxis
            orientation={Orientation.left}
            labelProps={{
              fill: "#978695",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              textAnchor: "middle",
              backgroundFill: "#ff0000",
              // dx: -18,
              dx: -40,
              // dx: -18,
              dy: (chartMT + chartMB) / 2 - 3
            }}
            left={chartWidth - chartML - chartMR + axisLeftOffset}
            tickLength={chartWidth - chartML - chartMR}
            scale={scales.yScale}
            label={config.yAxisLabel}
            numTicks={5}
            stroke={theme.chart.colors.stroke}
            tickStroke={theme.chart.colors.tickStroke}
            tickClassName={"scatter-tick-left"}
            tickLabelProps={(value, index) => ({
              fill: "#a391a0",
              fontSize: "12px",
              fontWeight: 500,
              textAnchor: "end",
              dx: -8,
              dy: 4
            })}
            hideAxisLine={true}
          />
          <AnimatedAxis
            orientation={Orientation.bottom}
            labelProps={{
              fill: "#978695",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              textAnchor: "middle",
              y: chartHeight + chartMB - 6,
              dx: -chartML - axisLeftOffset / 2
              // backgroundFill: "#ff0000"
            }}
            label={config.xAxisLabel}
            top={0}
            numTicks={7}
            tickLength={chartHeight - chartMT - chartMB}
            scale={scales.xScale}
            stroke={theme.chart.colors.stroke}
            tickStroke={theme.chart.colors.tickStroke}
            tickLabelProps={(value, index) => ({
              fill: "#a391a0",
              fontSize: "12px",
              fontWeight: 500,
              textAnchor: "middle",
              // dy: 10
              dy: 20
              // dx: axisLeftOffset
            })}
            left={axisLeftOffset}
            hideAxisLine={true}
          />
          <Group left={axisLeftOffset}>
            {config.showDiagonal &&
              scales.xScale.domain()[1] >= 1 &&
              scales.yScale.domain()[1] >= 1 && (
                <Line
                  to={{
                    x: scales.xScale(0),
                    y: scales.yScale(1)
                  }}
                  from={{
                    x: scales.xScale(1),
                    y: scales.yScale(0)
                  }}
                  strokeWidth={1}
                  stroke="black"
                  opacity={0.8}
                  pointerEvents="none"
                  strokeDasharray="2,4"
                />
              )}

            {data.map((element, index) => {
              if (index < numberOfCircles) {
                return (
                  <>
                    <Circle
                      key={`circle-${index}`}
                      cx={scales.xScale(element[config.xAxisCol])}
                      cy={scales.yScale(element[config.yAxisCol])}
                      r={
                        10 +
                        15 *
                          (element[config.circleSizeCol] /
                            getMaxValueInMatrix(data, [config.circleSizeCol])) *
                          (element[config.circleSizeCol] /
                            getMaxValueInMatrix(data, [config.circleSizeCol]))
                      }
                      opacity={0.66}
                      strokeWidth={1.3}
                      stroke={'#ff8d00'}
                      fill={'#ff8d00'}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={event => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        // TooltipInPortal expects coordinates to be relative to containerRef
                        // localPoint returns coordinates relative to the nearest SVG, which
                        // is what containerRef is set to in this example.
                        const eventSvgCoords = localPoint(event);
                        showTooltip({
                          tooltipData: element,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: eventSvgCoords?.x
                        });
                      }}
                    />{" "}
                    {index < data.length &&
                      index > 0 &&
                      index < numberOfCircles && (
                        <>
                          <MarkerArrow
                            id={`marker-arrow-${index}`}
                            fill="black"
                            refX={6}
                            size={6}
                          />
                          <Line
                            markerEnd={`url(#marker-arrow-${index})`}
                            to={{
                              x: scales.xScale(element[config.xAxisCol]),
                              y: scales.yScale(element[config.yAxisCol])
                            }}
                            from={{
                              x: scales.xScale(
                                data[index - 1][config.xAxisCol]
                              ),
                              y: scales.yScale(data[index - 1][config.yAxisCol])
                            }}
                            strokeWidth={1}
                            stroke="black"
                            opacity={0.4}
                            pointerEvents="none"
                            // strokeDasharray="2,2"
                          />
                        </>
                      )}
                  </>
                );
              }
            })}
          </Group>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: "orange" }}>
            <strong>{giveHeadingValue()}</strong>
          </div>
          <div>
            {`${config.infoColLabel}: ${getStringFromLargeNumber(
              tooltipData[config.infoCol]
            )}`}
          </div>
          <div />
        </TooltipInPortal>
      )}
      <ChartZoom
        xDomainState={xDomainState}
        chartZoom={chartZoom}
        setChartZoom={setChartZoom}
      />
    </div>
  );
};

const Button = styled.button`
  background-color: black;
  color: white;
`;
let ScatterChart = withParentSize(ChartToRender);

export default ScatterChart;
