import styled from 'styled-components';

const ContainerChart = styled.div`
	margin: 32px 0;

	&:last-child {
		margin: 32px 0 0 0;
	}
`;

export default ContainerChart;