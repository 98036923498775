import React, { useContext, useState, useEffect } from "react";
import firebase, { auth, googleProvider } from "services/firebase";

const AuthContext = React.createContext();

// useAuth hook returns the value object
export function useAuth() {
  return useContext(AuthContext);
}

// the AuthProvider wrapper that is wrapped around our App
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  // when the app mounts first time, loading will be true
  const [loading, setLoading] = useState(true);

  function signupWithEmail(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function loginWithEmail(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function signInWithGooglePopup() {
    return auth.signInWithPopup(googleProvider);
  }

  function updatePassword(newPassword) {
    let userEmail = currentUser.email;

    currentUser
      .updatePassword(newPassword)
      .then(function () {
        // Update successful.
        console.log("Sucessfully updated");
        //relogin
        auth.signInWithEmailAndPassword(userEmail, newPassword);
      })
      .catch(function (error) {
        // An error happened.
        console.log("Something went wrong!");
        console.log(error);
      });
  }
  //   function resetPassword(email) {
  //     return auth.sendPasswordResetEmail(email);
  //   }

  //   function updateEmail(email) {
  //     return currentUser.updateEmail(email);
  //   }

  //   function updatePassword(password) {
  //     return currentUser.updatePassword(password);
  //   }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    loading,
    currentUser,
    loginWithEmail,
    signupWithEmail,
    logout,
    signInWithGooglePopup,
    updatePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
