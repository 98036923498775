// this is the default instance for fetching data from Sundial API
import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.sundial.so/",
  //baseURL: process.env.REACT_APP_SUNDIAL_PUBLIC_API_BASE_URL,
  //baseURL: "http://localhost:3000/",
});

export default instance;
