import { useState } from 'react';
import styled from 'styled-components';
import { Spacer8, Spacer48 } from 'components/Spacer';
import { H1, H5 } from '../ContentTypography';
import ContentCardBase from './ContentCardBase';

function ContentCard({ subtitle, title, children, formContent }) {
	return (
		<>
			<Spacer48 />
			{/* CREATE A WAY TO MAKE THIS A FLEX COMPONENT  */}
			{/* WITH THE RIGHT SIDE HAVING ACTION PROPS */}
			<ContentCardBase formContent={formContent}>
				{title && <H1>{title}</H1>}
				{children}
			</ContentCardBase>
		</>
	);
}

export default ContentCard;
