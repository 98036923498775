import { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { H5 } from "components/Content/ContentTypography";
import { transitionDefault, transitionDefaultFast } from "styles/Styles";

import Input from "../Input";
import InputLabel from "../InputLabel";
import InputField from "../InputField";
import InputError from "../InputError";
import InputErrorText from "../InputErrorText";

function InputText({
	name,
	label,
	placeholder,
	defaultValue,
	onChange,
	onFocus,
	onBlur,
	index,
	isFaded,
	required,
	error,
	disabled,
	type,
	isTransparent
}) {
	const theme = useContext(ThemeContext);
	const [value, setValue] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		if (error != "" && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	const onChangeHelper = value => {
		setValue(value);
		onChange(name, value);
	};

	return (
		<Input isFaded={isFaded}>
			<InputLabel error={error} required={required}>
				<H5
					opacity={0.5}
					// opacity={error ? 1 : 0.5}
					// color={error && theme.colors.colorRed}
				>
					{label}
				</H5>
			</InputLabel>
			<InputField
				type={type ? type : "text"}
				placeholder={placeholder}
				onChange={e => onChangeHelper(e.target.value)}
				onFocus={() => onFocus(index)}
				onBlur={() => onBlur(index)}
				value={value}
				required={required}
				isFaded={isFaded}
				error={error != null && error != "" && valueOnDelayedError == value}
				disabled={disabled}
				isTransparent={isTransparent}
			/>
			<InputError error={error}>
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
		</Input>
	);
}

export default InputText;
