import styled from 'styled-components';
import { stylesNoSelect, transitionDefault } from 'styles/Styles';

// background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.12)' : 'rgba(255, 255, 255, 0.33)')};
const InputField = styled.input`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};

	width: 100%;
	height: 56px;

	margin: 0;
	padding: 0 20px;

	font-size: 18px;
	line-height: 32px;
	font-weight: 400;

	/*background: rgba(255, 237, 237, 0.2);*/
	/*background: rgba(0, 0, 0, 0.02);*/
	background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0)')};
	color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.75)')};

	${(props) => props.disabled && props.defaultValue != null && 'background: rgba(255, 255, 255, 1) !important;'}

	border: ${(props) =>
		props.error
			? `1px solid ${props.theme.colors.colorRed}`
			: props.disabled
			? '1px solid rgba(33, 0, 0, 0.12)'
			: '1px solid rgba(33, 0, 0, 0.2)'};
	border-radius: 8px;
	outline: none;

	${(props) => props.disabled && 'cursor: not-allowed;'}

	&:focus {
		background: ${(props) => (props.isTransparent ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 237, 237, 0.33)')};
		border: 1px solid rgba(33, 0, 0, 0.33);
	}

	${stylesNoSelect}
	${transitionDefault}
`;

export default InputField;

// background: ${(props) => (props.transparent ? 'rgba(255, 255, 255, 0.33)' : 'rgba(255, 255, 255, 1)')};
