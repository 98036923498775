import styled from "styled-components";
import { H5 } from "components/Content/ContentTypography";
import { stylesNoSelect } from "styles/Styles";

const ContainerLabel = styled.div`
	display: flex;
	flex-direction: row;
`;

const LabelText = styled.div`
	flex: 1;
	margin: 0 0 8px 0;
	font-size: 14px;
	line-height: 20px;
	${stylesNoSelect}
`;

const LabelOptional = styled.div`
	flex: 0 auto;
	margin: 0 0 8px 0;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	${stylesNoSelect}
`;

function InputLabel({ children, required }) {
	return (
		<ContainerLabel>
			<LabelText>{children}</LabelText>
			{(required == false || required == undefined) && (
				<LabelOptional>
					<H5 opacity={0.33}>optional</H5>
				</LabelOptional>
			)}
		</ContainerLabel>
	);
}

export default InputLabel;
