import styled from 'styled-components';

const ContentNumberContent = styled.div`
	flex: 1;
	margin: 0;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: 1;

	// Last child no longer works here like Overall Story
	.chart,
	.graph,
	.table {
		margin: 32px 0;

		&:last-child {
			margin: 32px 0;
		}
	}

	&:last-child {
		.chart,
		.graph,
		.table {
			&:last-child {
				margin: 32px 0 0 0;
				/*border: 1px solid blue;*/
			}
		}
	}

	// For nested graphs, which become small
	.content-number__content {
		.chart,
		.graph,
		.table {
			margin: 16px 0;

			&:last-child {
				margin: 16px 0 0 0;
			}
		}
	}
`;

export default ContentNumberContent;
