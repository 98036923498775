import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const TableContentRowItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  font-size: 16px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : "#000000")};
  opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
`;

export default TableContentRowItem;
