import styled from "styled-components";
import * as _ from "lodash";
const SparkLineDiv = styled.div`
  //margin-left: 5px;
  background-color: ${(props) => (props.data < 0 ? "red" : "lime")};
  border-radius: 10px;
  width: ${(props) => `${_.clamp(Math.abs(props.data) * 100, 0, 101)}%`};
  height: 5px;
  opacity: 1;
`;

const SparkLine = (props) => (
  <SparkLineDiv data={props.data}>&nbsp;</SparkLineDiv>
);

export default SparkLine;
