const stylesNoSelect = `
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const stylesCardDefault = `
	width: 100%;
	background: rgba(255, 255, 255, 0.85);
	border: 0;
	border-radius: 12px;
	box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.07), 0px 1.8px 2.7px -6px rgba(0, 0, 0, 0.042), 0px 0.5px 0.8px -6px rgba(0, 0, 0, 0.028);
`;

const transitionDefault = `
	-webkit-transition: all 330ms ease-in-out;
	-moz-transition: all 330ms ease-in-out;
	-ms-transition: all 330ms ease-in-out;
	-o-transition: all 330ms ease-in-out;
	transition: all 330ms ease-in-out;
`;

const transitionDefaultFast = `
	-webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
`;

const transitionOpacity = `
	-webkit-transition: opacity 330ms ease-in-out;
	-moz-transition: opacity 330ms ease-in-out;
	-ms-transition: opacity 330ms ease-in-out;
	-o-transition: opacity 330ms ease-in-out;
	transition: opacity 330ms ease-in-out;
`;

export { stylesNoSelect, stylesCardDefault, transitionDefault, transitionDefaultFast, transitionOpacity };
