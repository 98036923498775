import styled from "styled-components";

const ContentFormula = styled.div`
	width: 100%;
	height: auto;
	margin: 0 0 24px 0;
	padding: 12px 16px;

	font-family: monospace;
	font-size: 16px;
	font-weight: 600;

	background: rgba(0, 0, 0, 0.03);
	color: rgba(0, 0, 0, 0.75);

	text-align: center;
	border-radius: 8px;
`;

export default ContentFormula;
