import { useState } from "react";

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
} from "components/Content/ContentTypography";
import { type, sentiment } from "Constants";

import ContentChapter from "components/Content/ContentChapter";
import ContentPreview from "components/Content/ContentPreview";
import ContentPreviewJump from "components/Content/ContentPreview/ContentPreviewJump";
import ContentAnchorDeep from "components/Content/ContentAnchorDeep";
import ContentDivider from "components/Content/ContentDivider";
import ContentSynopsis from "components/Content/ContentSynopsis";
import ContentSubsection from "components/Content/ContentSubsection";
import ContentChart from "components/Content/ContentChart";
import Chart from "components/Chart";
import Table from "components/Table";
import ContentBullet from "components/Content/ContentBullet";
import ContentNumber from "components/Content/ContentNumber";
import ContentUnorderedList from "components/Content/ContentUnorderedList";
import ContentOrderedList from "components/Content/ContentOrderedList";
import ContentFormula from "components/Content/ContentFormula";

function StoryEngine({ data, title }) {
  let titleFormatted = title ? title.replace(" ", "-").toLowerCase() : "";

  let numberInitial = 1;
  const buildStory = (storyData) => {
    if (!storyData) return <></>;
    else
      return storyData.map((block, index) => {
        // console.log("block");
        // console.log(block);
        if (block == null) {
          //console.log("null element in JSON", index, block);
          return <></>;
        } else if (block.type == type.SECTION) {
          return (
            <ContentChapter
              key={`${titleFormatted}-content-chapter--${index}`}
              subtitle={block.subtitle}
              title={block.title}
            >
              {buildStory(block.content)}
            </ContentChapter>
          );
        } else if (block.type == type.SUBSECTION) {
          return (
            <ContentSubsection
              key={`${titleFormatted}-content-subsection--${index}`}
              subtitle={block.subtitle}
              title={block.title}
              config={JSON.parse(block.config)}
            >
              {buildStory(block.content)}
            </ContentSubsection>
          );
        } else if (block.type == type.UNORDERED_LIST) {
          return (
            <ContentUnorderedList
              key={`${titleFormatted}-content-ul--${index}`}
              startingText={block.text}
            >
              {buildStory(block.content)}
            </ContentUnorderedList>
          );
        } else if (block.type == type.ORDERED_LIST) {
          numberInitial = 1;
          return (
            <ContentOrderedList
              key={`${titleFormatted}-content-ol--${index}`}
              startingText={block.text}
            >
              {buildStory(block.content)}
            </ContentOrderedList>
          );
        } else if (block.type == type.P) {
          return <P>{block.text}</P>;
        } else if (block.type == type.FORMULA) {
          return <ContentFormula>{block.text}</ContentFormula>;
        } else if (block.type == type.DIVIDER) {
          return <ContentDivider />;
        } else if (block.type == type.BULLET) {
          return (
            <ContentBullet
              key={`${titleFormatted}-content-bullet--${index}`}
              content={block.content && buildStory(block.content)}
              text={block.text}
            />
          );

          /////////////////////////////
          // if (block.text != null) {
          //   return (
          //     <ContentBullet key={`${titleFormatted}-content-bullet--${index}`} text>
          //       {block.text}
          //     </ContentBullet>
          //   );
          // } else if (block.content != null) {
          //   return (
          //     <ContentBullet key={`${titleFormatted}-content-bullet--${index}`} content>
          //       {buildStory(block.content)}
          //     </ContentBullet>
          //   );
          // }
        } else if (block.type == type.NUMBER) {
          return (
            <ContentNumber
              key={`${titleFormatted}-content-number--${index}`}
              number={numberInitial++}
              content={block.content && buildStory(block.content)}
              text={block.text}
            />
          );
          // if (block.text != null) {
          //   return (
          //     // <ContentNumber key={`${titleFormatted}-content-number--${index}`} number={block.number} text>
          //     <ContentNumber key={`${titleFormatted}-content-number--${index}`} number={number++} text>
          //       {block.text}
          //     </ContentNumber>
          //   );
          // } else if (block.content != null) {
          //   return (
          //     <ContentNumber key={`${titleFormatted}-content-number--${index}`} number={block.number} content>
          //       {buildStory(block.content)}
          //     </ContentNumber>
          //   );
          // }
        } else if (block.type == type.CHART) {
          // console.log(block);
          return (
            <Chart
              key={`${titleFormatted}-content-chart--${index}`}
              text={block.title}
              config={block.config}
            />
          );
        } else if (block.type == type.TABLE) {
          // console.log(block);
          return (
            <Table
              key={`${titleFormatted}-table--${index}`}
              text={block.text}
              config={block.config}
            />
          );
        } else if (block.type == type.ANCHOR_DEEP) {
          return (
            <ContentAnchorDeep
              key={`${titleFormatted}-content-anchor-deep--${index}`}
              url={block.config?.url ?? "#"}
            >
              {block.text}
            </ContentAnchorDeep>
          );
        } else if (block.type == type.SYNOPSIS) {
          console.log("SYNOPSIS", block, JSON.parse(block.config));
          
          return (
            <ContentSynopsis
              key={`${titleFormatted}-content-synopsis--${index}`}
              sentiment={JSON.parse(block.config).sentiment}
            >
              {block.text}
            </ContentSynopsis>
          );
        } else if (block.type == type.PREVIEW) {
          return (
            <ContentPreview
              key={`${titleFormatted}-content-preview--${index}`}
              title={block.title}
            >
              {block.content.map((previewJump, count) => (
                <ContentPreviewJump
                  key={`${titleFormatted}-content-preview--${index}--${count}`}
                  subtitle={previewJump.subtitle}
                  text={previewJump.text}
                  href={`#${previewJump.subtitle
                    .replace(" ", "-")
                    .toLowerCase()}`}
                />
              ))}
            </ContentPreview>
          );
        } else {
          return (
            <>
              {process.env.NODE_ENV == "production" ? (
                <></>
              ) : (
                <>
                  "Unrecognized Element : (This message only shows on dev)"
                  {console.log("UNRECOGNIZED", block)}
                </>
              )}
            </>
          );
        }
      });
  };

  return <>{buildStory(data)}</>;
}

export default StoryEngine;
