import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const DeepContentIcon = styled.span`
  position: absolute;
  /*left: calc(100% - 24px);*/
  left: ${(props) => (props.isHovering ? 'calc(100% + 8px)' : '100%')};
  display: block;
  margin: 0;
  padding: 0 0 0 8px;
  opacity: 1;
  ${transitionDefault}
`;

export default DeepContentIcon;