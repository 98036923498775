import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const OuterBox = styled.div`
  font-size: 1.2rem;
  width: 100vw;
  padding: 1rem 5rem;
  color: ${(props) => props.theme.colors.colorTextProminent};
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled(Link)`
  color: ${(props) => props.theme.colors.colorTextProminent};
  font-weight: bold;
`;

const ModLink = styled(Link)`
  margin-right: 20px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.colorTextProminent};
  &:hover {
    color: ${(props) => props.theme.colors.colorTextNormal};
    font-weight: bold;
  }
`;

function Navbar() {
  return (
    <OuterBox>
      <Logo to="/">Sundial</Logo>
      <div>
        <ModLink to="/">Home</ModLink>
        <ModLink to="/careers">Careers</ModLink>
        <ModLink to="/login">Login</ModLink>
      </div>
    </OuterBox>
  );
}

export default Navbar;
