import React from "react";
import { ThemeProvider } from "styled-components";

// please update this theme object
const theme = {
  chart: {
    // default chart configuration
    // marginLeft: 50,
    // marginRight: 10,
    // marginLeft: 40,
    marginLeft: 28,
    marginRight: 0,
    marginTop: 0,
    // marginBottom: 44,
    marginBottom: 28,
    // marginLeft: 0,
    // marginRight: 0,
    // marginTop: 0,
    // marginBottom: 0,
    // chartRatio: 0.6172839506,
    // chartRatio: 0.5625,
    chartRatio: 0.5029761905,
    background: "rgba(0, 0, 0, 0)",
    // background: "rgba(0, 0, 0, 0.15)",
    colors: {
      background: "#000000",
      backgroundOpacity: 0,
      // backgroundOpacity: 0.15,
      stroke: "#cccccc",
      tickStroke: "#f7e8ef",
      // tickStroke: "#000000",
      // tickStroke: "#cccccc"
      lines: [
        "#FF5D39",
        "#B744FA",
        "#04B35D",
        "#0762f9",
        "#FF902B",
        "#0CA6B3",
        "#1CD6E5"
      ]
      // lines: ["#ff0000", "#00ff00", "#0000ff"]
    }
    // =======
    //       background: "#F3F7F6",
    //       backgroundOpacity: 1,
    //       stroke: "#cccccc",
    //       tickStroke: "#cccccc",
    //     },
    // >>>>>>> 5a7c617f72ff6bce2869ca2df7fdd969050588b8
  },
  colors: {
    accentGrowth: "#0CD319",
    accentStickiness: "#FF9500",
    accentRetention: "#FF2A68",
    colorTextNormal: "rgba(0, 0, 0, 0.66)",
    colorTextProminent: "rgba(0, 0, 0, 0.75)",
    colorGreen: "#0CD319",
    colorRed: "#FF2A68"
  },
  fonts: ["Work Sans", "Inter", "sans-serif"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
// use the following name export to use the theme object outside the scope of StyledComponents like Charts
export { theme };
