import * as _ from "lodash";
import millify from "millify";
/**
 * A function that gives maximum value of a 2dArray/Matrix. Useful in calculating the
 * max value for Y-axis limit.
 * @param  Array2D: an array of objects
 * @param  ArrayOfCols: a array of strings with name of cols, ex- ["2016_value", "2017_value"]
 */
function getMaxValueInMatrix(Array2D, ArrayOfCols) {
  let maxValuesArr = [];

  ArrayOfCols.forEach(element => {
    let currentMaxObject = _.maxBy(Array2D, function(object) {
      return object[element];
    });

    maxValuesArr.push(currentMaxObject[element]);
  });

  return Math.max(...maxValuesArr);
}

/**
 * A function that gives maximum value for a stackchart when all values of column are stacked.
 * @param  Array2D: an array of objects
 * @param  ArrayOfCols: a array of strings with name of cols, ex- ["2016_value", "2017_value"]
 */
function getMaxStackedValue(Array2D, ArrayOfCols) {
  let maxValuesArr = [];

  Array2D.map(row => {
    // row has all columns that can be stacked
    let sum = 0;
    // find the sum of all positive values
    ArrayOfCols.map(val => {
      if (row[val] > 0) {
        sum = sum + row[val];
      }
    });
    // now sum will max for all positive values in that row
    maxValuesArr.push(sum);
  });
  //console.log(Math.min(...maxValuesArr));
  return Math.max(...maxValuesArr);
}

/**
 * A function that gives minimum value for a stackchart when all values of column are stacked.
 * @param  Array2D: an array of objects
 * @param  ArrayOfCols: a array of strings with name of cols, ex- ["2016_value", "2017_value"]
 */
function getMinStackedValue(Array2D, ArrayOfCols) {
  let maxValuesArr = [];
  //console.log(Array2D);
  //console.log(ArrayOfCols);
  Array2D.map(row => {
    // row has all columns that can be stacked
    let sum = 0;
    // find the sum of all positive values
    ArrayOfCols.map(val => {
      if (row[val] < 0) {
        sum = sum + row[val];
      }
    });
    // now sum will max for all positive values in that row
    maxValuesArr.push(sum);
  });

  //console.log(Math.min(...maxValuesArr));
  return Math.min(...maxValuesArr);
}

/**
 * A function that gives minimum value of a 2dArray/Matrix. Useful in calculating the
 * min value for Y-axis limit.
 * @param  Array2D: a 2-d array of type [[],[]]
 * @param  ArrayOfCols: a array of strings with name of cols, ex- ["2016_value", "2017_value"]
 */

function getMinValueInMatrix(ObjectArray, keysArray) {
  let minValuesArr = [];

  keysArray.forEach(element => {
    let currentMinObject = _.minBy(ObjectArray, function(object) {
      return object[element];
    });

    minValuesArr.push(currentMinObject[element]);
  });

  return Math.min(...minValuesArr);
}

/**
 * A function converts the passed number to readable string.
 * @param  number: a number in float/decimal 123242.4545
 * @param  magnitude: a string "K", "M", "B", "T"
 */

function getStringFromLargeNumber(number, magnitude) {
  switch (magnitude) {
    case "K":
      return (number / 1000).toFixed(2) + "K";
      break;
    case "M":
      return (number / 1000000).toFixed(2) + "M";
      break;
    case "B":
      return (number / 1000000000).toFixed(2) + "B";
      break;
    case "T":
      return (number / 1000000000000).toFixed(2) + "T";
      break;
    case "%":
      return (number * 100).toFixed(2) + "%";
      break;

    case "text":
      return number;
      break;

    default:
      if (typeof number === "number") {
        // It's a number

        return millify(number, { space: false });
      } else {
        return number;
      }
      break;
  }
}

// const monthAbbreviated = {
//   January: "Jan",
//   February: "Feb",
//   March: "Mar",
//   April: "Apr",
//   May: "May",
//   June: "June",
//   July: "Jul",
//   August: "Aug",
//   September: "Sep",
//   October: "Oct",
//   November: "Nov",
//   December: "Dec"
// };

const monthAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

function getShortenedMonth(month, isDate) {
  if (isDate) {
    // console.log(
    //   "isDate",
    //   new Date(month).getMonth(),
    //   monthAbbreviated[new Date(month).getMonth()]
    // );
    let monthProcessed = new Date(month).getMonth();
    if (monthProcessed == 0) return new Date(month).getFullYear();
    else return monthAbbreviated[new Date(month).getMonth()];
  } else {
    return month;
  }
}

export {
  getMaxValueInMatrix,
  getMinValueInMatrix,
  getStringFromLargeNumber,
  getMaxStackedValue,
  getMinStackedValue,
  getShortenedMonth
};
