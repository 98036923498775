import { useState } from "react";

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P
} from "components/Content/ContentTypography";
import { type, sentiment } from "Constants";

import ContentOverview from "components/Content/ContentOverview";
import ContentPoint from "components/Content/ContentPoint";
import ContentAnchorDeep from "components/Content/ContentAnchorDeep";
import ContentDivider from "components/Content/ContentDivider";

// import ContentPreview from 'components/Content/ContentPreview';
// import ContentPreviewJump from 'components/Content/ContentPreview/ContentPreviewJump';
// import ContentDivider from 'components/Content/ContentDivider';
// import ContentSynopsis from 'components/Content/ContentSynopsis';
// import ContentSubsection from 'components/Content/ContentSubsection';
// import ContentChart from 'components/Content/ContentChart';
// import ContentBullet from 'components/Content/ContentBullet';
// import ContentNumber from 'components/Content/ContentNumber';
// import ContentUnorderedList from 'components/Content/ContentUnorderedList';
// import ContentOrderedList from 'components/Content/ContentOrderedList';

function SummaryEngine({ data, title }) {
  let titleFormatted = title.replace(" ", "-").toLowerCase();

  console.log("summary data", data);
  const buildSummary = summaryData => {
    return summaryData.map((block, index) => {
      if (block == null) {
        console.log("null element in JSON", index, block);
        return <></>;
      } else if (block.type == type.OVERVIEW) {
        // console.log('Recursion', index, block, block.content);
        console.log("look here");
        console.log("block.chart", block.chart);
        return (
          <ContentOverview
            key={`${titleFormatted}-content-overview--${index}`}
            subtitle={block.subtitle}
            title={block.title}
            chart={block.chart}
          >
            {block.content && buildSummary(block.content)}
          </ContentOverview>
        );
      } else if (block.type == type.CONCLUSION) {
        return (
          <ContentOverview
            key={`${titleFormatted}-content-conclusion--${index}`}
            subtitle={block.subtitle}
            title={block.text}
            // change this ^
            chart={block.chart}
          >
            {block.content && buildSummary(block.content)}
          </ContentOverview>
        );
      } else if (block.type == type.POINT) {
        return (
          <ContentPoint
            key={`${titleFormatted}-content-point--${index}`}
            subtitle={block.subtitle}
            title={block.title}
            config={JSON.parse(block.config)}
          >
            {block.text}
          </ContentPoint>
        );
      } else if (block.type == type.ANCHOR_DEEP) {
        return (
          <ContentAnchorDeep
            key={`${titleFormatted}-content-anchor-deep--${index}`}
            url={block.config?.url ?? "#"}
          >
            {block.text}
          </ContentAnchorDeep>
        );
      } else if (block.type == type.DIVIDER) {
        return <ContentDivider />;
      } else {
        return (
          <>
            {process.env.NODE_ENV == "production" ? (
              <></>
            ) : (
              "Unrecognized Element : (This message only shows on dev)"
            )}
          </>
        );
      }
    });
  };

  return <>{buildSummary(data)}</>;
}

export default SummaryEngine;
