import styled from 'styled-components';

const ContentHeaderTitle = styled.h1`
	margin: 0 0 -16px 0;
	padding: 0;
	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 40px;
	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
`;

export default ContentHeaderTitle;