import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import RouteWrapper from "layouts/RouterWrapper";

// import pages to render only
import Home from "pages/Home";
import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import ExecutiveSummary from "pages/ExecutiveSummary";
import ErrorPage from "pages/ErrorPage";

import Growth from "pages/Growth";
import GrowthDeepDive from "pages/Growth/DeepDive";
import GrowthCriticalSegments from "pages/Growth/CriticalSegments";
import GrowthTopDrivers from "pages/Growth/TopDrivers";

import Stickiness from "pages/Stickiness";
// import StickinessDeepDive from "pages/Stickiness/DeepDive";
import StickinessCriticalSegments from "pages/Stickiness/CriticalSegments";
import StickinessTopDrivers from "pages/Stickiness/TopDrivers";

import Retention from "pages/Retention";
import RetentionDeepDive from "pages/Retention/DeepDive";
import RetentionCriticalSegments from "pages/Retention/CriticalSegments";
import RetentionTopDrivers from "pages/Retention/TopDrivers";
import Dashboard from "pages/Dashboard";

import Segment from "pages/Segment";
import CompanySettings from "pages/CompanySettings";
import PersonalSettings from "pages/PersonalSettings";
import ChangePassword from "pages/PersonalSettings/ChangePassword";
import Onboarding from "pages/Onboarding";

// import Layouts
import PublicLayout from "layouts/PublicLayout";
import PrivateLayout from "layouts/PrivateLayout";
// importing styles
import "styles/App.css";
import "styles/index.css";

function AppRoutes() {
  // let location = useLocation();

  const isRoutePrivate = (pathname) => {
    const privateRoutes = [
      "/summary",
      "/growth",
      "/growth/deep-dive",
      "/growth/critical-segments",
      "/growth/top-drivers",
      "/stickiness",
      "/stickiness/deep-dive",
      "/stickiness/critical-segments",
      "/stickiness/top-drivers",
      "/retention",
      "/retention/deep-dive",
      "/retention/critical-segments",
      "/retention/top-drivers",
    ];
    console.log("isRoutePrivate", privateRoutes.includes(pathname), pathname);
    return privateRoutes.includes(pathname);
  };

  return (
    <Router>
      {/* <Switch> */}
      <Route
        render={({ location }) => {
          return (
            <Switch>
              <RouteWrapper
                exact
                path="/summary"
                component={ExecutiveSummary}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/growth"
                component={Growth}
                layout={PrivateLayout}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/growth/deep-dive"
                component={GrowthDeepDive}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/growth/critical-segments"
                component={GrowthCriticalSegments}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/growth/top-drivers"
                component={GrowthTopDrivers}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/stickiness"
                component={Stickiness}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              {/* <RouteWrapper */}
              {/*   exact */}
              {/*   path="/engagement/deep-dive" */}
              {/*   component={EngagementDeepDive} */}
              {/*   layout={PrivateLayout} */}
              {/*   location={location} */}
              {/*   isPrivate */}
              {/* /> */}
              <RouteWrapper
                exact
                path="/stickiness/critical-segments"
                component={StickinessCriticalSegments}
                layout={PrivateLayout}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/stickiness/top-drivers"
                component={StickinessTopDrivers}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/retention"
                component={Retention}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/retention/deep-dive"
                component={RetentionDeepDive}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/retention/critical-segments"
                component={RetentionCriticalSegments}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/retention/top-drivers"
                component={RetentionTopDrivers}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/dashboard"
                component={Dashboard}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/segment"
                component={Segment}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/company-settings"
                component={CompanySettings}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/personal-settings"
                component={PersonalSettings}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/personal-settings/change-password"
                component={ChangePassword}
                layout={PrivateLayout}
                location={location}
                isPrivate
              />
              <Route
                exact
                path="/onboarding"
                component={() => (
                  <Redirect
                    to={{
                      pathname: "/onboarding/personal",
                      state: { from: location },
                    }}
                  />
                )}
              />
              <RouteWrapper
                exact
                path="/onboarding/:step"
                component={Onboarding}
                layout={PrivateLayout}
                location={location}
                isSidebarHidden={true}
                isPrivate
              />
              <RouteWrapper
                exact
                path="/login"
                component={Login}
                layout={PublicLayout}
              />
              <RouteWrapper
                exact
                path="/forgot-password"
                component={ForgotPassword}
                layout={PublicLayout}
              />
              <RouteWrapper
                exact
                path="/"
                component={Home}
                layout={PublicLayout}
              />
              <RouteWrapper
                path="*"
                component={ErrorPage}
                layout={PublicLayout}
              />
            </Switch>
          );
        }}
      />
      {/* <RouteWrapper path="*" component={ErrorPage} layout={PublicLayout} /> */}
      {/* </Switch> */}
    </Router>
  );
}

export default AppRoutes;
