import React, { useEffect, useState } from "react";
import { H1, H2, H4 } from "components/Content/ContentTypography";
import styled from "styled-components";
import fetchData from "util/fetchData/getChartOrTableData";
import ComparativeTable from "./ComparativeTable";
import SimpleTable from "./SimpleTable";
import DriverTable from "./DriverTable";

const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainerTable = styled.div`
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  /*width: 672px;*/
  /*max-width: 100%;*/
  max-width: 672px;
  width: 100%;
`;

const TableTitle = styled.div`
  margin: 0 0 16px 0;
  padding: 0;
  text-align: center;

  h4 {
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
  }
`;

function Table(props) {
  //get Table info from the main JSON
  const { text, config } = props;
  //set loading to true until fetch of the data has been completed
  const [loading, setLoading] = useState(true);
  // data is initially empty
  const [data, setData] = useState();

  useEffect(() => {
    //fetch the data
    async function fetchTableData() {
      if (Array.isArray(config.path)) {
        // multiple paths => multiple CSVs to be imported
        // example is J curve

        Promise.all(
          config.path.map(async (element) => {
            return await fetchData(element);
          })
        ).then(
          // now we have array of resolved data
          (result) => {
            setData(result);
            console.log(result);
            setLoading(false);
          }
        );
      } else {
        // single path for other normal Tables

        let result = await fetchData(config.path);

        setData(result);
        setLoading(false);
      }
    }
    fetchTableData();
    //TODO: set the loading to false
  }, [config]);

  // if chart is fetching data, tell them to wait
  if (loading) {
    return <div>Loading...</div>;
  }

  if (data.err) {
    return (
      <div>Something Went wrong. Most probably this path doesn't exist.</div>
    );
  }
  // conditionally render the correct Table subtype
  switch (config.subtype) {
    case "COMPARATIVE_TABLE":
      return (
        <ContainerTable>
          <TableTitle>
            <H4>{text}</H4>
          </TableTitle>
          <ComparativeTable data={data} config={config} />
        </ContainerTable>
      );
    case "SIMPLE_TABLE":
      return (
        <ContainerTable>
          <TableTitle>
            <H4>{text}</H4>
          </TableTitle>
          <SimpleTable data={data} config={config} />
        </ContainerTable>
      );
    case "DRIVER_TABLE":
      return (
        <ContainerTable>
          <TableTitle>
            <H4>{text}</H4>
          </TableTitle>
          <DriverTable data={data} config={config} />
        </ContainerTable>
      );
    default:
      return (
        <DivWrapper>
          <H1>{text}</H1>
          <H2>Type does not exist.</H2>
        </DivWrapper>
      );
  }
}

export default Table;
