import styled from 'styled-components';
import { transitionDefaultFast } from 'styles/Styles';

const ItemGroupText = styled.h4`
	display: inline-block;
	margin: 0;
	padding: 0;

	font-size: 14px;
	font-weight: 500;
	line-height: 22px;

	color: ${(props) =>
		props.isActive ? (props.colorActive ? props.colorActive : 'rgba(0, 0, 0, 1)') : 'rgba(0, 0, 0, 1)'};
	opacity: ${(props) => (props.isActive ? 1 : 0.66)};

	&:hover {
		opacity: ${(props) => (props.isActive ? 1 : 0.75)};
	}

	${transitionDefaultFast}
`;

export default ItemGroupText;
