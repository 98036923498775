const dataSegments = [
	'bra',
	'bra_android',
	'bra_ios',
	'can',
	'can_android',
	'can_ios',
	'esp',
	'esp_android',
	'esp_ios',
	'gbr',
	'gbr_android',
	'gbr_ios',
	'ind',
	'ind_android',
	'ind_ios',
	'usa',
	'usa_android',
	'usa_ios',
];

const dataSegmentsCountry = ['bra', 'can', 'esp', 'gbr', 'ind', 'usa'];

const dataSegmentsPlatform = ['android', 'ios'];

const type = {
	// All + StoryEngine
	SECTION: 'SECTION',
	H1: 'H1',
	P: 'P',
	ANCHOR_DEEP: 'ANCHOR_DEEP',
	PREVIEW: 'PREVIEW',
	DIVIDER: 'DIVIDER',
	SUBSECTION: 'SUBSECTION',
	SYNOPSIS: 'SYNOPSIS',
	CHART: 'CHART',
	TABLE: 'TABLE',
	BULLET: 'BULLET',
	NUMBER: 'NUMBER',
	UNORDERED_LIST: 'UNORDERED_LIST',
	ORDERED_LIST: 'ORDERED_LIST',
	FORMULA: 'FORMULA',
	// SummaryEngine
	OVERVIEW: 'OVERVIEW',
	POINT: 'POINT',
	CONCLUSION: 'CONCLUSION',
};

const sentiment = {
	POSITIVE: 'POSITIVE',
	NEUTRAL: 'NEUTRAL',
	NEGATIVE: 'NEGATIVE',
	NONE: 'NONE',
};

const loading = {
	overallStory: [
		{
			type: 'SECTION',
			subtitle: null,
			title: 'Overview',
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
		{
			type: 'SECTION',
			subtitle: 'Section 1',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
		{
			type: 'SECTION',
			subtitle: 'Section 2',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
		{
			type: 'SECTION',
			subtitle: 'Section 3',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
	],
	deepDive: [
		{
			type: 'SECTION',
			subtitle: 'Section 1',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
		{
			type: 'SECTION',
			subtitle: 'Section 2',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
		{
			type: 'SECTION',
			subtitle: 'Section 3',
			title: null,
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
	],
	executiveSummary: [
		{
			type: 'OVERVIEW',
			chart: {
				path: 'growth_module/charts/Overall_quadrant_chart.csv',
				title: '...',
			},
			title: '...',
			config: '{}',
			content: [
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
				{ type: 'DIVIDER', config: {} },
			],
		},
	],
};
const transitionSpring = { type: 'spring', stiffness: 1000, damping: 100 };
const transitionTween = { type: 'tween', duration: 0.1 };

export {
	dataSegments,
	dataSegmentsCountry,
	dataSegmentsPlatform,
	type,
	sentiment,
	loading,
	transitionSpring,
	transitionTween,
};
