//experimental
import React, { useState, useEffect } from "react";
import { theme } from "contexts/Theme";
import { scaleLinear, scaleUtc } from "@visx/scale";
import { LinePath, Bar, Line } from "@visx/shape";
import * as _ from "lodash";
import { Group } from "@visx/group";
import { withParentSize } from "@visx/responsive";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { getMaxValueInMatrix } from "components/Chart/functions";
import {
  useTooltip,
  useTooltipInPortal,
  TooltipWithBounds
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";

import { getShortenedMonth } from "components/Chart/functions";

import ChartAnimate from "./ChartAnimate";
import ChartAnimatePlay from "./ChartAnimatePlay";
import ChartAnimateProgress from "./ChartAnimateProgress";
import ChartAnimateLabel from "./ChartAnimateLabel";

var randomColor = require("randomcolor");

const ChartToRender = ({ data, config, parentWidth, parentHeight }) => {
  // console.log(data);

  const bisectArray = bisector(array => array[0]).left;

  //usetooltip hook
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true
  });

  const [noData, setNoData] = useState(false);

  const axisLeftOffset = 16;

  // tooltip handler
  const handleTooltip = (event, datum) => {
    // happens everytime pointer position changes inside portal

    let coords = localPoint(event);
    coords.x = coords.x - chartML - axisLeftOffset;
    coords.y = coords.y - chartMT;
    let tooltipTopArray = [];
    let tooltipDataArray = [];

    data.map(chartLine => {
      // now chartLine is a single j curve
      let cohortdataArr = Object.values(
        _.omit(chartLine[cohortIndex], config.xAxisCol ?? "cohort_ds")
      );

      let yValue = yScale(cohortdataArr[xScale.invert(coords.x).toFixed(0)]);
      tooltipTopArray.push(yValue);
      tooltipDataArray.push(cohortdataArr[xScale.invert(coords.x).toFixed(0)]);
    });

    // update if there is no data
    setNoData(tooltipDataArray.every(el => el === ""));

    //console.log(tooltipDataArray);
    showTooltip({
      tooltipLeft: coords.x,
      //tooltipTop: coords.y - chartMT,
      tooltipTop: tooltipTopArray,
      tooltipData: tooltipDataArray
    });
  };

  const [cohortIndex, setCohortIndex] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(true);
  let lineData = data[0];
  // let index = bisector((array) => array[config.xAxisCol]).left;

  config.dataCols = Object.keys(lineData[0]).filter(
    word => word !== "cohort_ds" || word !== config.xAxisCol
  );

  //convert date column to date object for the first array
  if (config.xAxisDate) {
    data[0].forEach(
      element => (element[config.xAxisCol] = new Date(element[config.xAxisCol]))
    );
  }
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;

  // getter functions for scale

  const getX = d => d["key"];
  const getY = d => d["value"];

  // scales
  //y scale
  const yScale = scaleLinear({
    domain: [config?.yStartValue ?? 0, config?.yEndValue ?? 1],
    range: [chartHeight - chartMB - chartMT, 0],
    nice: true
  });

  // x scale

  let xScale;

  if (config.xAxisDate) {
    xScale = scaleUtc({
      domain: [
        data[0][0][config.xAxisCol],
        data[0][data[0].length - 1][config.xAxisCol]
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset]
    });
  } else {
    // x axis is linear
    xScale = scaleLinear({
      domain: [
        config?.xStartValue ?? 0,
        config?.xEndValue ?? config.dataCols.length
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset],
      nice: config?.xScaleNice === "true" ? true : false
    });
  }

  useEffect(() => {
    let cohortTimer;

    if (playAnimation) {
      cohortTimer = setInterval(() => {
        // <<<<<<< charts
        //         setCohortIndex(prev =>
        //           prev + 1 >= Object.keys(data[0][0]).length ? 0 : prev + 1
        // =======
        setCohortIndex(
          prev => (prev + 1 >= Object.keys(data[0]).length ? 0 : prev + 1)
          // >>>>>>> main
        );
      }, 50);
    } else {
      clearInterval(cohortTimer);
    }

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(cohortTimer);
  }, [playAnimation]);

  return (
    // <ChartContent>
    <div>
      <svg ref={containerRef} width={chartWidth} height={chartHeight}>
        <Group top={chartMT} left={chartML}>
          <rect
            fill="teal"
            opacity={0}
            width={chartWidth - chartML - chartMR}
            height={chartHeight - chartMT - chartMB}
          />
          <AxisLeft
            labelClassName={config?.yAxisLabelClassName ?? " "}
            scale={yScale}
            label={config.yAxisLabel}
            labelProps={{
              fill: "#978695",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              textAnchor: "middle",
              backgroundFill: "#ff0000",
              dx: -18,
              dy: (chartMT + chartMB) / 2 - 3
            }}
            left={chartWidth - chartML - chartMR + axisLeftOffset}
            tickLength={chartWidth - chartML - chartMR}
            numTicks={5}
            // tickFormat={d => getStringFromLargeNumber(d, config.yTickFormat)}
            stroke={theme.chart.colors.stroke}
            tickStroke={theme.chart.colors.tickStroke}
            tickLabelProps={(value, index) => ({
              fill: "#a391a0",
              fontSize: "12px",
              fontWeight: 500,
              textAnchor: "end",
              dx: -8,
              dy: 4
            })}
            hideAxisLine={true}
          />
          <AxisBottom
            labelClassName={config?.yAxisLabelClassName ?? " "}
            label={config.xAxisLabel}
            labelProps={{
              fill: "#978695",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              textAnchor: "middle",
              y: chartHeight + chartMB - 6,
              dx: -chartML - axisLeftOffset / 2
              // backgroundFill: "#ff0000"
            }}
            top={0}
            tickLength={chartHeight - chartMT - chartMB}
            tickFormat={d => getShortenedMonth(d, config.xAxisDate)}
            scale={xScale}
            stroke={theme.chart.colors.stroke}
            tickStroke={theme.chart.colors.tickStroke}
            tickLabelProps={(value, index) => ({
              fill: "#a391a0",
              fontSize: "12px",
              fontWeight: 500,
              textAnchor: "middle",
              dy: 10
            })}
            left={axisLeftOffset}
            hideAxisLine={true}
          />
          <Group left={axisLeftOffset}>
            {data.map((LineArr, LineIndex) => (
              <LinePath
                defined={d => (getY(d) ? true : false)}
                data={_.map(
                  _.omit(LineArr[cohortIndex], "cohort_ds"),
                  (value, key) => ({
                    key,
                    value
                  })
                )}
                x={d => xScale(getX(d)) ?? 0}
                y={d => yScale(getY(d)) ?? 0}
                strokeWidth={1.3}
                stroke={theme.chart.colors.lines[LineIndex]}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            ))}
            {!noData && tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: -2 }}
                  to={{
                    x: tooltipLeft,
                    y: chartHeight - chartMT - chartMB + 4
                  }}
                  strokeWidth={1}
                  stroke="#80787b"
                  pointerEvents="none"
                />
              </g>
            )}
            {!noData &&
              tooltipOpen &&
              tooltipTop?.map((element, index) => (
                <>
                  <TooltipInPortal
                    // set this to random so it correctly updates with parent bounds
                    key={Math.random()}
                    top={element}
                    left={tooltipLeft + 50}
                  >
                    {config.yAxisLabel} <strong>{tooltipData[index]}</strong>
                  </TooltipInPortal>

                  <circle
                    cx={tooltipLeft}
                    cy={element}
                    r={4}
                    stroke="blue"
                    strokeWidth={2}
                    pointerEvents="none"
                  />
                </>
              ))}

            {/* this is a bar that gives the onclick event location for tooltip */}

            <Bar
              width={chartWidth - chartML - chartMR}
              height={chartHeight - chartMT - chartMB}
              fill="transparent"
              // rx={14}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          </Group>
        </Group>
      </svg>
      <ChartAnimate>
        <ChartAnimatePlay
          playAnimation={playAnimation}
          onClick={() => setPlayAnimation(prev => !prev)}
        />
        <ChartAnimateProgress
          type="range"
          id="volume"
          name="volume"
          value={cohortIndex}
          min="0"
          // <<<<<<< charts
          // max={
          //   Object.values(_.omit(data[0][0], config.xAxisCol ?? "cohort_ds"))
          //     .length
          // }
          //           onChange={e => setCohortIndex(e.target.value)}
          // =======
          max={data[0].length - 1}
          onChange={e => setCohortIndex(e.target.value)}
          // >>>>>>> main
        />
        <ChartAnimateLabel for="volume">Cohort {cohortIndex}</ChartAnimateLabel>
      </ChartAnimate>
    </div>
    // </ChartContent>
  );
};

let JCurveChart = withParentSize(ChartToRender);

export default JCurveChart;
