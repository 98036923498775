import React, { useState } from "react";
import { Link } from "react-router-dom";

import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerFull from "layouts/PrivateLayout/GridContainerFull";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import { P } from "components/Content/ContentTypography";
import QuickNavigate from "components/QuickNavigate";
import Segments from "components/Segments";
import SegmentsPreview from "components/Segments/SegmentsPreview";
import { useValidationTool } from "contexts/ValidationTool";

import * as _ from "lodash";
function RetentionCriticalSegments() {
  const [topSegmentsData, setTopSegmentData] = useState([
    {
      segment: "android",
      description: `Android's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
    {
      segment: "apple",
      description: `iOS's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
    {
      segment: "USA_android",
      description: `Android's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
    {
      segment: "USA_apple",
      description: `iOS's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
    {
      segment: "GBR_android",
      description: `Android's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
    {
      segment: "GBR_apple",
      description: `iOS's retention is similar to the retention for Overall. It also is the company's largest segment.`,
    },
  ]);

  const { validationToolConfig } = useValidationTool();

  return (
    <GridContainer>
      <GridContainerFull>
        <ContentHeader subtitle="Retention" title="Critical Segments" />
        <ContentCard>
          <QuickNavigate />
        </ContentCard>
        <ContentCard title="PicsArt's critical segments">
          <P maxWidth>
            Based on{" "}
            <Link to="/company-settings">
              what you told us about your companies
            </Link>{" "}
            and our <Link to="top-drivers">analysis of top drivers</Link>, the
            following are important segments to tracks.
          </P>
          <Segments>
            {topSegmentsData.map((segment, index) => (
              <SegmentsPreview
                {...segment}
                title={
                  _.find(validationToolConfig.filteredArr, function (o) {
                    return o.value === segment.segment;
                  })?.label ?? segment.segment
                }
                base="retention"
              />
            ))}
          </Segments>
        </ContentCard>
      </GridContainerFull>
    </GridContainer>
  );
}

export default RetentionCriticalSegments;
