import { useState, useEffect } from 'react';
import getStoryJson from 'util/fetchData/getStoryJson';
import GridContainer from 'layouts/PrivateLayout/GridContainer';
import GridContainerContent from 'layouts/PrivateLayout/GridContainerContent';
import GridContainerAside from 'layouts/PrivateLayout/GridContainerAside';
import ContentHeader from 'components/Content/ContentHeader';
import { useValidationTool } from 'contexts/ValidationTool';
import { loading } from 'Constants';
import StoryEngine from 'components/Engines/StoryEngine';
import TableOfContents from 'components/TableOfContents';

function RetentionDeepDive() {
  const [contentData, setContentData] = useState(loading.deepDive);
  const [hasDataInitialized, setHasDataInitialized] = useState(false);
  // const [loading, setLoading] = useState(true);
  let { validationToolConfig } = useValidationTool();

  useEffect(() => {
    console.log(validationToolConfig.currentSegment);
    //fetch the data
    async function fetchData() {
      if (validationToolConfig.currentSegment == 'none') {
        // draw default
        let result = await getStoryJson(`retention_module/statements/dd_view/Overall.json`);
        // console.log('RESULT', result);
        setContentData(result.content);
        setHasDataInitialized(true);
      } else {
        // draw default
        let result = await getStoryJson(
          `retention_module/statements/dd_view/${validationToolConfig.currentSegment}.json`
        );
        console.log(result);
        setContentData(result.content);
        setHasDataInitialized(true);
      }
    }

    fetchData();
    // setLoading(false);
  }, [validationToolConfig]);

  // if (loading) {
  //   return <h1>Loading...</h1>;
  // }
  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader key="contentHeader" subtitle="Retention" title="Deep Dive" />
        <StoryEngine data={contentData} subtitle="Retention" title="Deep Dive" />
      </GridContainerContent>
      <GridContainerAside>{hasDataInitialized && <TableOfContents data={contentData} />}</GridContainerAside>
    </GridContainer>
  );
}

export default RetentionDeepDive;
