import React, { useContext, useState, useEffect } from "react";

const ValidationToolContext = React.createContext();

// useValidationTool hook returns the value object
export function useValidationTool() {
  return useContext(ValidationToolContext);
}

// the ContextProvider wrapper that is wrapped around our App
export function ValidationToolProvider({ children }) {
  const [validationToolConfig, setValidationToolConfig] = useState({
    currentSegment: "none",
  });

  const value = {
    validationToolConfig,
    setValidationToolConfig,
  };

  return (
    <ValidationToolContext.Provider value={value}>
      {children}
    </ValidationToolContext.Provider>
  );
}
