import styled from "styled-components";
import { transitionDefault, stylesNoSelect } from "styles/Styles";

const ExpandCollapse = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin: ${props =>
		props.isCollapsed ? "4px auto 0 auto" : "12px auto 0 auto"};
	padding: 0 24px;

	background: ${props =>
		props.isCollapsed ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0.75)"};
	color: ${props =>
		props.isCollapsed ? "rgba(0, 0, 0, 0.75)" : "rgba(255, 255, 255, 1)"};
	border: 0;
	border-radius: 8px;
	font-weight: 500;
	cursor: pointer;
	text-align: center;
	/*cursor: pointer;*/

	outline: none;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	/*line-height: 40px;*/

	&:hover {
		.expand-collapse__icon {
			transform: ${props =>
				props.isCollapsed
					? "translateY(1px) rotate(0deg)"
					: "translateY(-2px) rotate(180deg)"};
		}
	}

	${transitionDefault}
	${stylesNoSelect}
`;

export default ExpandCollapse;
