import styled from "styled-components";

const ChartContent = styled.div`
	position: relative;
	/*max-width: 100%;
	width: 100%;*/
	/*overflow: hidden;*/
	margin: 0;
	margin: ${props => props.hasPlayer ? '0 0 48px 0' : '0'};
	padding: 0;
	line-height: 1;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom-left-radius: 6px;
`;

export default ChartContent;
