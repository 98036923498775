import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { stylesCardDefault, transitionDefaultFast } from "styles/Styles";
import { transitionSpring, transitionTween } from "Constants";
import { useOuterClick } from "util/customHooks";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
const UserOptions = styled.div`
  position: absolute;
  top: 28px;
  left: -16px;
  width: auto;
  margin: 10px auto 0 32px;
  padding: 13px 0;
  /*padding: 0;*/

  a {
    text-decoration: none;
  }
`;

const UserOptionsItem = styled.div`
  position: relative;
  margin: 0 0 8px 0;
  padding: 0 16px;
  opacity: 1;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }
`;

const UserOptionsItemText = styled.h3`
  /*margin: 0;*/
  margin: -8px -12px;
  padding: 8px 12px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  opacity: ${(props) => (props.isActive ? 1 : 0.75)};
  border-radius: 8px;

  ${transitionDefaultFast}

  &::after {
    content: " ";
    position: absolute;
    top: 7px;
    left: 0;
    width: 1px;
    height: 24px;
    background: #000000;
    border-radius: 2px;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    ${transitionDefaultFast}
  }

  &:hover {
    opacity: ${(props) => (props.isActive ? 1 : 0.75)};
    background: ${(props) =>
      props.isActive ? "transparent" : "rgba(0, 0, 0, 0.05)"};
  }
`;

function SidebarCurrentUserOptions({ hasExpanded, setHasExpanded, location }) {
  const [isRendering, setIsRendering] = useState(hasExpanded);
  const animateCard = useAnimation();
  const animateCardItemFirst = useAnimation();
  const animateCardItemSecond = useAnimation();

  const refBoundingBox = useOuterClick((e) => {
    if (isRendering && location.pathname != "/personal-settings") {
      setHasExpanded(false);
    }
  });

  let history = useHistory();

  let { logout } = useAuth();

  let handleLogOut = (e) => {
    e.preventDefault();
    // first this is to push to different page
    history.push("/");
    // now logout the user
    logout();
  };

  useEffect(() => {
    // alert('hasExpanded:' + hasExpanded);
    // THIS HITS A CYCLE LATE I THINK. RECOMMENDED TO USE !hasExpanded by TRIAL & ERROR not LOGIC by @jayhxmo
    if (!hasExpanded) {
      animateCard.start({
        scaleY: 0,
        transition: { type: "tween", duration: 0.3 },
      });

      animateCardItemFirst.start({
        opacity: 0,
        transition: transitionTween,
      });

      animateCardItemSecond.start({
        opacity: 0,
        transition: transitionTween,
      });

      setTimeout(() => {
        setIsRendering(false);
      }, 300);
    } else {
      setIsRendering(true);

      animateCard.start({
        scaleY: 1,
        transitionSpring,
      });

      animateCardItemFirst.start({
        opacity: 1,
        transitionTween,
      });

      animateCardItemSecond.start({
        opacity: 1,
        transition: { ...transitionTween, delay: 0.1 },
      });
    }
  }, [hasExpanded]);

  return (
    <UserOptions ref={refBoundingBox}>
      <motion.div
        initial={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#ffffff",
          boxShadow:
            "0px 8px 12px -6px rgba(0, 0, 0, 0.07), 0px 1.8px 2.7px -6px rgba(0, 0, 0, 0.042), 0px 0.5px 0.8px -6px rgba(0, 0, 0, 0.028)",
          border: 0,
          borderRadius: 12,
          transformOrigin: "0 0",
          scaleY: 0,
        }}
        animate={animateCard}
      />
      <UserOptionsItem>
        <motion.div initial={{ opacity: 0 }} animate={animateCardItemFirst}>
          {isRendering && (
            <Link to="/personal-settings">
              <UserOptionsItemText
                isActive={location.pathname == "/personal-settings"}
              >
                Personal Settings
              </UserOptionsItemText>
            </Link>
          )}
        </motion.div>
      </UserOptionsItem>
      <UserOptionsItem>
        <motion.div initial={{ opacity: 0 }} animate={animateCardItemSecond}>
          {isRendering && (
            <Link onClick={handleLogOut}>
              <UserOptionsItemText isActive={false}>
                Log Out
              </UserOptionsItemText>
            </Link>
          )}
        </motion.div>
      </UserOptionsItem>
    </UserOptions>
  );
}

export default SidebarCurrentUserOptions;
