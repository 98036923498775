import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { H3 } from "components/Content/ContentTypography";
import { useValidationTool } from "contexts/ValidationTool";
import fetchData from "util/fetchData/getChartOrTableData";
import ReactSelect, { components } from "react-select";
import Flag from "react-world-flags";
import {
  IconEarth,
  IconAndroid,
  IconApple,
  IconRefresh,
} from "components/Icons";
import * as _ from "lodash";
const lookup = require("country-code-lookup");

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: black;
`;

const ModH3 = styled(H3)`
  font-weight: bold;
  font-size: 1rem;
  color: black;
  opacity: 1;
`;
const Description = styled(H3)`
  font-size: 0.9rem;
  color: black;
`;

const SelectButton = styled.button`
  color: white;
  background-color: black;
  outline: none;
  &:hover {
    opacity: 0.75;
  }

  border-style: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  width: 100%;
`;

const FlagFallback = (code) => {
  switch (code.toLowerCase()) {
    case "overall":
      return (
        <IconEarth
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    case "male":
      return <span>👨</span>;
    case "female":
      return <span>👩</span>;
    case "apple":
      return (
        <IconApple
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    case "android":
      return (
        <IconAndroid
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    default:
      return <span>❔</span>;
      break;
  }
};

const singleOption = (props) => (
  <components.Option {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      {props.data.value && (
        <Flag
          height="24"
          width="24"
          style={{ marginRight: "10px" }}
          code={props.data.value.split("_")[0]}
          fallback={FlagFallback(props.data.value)}
        />
      )}
      {props.data.label ?? props.data.value}
    </div>
  </components.Option>
);

const singleValue = (props) => (
  <components.SingleValue {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      {props.data.value && (
        <Flag
          height="24"
          width="24"
          style={{ marginRight: "10px" }}
          code={props.data.value.split("_")[0]}
          fallback={FlagFallback(props.data.value)}
        />
      )}
      {props.data.label ?? props.data.value}
    </div>
  </components.SingleValue>
);
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgb(0,0,0,0.2)",
    background: state.isSelected ? "black" : "white",
    fontColor: state.isSelected ? "white" : "black",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "black";
    return { ...provided, opacity, transition, color };
  },
};

function ValidationTool() {
  const { validationToolConfig, setValidationToolConfig } = useValidationTool();

  const [segments, setSegments] = useState(["Default"]);

  useEffect(() => {
    //fetch the data
    async function fetchChartData() {
      let result = await fetchData("top_level_datasets/analyzability_df.csv");
      //console.log(result);
      const filteredArr = result.reduce(
        (a, o) => (
          o["analyzable"] === "True" &&
            a.push({
              value: o["segment"],
              label: o["full_segment"],
            }),
          a
        ),
        []
      );

      setSegments(filteredArr);
      setValidationToolConfig({
        ...validationToolConfig,
        filteredArr: filteredArr,
      });
    }
    fetchChartData();
    //TODO: set the loading to false
  }, []);

  const handleSelection = (newValue) => {
    if (newValue) {
      setValidationToolConfig((prev) => ({
        ...prev,
        currentSegment: newValue.value,
      }));
      console.log("changed segment to: " + newValue.value);
    }
  };
  //temp fix
  // Consult Rajeev before making changes here

  return <></>;
  return (
    <Wrapper>
      <ModH3>Validation Mode</ModH3>
      <Description>Currently {validationToolConfig.currentSegment}</Description>

      <ReactSelect
        onChange={handleSelection}
        styles={customSelectStyles}
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        components={{
          Option: singleOption,
          SingleValue: singleValue,
        }}
        maxMenuHeight={200}
        name="color"
        options={segments}
      />
    </Wrapper>
  );
}

export default ValidationTool;
