import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const DeepContent = styled.p`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  text-align: left;
  opacity: 0.75;
  color: #000000;
  ${transitionDefault}
`;

export default DeepContent;