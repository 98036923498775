import React from "react";
import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import GridContainerAside from "layouts/PrivateLayout/GridContainerAside";
import Chart from "components/Chart";
import Table from "components/Table";

function Dashboard() {
  return (
    <GridContainer>
      <GridContainerContent>
        Engagement, Stickiness Overall Story content goes here
        {/* <Chart
          config={{
            subtype: "PIVOT_LINE_CHART",
            path: "growth_module/charts/testline.csv",
            xAxisLabel: "Cohort",
            yAxisLabel: "Users in Million",
            xScaleNice: true,
            xAxisCol: "ds",
            xAxisDate: false,
            yAxisFactor: "M",
            pivot: true,
            pivotCol: "year",
            dataCols: ["w1", "w26"],
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
          }}
          text={"Test Chart"}
        /> */}
        {/* <Chart
          config={{
            subtype: "QUADRANT_CHART",
            path: "growth_module/charts/Overall_quadrant_chart.csv",

            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
          }}
          text={"Test Chart"}
        /> */}
        {/* <Chart
          config={{
            subtype: "STACKBAR_CHART",
            path: "growth_module/charts/Overall_growth_breakdown_chart.csv",
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
            xAxisDate: true,
            xAxisCol: "ds",
          }}
          text={"Test Chart"}
        /> */}
        {/* <Chart
          config={{
            subtype: "STACKBAR_CHART",
            path: "growth_module/charts/Overall_waterfall_chart.csv",
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
            xAxisCol: "",
          }}
          text={"Test Chart"}
        /> */}
        {/* <Chart
          config={{
            subtype: "SCATTER_CHART",
            path: "stickiness_module/charts/Overall_scatter_chart_data.csv",
            xAxisCol: "dau_wau",
            yAxisCol: "wau_mau",
            xAxisLabel: "DAU/WAU",
            yAxisLabel: "WAU/MAU",
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
            showDiagonal: true,
            circleSizeCol: "dau_mau",
            dateCol: "ds_y",
            infoCol: "mau",
            infoColLabel: "Monthly Av. Users",
            yEndValue: 1,
            xEndValue: 1,
          }}
          text={"Test Chart"}
        /> */}
        {/* <Chart
          config={{
            subtype: "J_CURVE_CHART",
            path: "retention_module/charts/Overall_weekly_perc_j_curves.csv",
            xAxisLabel: "Cohorts",
            yAxisLabel: "Retention",
            xScaleNice: true,
            yEndValue: 1,
            // yTickFormat: (value, index) => value / 1000000 + " M",
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
          }}
          text={"Test Chart"}
        /> */}
        {/* <Table
          text="Change in daily new users over the last year"
          config={{
            path:
              "growth_module/drivers/new/Overall_segment_1-segment_2_364_new_growth_driver_data.csv",
            subtype: "COMPARATIVE_TABLE",

            showCols: ["segment", "ct_new_t1", "ct_new_t2", "contribution"],
            labels: [
              "Segment",
              "New Users Current",
              "New Users 1Y Ago",
              "Contribution",
            ],
            columnStyling:
              "[{}, {}, {}, {factor: '%',color:'true', sparkline: true}]",
          }}
        /> */}
        {/* <Chart
          config={{
            path:
              "retention_module/charts/Overall_weekly_retention_over_time_line_chart.csv",
            subtype: "LINE_CHART",
            xAxisLabel: "Date",
            yAxisLabel: "Retention",
            xScaleNice: "true",
            xAxisCol: "cohort_ds",
            xAxisDate: "true",
            dataCols: ["w1", "flat_value"],
            labels: ["week 1", "week 2"],
          }}
          text={"Simple line chart"}
        /> */}
        {/* <Table
          config={{
            currentValueCol: "wau_t1",
            oldValueCol: "wau_t2",
            ignoreColumns: ["weight", "abs_contribution"],
            labels: [
              "Segments",
              "WAU current",
              "WAU 1 Year old",
              "contribution",
            ],
            subtype: "COMPARATIVE_TABLE",
            path:
              "growth_module/drivers/wau/Overall_segment_1-segment_2_364_wau_growth_driver_data.csv",
            columnStyling:
              "[{}, {}, {}, { factor: '%', color: true , sparkline: 'true' }, {}]",
          }}
          text={"Test Table Title"}
        /> */}
        {/* <Table
          config={{
            labels: ["Label1", "Label2", "Label3", "Label4"],
            subtype: "SIMPLE_TABLE",
            path: "growth_module/tables/GBR_growth_summary_data.csv",
            columnStyling: "[{}, {}, { factor: '%', color: true }, {}]",
          }}
          text={"Test Simple Table Title"}
        /> */}
        {/* <Chart
          config={{
            subtype: "J_CURVE_CHART",
            path:
              "retention_module/final_data/IND_tri_df_smoothed_df_perc_w.csv",

            xAxisLabel: "Cohorts",
            yAxisLabel: "Retention",
            xScaleNice: true,
            yEndValue: 1,
            // yTickFormat: (value, index) => value / 1000000 + " M",
            chartRatio: 0.8,
            marginLeft: 50,
            marginRight: 10,
            marginTop: 20,
            marginBottom: 50,
          }}
          text={"Test Chart"}
        /> */}
        <Table
          title={"Growth summary"}
          config={{
            showSearchBar: "true",
            labels: ["competitors", "wth", "super"],
            path: "growth_module/tables/competitors_table.csv",
            subtype: "COMPARATIVE_TABLE",
            columnStyling: "[{}, {factor: '%', sparkline: true}, {}]",
          }}
        />
      </GridContainerContent>
      <GridContainerAside>Rest go here</GridContainerAside>
    </GridContainer>
  );
}

export default Dashboard;
