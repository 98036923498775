import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { transitionSpring } from 'Constants';

import ContainerItemGroup from './ContainerItemGroup';
import ItemGroup from './ItemGroup';
import ItemGroupTitle from './ItemGroupTitle';
import ItemGroupContent from './ItemGroupContent';
import ItemGroupText from './ItemGroupText';
import GhostItemGroup from './GhostItemGroup';
import ItemGroupBar from './ItemGroupBar';
import ItemGroupActive from './ItemGroupActive';

import { transitionDefault } from 'styles/Styles';

function SidebarItemGroup({ title, route, activeIndex, itemIndex, colorActive }) {
	let location = useLocation();
	let isActive =
		location && location.pathname && (route == location.pathname || location.pathname.includes(route));
	let isNotStickiness = route && !route.includes('stickiness');

	const [ghostHeight, setGhostHeight] = useState(-1);
	const [hasExpanded, setHasExpanded] = useState(false);
	const [currentRouteIndex, setCurrentRouteIndex] = useState(-1);

	const refGhost = useRef();
	const animateItemGroup = useAnimation();
	const animateItemGroupActive = useAnimation();

	let routeOverallStory = route;
	let routeDeepDive = `${route}/deep-dive`;
	let routeCriticalSegments = `${route}/critical-segments`;
	let routeTopDrivers = `${route}/top-drivers`;

	let routes = isNotStickiness
		? [routeOverallStory, routeDeepDive, routeCriticalSegments, routeTopDrivers]
		: [routeOverallStory, routeCriticalSegments, routeTopDrivers];
	// let currentRouteIndex = routes.indexOf(location.pathname);

	let isRouteOverallStory = location.pathname == route;
	let isRouteDeepDive = location.pathname == routeDeepDive;
	let isRouteCriticalSegments = location.pathname == routeCriticalSegments;
	let isRouteTopDrivers = location.pathname == routeTopDrivers;

	useEffect(() => {
		// Update Active Bar
		let newRouteIndex = routes.indexOf(location.pathname);
		if (newRouteIndex != currentRouteIndex) {
			animateItemGroupActive.start({
				y: newRouteIndex * 30,
				transition: transitionSpring,
			});
			setCurrentRouteIndex(routes.indexOf(location.pathname));
		}

		// console.log('GhostHeight', ghostHeight);
		// Setting ghostHeight
		if (ghostHeight < 0 && refGhost && refGhost.current) {
			setGhostHeight(refGhost.current.getBoundingClientRect().height + 8);
		}

		// Animating expand and collapse
		if (isActive && ghostHeight > 0 && !hasExpanded) {
			animateItemGroup.start({
				maxHeight: ghostHeight,
				opacity: 1,
				transition: transitionSpring,
			});
			setHasExpanded(true);
		} else if (!isActive && hasExpanded) {
			animateItemGroup.start({
				maxHeight: 0,
				opacity: 0,
				transition: transitionSpring,
			});
			setHasExpanded(false);
		}
	});

	return (
		<ContainerItemGroup>
			<Link to={route}>
				<ItemGroupTitle isActive={isActive} colorActive={colorActive}>
					{title}
				</ItemGroupTitle>
			</Link>
			<motion.div
				animate={animateItemGroup}
				initial={{
					maxHeight: 0,
					padding: '0',
					opacity: 0,
					overflow: 'hidden',
					background: 'rgba(255, 255, 0, 0)',
				}}
			>
				<ItemGroup isActive={isActive}>
					<ItemGroupBar />
					<motion.div
						animate={animateItemGroupActive}
						initial={{ position: 'absolute', top: 0, left: 1 }}
					>
						{/* <ItemGroupActive currentRouteIndex={currentRouteIndex} isActive={isActive} /> */}
						<ItemGroupActive isActive={isActive} colorActive={colorActive} />
					</motion.div>
					<ItemGroupContent>
						<Link to={routeOverallStory}>
							<ItemGroupText isActive={isRouteOverallStory} colorActive={colorActive}>Overall Story</ItemGroupText>
						</Link>
					</ItemGroupContent>
					{isNotStickiness && (
						<ItemGroupContent>
							<Link to={routeDeepDive}>
								<ItemGroupText isActive={isRouteDeepDive} colorActive={colorActive}>Deep Dive</ItemGroupText>
							</Link>
						</ItemGroupContent>
					)}
					<ItemGroupContent>
						<Link to={routeCriticalSegments}>
							<ItemGroupText isActive={isRouteCriticalSegments} colorActive={colorActive}>
								Critical Segments
							</ItemGroupText>
						</Link>
					</ItemGroupContent>
					<ItemGroupContent>
						<Link to={routeTopDrivers}>
							<ItemGroupText isActive={isRouteTopDrivers} colorActive={colorActive}>Top Drivers</ItemGroupText>
						</Link>
					</ItemGroupContent>
				</ItemGroup>
			</motion.div>
			<GhostItemGroup ref={refGhost}>
				<ItemGroupContent>
					<Link to={routeOverallStory}>
						<ItemGroupText isActive={isRouteOverallStory}>Overall Story</ItemGroupText>
					</Link>
				</ItemGroupContent>
				{isNotStickiness && (
					<ItemGroupContent>
						<Link to={routeDeepDive}>
							<ItemGroupText isActive={isRouteDeepDive}>Deep Dive</ItemGroupText>
						</Link>
					</ItemGroupContent>
				)}
				<ItemGroupContent>
					<Link to={routeCriticalSegments}>
						<ItemGroupText isActive={isRouteCriticalSegments}>Critical Segments</ItemGroupText>
					</Link>
				</ItemGroupContent>
				<ItemGroupContent>
					<Link to={routeTopDrivers}>
						<ItemGroupText isActive={isRouteTopDrivers}>Top Drivers</ItemGroupText>
					</Link>
				</ItemGroupContent>
			</GhostItemGroup>
		</ContainerItemGroup>
	);
}

export default SidebarItemGroup;
