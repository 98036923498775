//experimental
import React from "react";
import { theme } from "contexts/Theme";
import { scaleLinear, scaleUtc } from "@visx/scale";
import { LinePath, Bar, Line } from "@visx/shape";
import * as _ from "lodash";
import { Group } from "@visx/group";
import { withParentSize } from "@visx/responsive";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { curveBasis } from "@visx/curve";
import {
  getMaxValueInMatrix,
  getStringFromLargeNumber,
} from "components/Chart/functions";
import {
  useTooltip,
  useTooltipInPortal,
  TooltipWithBounds,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";
var randomColor = require("randomcolor");

const ChartToRender = ({ data, config, parentWidth, parentHeight }) => {
  const bisectArray = bisector((array) => array[config.xAxisCol]).left;

  //usetooltip hook
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  let pivotObject = {};
  data.map((element) => {
    //check if key exists
    if (element[config.pivotCol] in pivotObject) {
      // key exists
      pivotObject[element[config.pivotCol]].push(element);
    } else {
      pivotObject[element[config.pivotCol]] = [];
      pivotObject[element[config.pivotCol]].push(element);
    }
  });
  //console.log(pivotObject);

  const axisLeftOffset = 16;

  // tooltip handler
  const handleTooltip = (event, datum) => {
    let coords = localPoint(event);
    coords.x = coords.x - chartML - axisLeftOffset;
    let tooltipTopArray = [];
    let tooltipDataArray = [];

    // there is pivot
    Object.keys(pivotObject).map((key) => {
      // element is 2016 or 2017 , basically key of the object
      config.dataCols.map((element) => {
        let index = bisectArray(pivotObject[key], xScale.invert(coords.x));
        console.log(index);
        tooltipTopArray.push({
          yValue: index < 52 ? yScale(pivotObject[key][index][element]) : 0,
          pivotData: key,
        });
        tooltipDataArray.push({
          data: index < 52 ? pivotObject[key][index][element] : null,
          pivotData: key,
        });
      });
    });

    showTooltip({
      tooltipLeft: coords.x,
      //tooltipTop: coords.y - chartMT,
      tooltipTop: tooltipTopArray,
      tooltipData: tooltipDataArray,
    });
  };

  //convert date column to date object
  if (
    config.xAxisDate &&
    config.xAxisDate !== "false" &&
    config.xAxisDate !== "False"
  ) {
    console.log("converted");
    data.forEach(
      (element) =>
        (element[config.xAxisCol] = new Date(element[config.xAxisCol]))
    );
  }
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;

  // getter functions for scale

  const getX = (d) => d[config.xAxisCol];
  const getY = (d, element) => d[element];

  // scales
  //y scale
  const yScale = scaleLinear({
    domain: [
      config?.yStartValue ?? 0,
      config?.yEndValue ?? getMaxValueInMatrix(data, config.dataCols),
    ],
    range: [chartHeight - chartMB - chartMT, 0],
    nice: true,
  });

  // x scale

  let xScale;

  if (
    config.xAxisDate &&
    config.xAxisDate !== "false" &&
    config.xAxisDate !== "False"
  ) {
    xScale = scaleUtc({
      domain: [
        data[0][config.xAxisCol],
        data[data.length - 1][config.xAxisCol],
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset],
    });
  } else {
    // x axis is linear
    xScale = scaleLinear({
      domain: [
        config?.yStartValue ?? 0,
        config?.yEndValue ?? getMaxValueInMatrix(data, [config.xAxisCol]),
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset],
      nice: config?.xScaleNice ?? false,
    });
  }

  return (
    <svg ref={containerRef} width={chartWidth} height={chartHeight}>
      <Group top={chartMT} left={chartML}>
        <rect
          fill="teal"
          opacity={0}
          width={chartWidth - chartML - chartMR}
          height={chartHeight - chartMT - chartMB}
        />
        <AxisLeft
          labelClassName={config?.yAxisLabelClassName ?? " "}
          scale={yScale}
          label={config.yAxisLabel}
          labelProps={{
            fill: "#978695",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "14px",
            textAnchor: "middle",
            backgroundFill: "#ff0000",
            dx: -18,
            dy: (chartMT + chartMB) / 2 - 3,
          }}
          left={chartWidth - chartML - chartMR + axisLeftOffset}
          tickLength={chartWidth - chartML - chartMR}
          numTicks={5}
          tickFormat={(d) => getStringFromLargeNumber(d, config.yTickFormat)}
          stroke={theme.chart.colors.stroke}
          tickStroke={theme.chart.colors.tickStroke}
          tickLabelProps={(value, index) => ({
            fill: "#a391a0",
            fontSize: "12px",
            fontWeight: 500,
            textAnchor: "end",
            dx: -8,
            dy: 4,
          })}
          hideAxisLine={true}
        />
        <AxisBottom
          labelClassName={config?.yAxisLabelClassName ?? " "}
          label={config.xAxisLabel}
          labelProps={{
            fill: "#978695",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "14px",
            textAnchor: "middle",
            y: chartHeight + chartMB - 6,
            dx: -chartML - axisLeftOffset / 2,
            backgroundFill: "#ff0000",
          }}
          top={0}
          tickLength={chartHeight - chartMT - chartMB}
          scale={xScale}
          stroke={theme.chart.colors.stroke}
          tickStroke={theme.chart.colors.tickStroke}
          tickLabelProps={(value, index) => ({
            fill: "#a391a0",
            fontSize: "12px",
            fontWeight: 500,
            textAnchor: "middle",
            dy: 10,
          })}
          left={axisLeftOffset}
          hideAxisLine={true}
        />
        <Group left={axisLeftOffset}>
          {tooltipOpen &&
            tooltipTop?.map((element, index) => {
              if (tooltipData[index]["data"]) {
                // data exists
                return (
                  <>
                    <TooltipInPortal
                      // set this to random so it correctly updates with parent bounds
                      key={Math.random()}
                      top={element["yValue"]}
                      left={tooltipLeft + 50}
                    >
                      {tooltipData[index]["pivotData"]}:{" "}
                      <strong>
                        {getStringFromLargeNumber(
                          tooltipData[index]["data"],
                          config.yAxisFactor
                        )}
                      </strong>
                    </TooltipInPortal>
                    <circle
                      cx={tooltipLeft}
                      cy={element["yValue"] + 1}
                      r={4}
                      fill="black"
                      fillOpacity={0.1}
                      stroke="black"
                      strokeOpacity={0.1}
                      strokeWidth={2}
                      pointerEvents="none"
                    />
                    <circle
                      cx={tooltipLeft}
                      cy={element["yValue"]}
                      r={4}
                      stroke="white"
                      strokeWidth={2}
                      pointerEvents="none"
                    />
                  </>
                );
              }
            })}

          {Object.values(pivotObject).map((dataArr, index) => {
            // each one is an data array with same segment say 2017
            return config.dataCols.map((colName) => (
              <LinePath
                defined={(d) =>
                  d[colName] !== 0 && d[colName] !== "" && d[colName] !== null
                }
                key={colName + "-" + dataArr}
                data={dataArr} //filter by pivot column
                x={(d) => xScale(getX(d))}
                y={(d) => yScale(getY(d, colName))}
                // stroke={randomColor({
                //   luminosity: "bright",
                //   seed: colName
                // })}
                stroke={theme.chart.colors.lines[index]}
                strokeWidth={1.3}
                curve={curveBasis}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            ));
          })}
          {tooltipData && (
            <g>
              {/* <Line */}
              {/*   from={{ x: tooltipLeft, y: 0 }} */}
              {/*   to={{ x: tooltipLeft, y: chartHeight - chartMT - chartMB }} */}
              {/*   strokeWidth={2} */}
              {/*   stroke="black" */}
              {/*   pointerEvents="none" */}
              {/*   strokeDasharray="5,2" */}
              {/* /> */}
              <Line
                from={{ x: tooltipLeft, y: -2 }}
                to={{ x: tooltipLeft, y: chartHeight - chartMT - chartMB + 4 }}
                strokeWidth={1}
                stroke="#80787b"
                pointerEvents="none"
                // strokeDasharray="2,6"
              />
            </g>
          )}
          {/* this is a bar that gives the onclick event location for tooltip */}
          <Bar
            width={chartWidth - chartML - chartMR}
            height={chartHeight - chartMT - chartMB}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>
      </Group>
    </svg>
  );
};

let PivotLineChart = withParentSize(ChartToRender);

export default PivotLineChart;
