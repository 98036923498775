import { useState } from 'react';
import { Link } from 'react-router-dom';
import ChangePasswordButton from './ChangePasswordButton';

function ChangePassword({ isHovering }) {
	const [isHoveringState, setIsHoveringState] = useState(false);

	const onMouseEnter = () => {
		setIsHoveringState(true);
	};

	const onMouseLeave = () => {
		setIsHoveringState(false);
	};

	return (
		<ChangePasswordButton
			isHovering={isHovering || isHoveringState}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			action="none"
		>
			<Link to="/personal-settings/change-password">Change Password</Link>
		</ChangePasswordButton>
	);
}

export default ChangePassword;
