import styled from "styled-components";

// padding: 16px 0 8px ${props => props.theme.chart.marginLeft}px;
const ChartTitle = styled.h4`
	width: 100%;
	margin: 0 auto;
	padding: ${props =>
		props.fullContainer ? "16px 0 8px 0" : "16px 28px 8px 0"};
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
	/*color: rgba(0, 0, 0, 0.66);*/
	color: #79656b;
	/*border: 1px solid red;*/
	text-align: center;
`;

export default ChartTitle;
