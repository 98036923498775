import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

import ContainerContentNumber from './ContainerContentNumber';
import ContentNumberIndex from './ContentNumberIndex';
// import ContentNumberText from './ContentNumberText';
import ContentNumberContent from './ContentNumberContent';
import ContentNumberContentText from './ContentNumberContentText';

function ContentNumber({ text, content, number, children }) {
	// console.log('ContentNumber', number, text, content);
	return (
		<ContainerContentNumber className={content ? 'content-number__content' : 'content-number__text'}>
			<ContentNumberIndex>{number}.</ContentNumberIndex>
			<ContentNumberContent>
				{text && (
					<ContentNumberContentText className="content-number__content__text">
						{text}
					</ContentNumberContentText>
				)}
				{content}
			</ContentNumberContent>
		</ContainerContentNumber>
	);

	// if (text) {
	// 	return (
	// 		<ContainerContentNumber>
	// 			<ContentNumberIndex>{number}.</ContentNumberIndex>
	// 			<ContentNumberText>{children}</ContentNumberText>
	// 		</ContainerContentNumber>
	// 	);
	// } else if (content) {
	// 	return (
	// 		<ContainerContentNumber>
	// 			<ContentNumberIndex>{number}.</ContentNumberIndex>
	// 			<ContentNumberContent className="content-number__content">{children}</ContentNumberContent>
	// 		</ContainerContentNumber>
	// 	);
	// } else {
	// 	return <></>;
	// }
}

export default ContentNumber;
