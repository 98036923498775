import { useEffect, useState } from "react";
import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import { useValidationTool } from "contexts/ValidationTool";

import axios from "util/axios/server-instance";
import { auth } from "services/firebase";
//import { type, sentiment, loading } from "Constants";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import { P } from "components/Content/ContentTypography";
import FormOmnipresent from "components/Form/FormOmnipresent";
import InputText from "components/Input/InputText";
import InputPassword from "components/Input/InputPassword";
import ButtonPrimary from "components/Button/ButtonPrimary";

function PersonalSettings() {
	const [name, setName] = useState("");
	const [role, setRole] = useState("");

	useEffect(() => {
		auth.currentUser.getIdToken(true).then(IdToken => {
			console.log(IdToken);
			return axios
				.get("user", {
					headers: { Authorization: `Bearer ${IdToken}` }
				})
				.then(response => {
					console.log("res", response);
					// alert(response.data.name);
					// alert(response.data.vertical);
					// alert(response.data.size);
					setName(response.data.name);
					setRole(response.data.role);
				})
				.catch(error => {
					console.log("error", error);
				});
		});
	}, []);

	return (
		<GridContainer>
			<GridContainerContent>
				<ContentHeader subtitle={null} title="Personal Settings" />
				<ContentCard formContent>
					<FormOmnipresent onSubmit={"user"}>
						<InputText
							name="name"
							label="Your name"
							placeholder="What's your name?"
							// defaultValue="Jay Mo"
							defaultValue={name}
							required
						/>
						<InputText
							name="role"
							label="Your job title"
							placeholder="What's your role?"
							// defaultValue="Product Designer"
							defaultValue={role}
							required
						/>
						<InputPassword
							name="password"
							label="Your password"
							placeholder="Choose a secret password"
							defaultValue={"         "}
							disabled={true}
							allowChangePassword={true}
							required
						/>
						<ButtonPrimary action="submit">Save Changes</ButtonPrimary>
					</FormOmnipresent>
				</ContentCard>
			</GridContainerContent>
		</GridContainer>
	);
}

export default PersonalSettings;
