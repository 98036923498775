import styled from 'styled-components';

const ChartAnimate = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	width: 100%;
	margin: 24px 0 0 0;
`;

export default ChartAnimate;