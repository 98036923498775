import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const PeekPasswordIcon = styled.div`
	position: absolute;
	/*top: 28px;*/
	top: 46px;
	right: 20px;
	opacity: 0.5;
	cursor: pointer;

	${transitionDefault}

	&:hover {
		opacity: 0.66;
	}
`;

export default PeekPasswordIcon;
