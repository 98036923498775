import { useState, useEffect } from "react";

import ContainerSidebarLogo from "./ContainerSidebarLogo";
import SidebarLogoContent from "./SidebarLogoContent";
import PicsArt from "./PicsArt";

function SidebarLogo({ company }) {
  return (
    <ContainerSidebarLogo>
      <SidebarLogoContent>
        {company === "picsart" && <PicsArt />}
      </SidebarLogoContent>
    </ContainerSidebarLogo>
  );
}

export default SidebarLogo;
