import { useState } from "react";
import styled from "styled-components";
import { H4 } from "components/Content/ContentTypography";
import { Spacer16 } from "components/Spacer";
import ContentDivider from "../ContentDivider";

function ContentPreview({ title, children }) {
  console.log(title);
  console.log(children);
  return (
    <>
      <ContentDivider />
      <H4>{title}</H4>
      <Spacer16 />
      {children}
    </>
  );
}

export default ContentPreview;
