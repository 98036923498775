import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const ItemGroupTitle = styled.h3`
	position: relative;
	display: inline-block;
	/*margin: 2px 0;*/
	/*margin: 1px 0;*/
	margin: -7px -12px; // -8 + 1px offset
	padding: 8px 12px;

	font-size: 16px;
	font-weight: 500;
	line-height: 22px;

	background: rgba(0, 0, 0, 0);
	color: ${(props) =>
		props.isActive ? (props.colorActive ? props.colorActive : 'rgba(0, 0, 0, 1)') : 'rgba(0, 0, 0, 1)'};
	opacity: ${(props) => (props.isActive ? 1 : 0.66)};

	border-radius: 8px;

	&:hover {
		opacity: ${(props) => (props.isActive ? 1 : 0.75)};
		background: ${(props) => (props.isActive ? 'transparent' : 'rgba(0, 0, 0, 0.05)')};
	}

	${transitionDefault}
`;

export default ItemGroupTitle;
