import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LineChart from "components/Chart/LineChart";
import PivotLineChart from "components/Chart/PivotLineChart";
import { H1, H2, H3, H4, H5 } from "components/Content/ContentTypography";
import fetchData from "util/fetchData/getChartOrTableData";
import QuadrantChart from "components/Chart/Quadrant";
import StackBarChart from "components/Chart/StackBarChart";
import ScatterChart from "components/Chart/ScatterChart";
import JCurveChart from "components/Chart/JCurveChart";
import ContainerChart from "components/Chart/ContainerChart";
import ChartContent from "components/Chart/ChartContent";
import ChartTitle from "components/Chart/ChartTitle";

const ChartPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 256px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  text-align: center;
`;

function Chart(props) {
  //get chart info from the main JSON
  const { text, config } = props;
  //set loading to true until fetch of the data has been completed
  const [loading, setLoading] = useState(true);
  // data is initially empty
  const [data, setData] = useState();

  useEffect(() => {
    //fetch the data
    async function fetchChartData() {
      if (Array.isArray(config.path)) {
        // multiple paths => multiple CSVs to be imported
        // example is J curve

        Promise.all(
          config.path.map(async element => {
            return await fetchData(element);
          })
        ).then(
          // now we have array of resolved data
          result => {
            console.log(result);
            setLoading(false);
          }
        );
      } else {
        // single path for other normal charts

        let result = await fetchData(config.path);
        //console.log(result);

        setData(result);
        setLoading(false);
      }
    }
    fetchChartData();
    //TODO: set the loading to false
  }, [config]);

  // if chart is fetching data, tell them to wait
  if (loading) {
    return <div>Loading...</div>;
  }

  if (data.err) {
    return (
      <ChartPlaceholder>
        LINE CHART PLACEHOLDER, TO PREVENT BUG TEMPORARILY. Most probably this
        path doesn't exist.
      </ChartPlaceholder>
    );
  }

  console.log("---------------------");
  console.log("config for", text, config);
  console.log("data for", text, data);
  console.log("---------------------");

  // conditionally render the correct chart subtype

  switch (config.subtype) {
    case "LINE_CHART":
      //console.log("chart config", config);
      return (
        <ContainerChart background>
          <ChartContent>
            <ChartTitle>{text}</ChartTitle>
            <LineChart config={config} data={data} />
          </ChartContent>
        </ContainerChart>
      );
      break;
    case "PIVOT_LINE_CHART":
      return (
        <ContainerChart background>
          <ChartContent>
            <ChartTitle>{text}</ChartTitle>
            <PivotLineChart config={config} data={data} />
          </ChartContent>
        </ContainerChart>
      );
      break;
    case "QUADRANT_CHART":
      return (
        <ContainerChart backgroundSentiment fullContainer>
          <ChartTitle fullContainer>{text}</ChartTitle>
          <QuadrantChart config={config} data={data} />
        </ContainerChart>
      );
      break;

    case "STACKBAR_CHART":
      return (
        <ContainerChart background>
          <ChartContent>
            <ChartTitle>{text}</ChartTitle>
            <StackBarChart config={config} data={data} />
          </ChartContent>
        </ContainerChart>
      );
      break;
    case "SCATTER_CHART":
      return (
        <ContainerChart backgroundSentiment>
          <ChartContent hasPlayer>
            <ChartTitle>{text}</ChartTitle>
            <ScatterChart config={config} data={data} />
          </ChartContent>
        </ContainerChart>
      );
      break;
    case "J_CURVE_CHART":
      return (
        <ContainerChart background>
          <ChartContent hasPlayer>
            <ChartTitle>{text}</ChartTitle>
            <JCurveChart
              config={{ ...config, path: [config.path] }}
              data={[data]}
            />
          </ChartContent>
        </ContainerChart>
      );
      break;
    default:
      return (
        <ContainerChart>
          <ChartTitle>{text}</ChartTitle>
          <H2>Type does not exist.</H2>
        </ContainerChart>
      );
      break;
  }
}

export default Chart;
