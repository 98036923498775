import styled from 'styled-components';

const ContainerItemSingle = styled.div`
	position: relative;
	height: 24px;

	display: flex;
	align-items: center;
	margin: 0 0 20px 0;
	padding: 0;
	/*background: rgba(255, 0, 0, 0.3);*/
`;

export default ContainerItemSingle;