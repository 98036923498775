import styled from "styled-components";

const ChartAnimateLabel = styled.label`
	width: 88px;
	text-align: left;
	color: rgba(0, 0, 0, 0.66);
	font-size: 16px;
	font-weight: 500;
`;

export default ChartAnimateLabel;
