import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Switcher = styled.div`
  display: flex;
  margin: 32px 0 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

const SwitcherLink = styled.div`
  margin: 0 24px 0 0;
  padding: 16px 0;
  cursor: pointer;

  a {
    padding: 16px 0;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    font-weight: ${(props) => (props.isActive ? 500 : 400)};
    border-bottom: ${(props) =>
      props.isActive ? '1px solid rgba(0, 0, 0, 0.33)' : '1px solid rgba(0, 0, 0, 0)'};
  }
`;

function BaseSwitcher({ base, segment }) {
  return (
    <Switcher>
      <SwitcherLink isActive={base == 'growth'}>
        <Link to={`segment?seg=${segment}&view=growth`}>Growth</Link>
      </SwitcherLink>
      <SwitcherLink isActive={base == 'stickiness'}>
        <Link to={`segment?seg=${segment}&view=stickiness`}>Stickiness</Link>
      </SwitcherLink>
      <SwitcherLink isActive={base == 'retention'}>
        <Link to={`segment?seg=${segment}&view=retention`}>Retention</Link>
      </SwitcherLink>
    </Switcher>
  );
}

export default BaseSwitcher;
