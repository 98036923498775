import styled from 'styled-components';
import { stylesCardDefault } from 'styles/Styles';

const ContentOverviewBase = styled.section`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	margin: 0;
	padding: 40px;
	${stylesCardDefault}

	.chart div, .graph, .table {
		margin: 0;
		padding: 0;
		/*height: 100% !important;*/

		height: 100% !important;
	}
`;

export default ContentOverviewBase;
