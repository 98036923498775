import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";

import { stylesNoSelect } from "styles/Styles";
import { transitionSpring } from "Constants";
import { IconChevronDown } from "components/Icons";
import Person from "components/Person";
import SidebarCurrentUserOptions from "./SidebarCurrentUserOptions";

const ContainerCurrentUser = styled.div`
	position: relative;
	display: block;
`;

const CurrentUser = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	${stylesNoSelect}
`;

const CurrentUserIcon = styled.div`
	margin: 0 0 0 10px;
`;

function SidebarCurrentUser({ location, name }) {
	const [hasExpanded, setHasExpanded] = useState(false);
	const animateIcon = useAnimation();

	useEffect(() => {
		if (location.pathname == "/personal-settings") {
			setTimeout(() => setHasExpanded(true), 300);
		}
	}, []);

	useEffect(() => {
		if (hasExpanded) {
			animateIcon.start({ rotate: 180, y: -1, transition: transitionSpring });
		} else {
			animateIcon.start({ rotate: 0, y: 0, transition: transitionSpring });
		}
	}, [hasExpanded]);

	return (
		<ContainerCurrentUser>
			<CurrentUser onClick={() => setHasExpanded(!hasExpanded)}>
				<Person name={name} />
				<CurrentUserIcon>
					<motion.div animate={animateIcon}>
						<IconChevronDown />
					</motion.div>
				</CurrentUserIcon>
			</CurrentUser>
			<SidebarCurrentUserOptions
				hasExpanded={hasExpanded}
				setHasExpanded={setHasExpanded}
				location={location}
			/>
		</ContainerCurrentUser>
	);
}

export default SidebarCurrentUser;
