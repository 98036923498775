import { useState } from "react";
import styled from "styled-components";
import { Spacer8, Spacer48 } from "components/Spacer";
import { H1 } from "../ContentTypography";

import ContentOverviewBase from "./ContentOverviewBase";
import ContentChart from "components/Content/ContentChart";
import OverviewContent from "./OverviewContent";
import OverviewChart from "./OverviewChart";
import Chart from "components/Chart";
import Table from "components/Table";

function ContentOverview({ subtitle, title, children, chart }) {
  //   console.log("Overview");
  //   console.log(chart);
  return (
    <>
      <Spacer48 />
      <ContentOverviewBase>
        <OverviewContent>
          <H1>{title}</H1>
          {children}
        </OverviewContent>
        {chart && (
          <OverviewChart>
            {/* <ContentChart /> */}
            <Chart config={chart} text={chart.title} />
          </OverviewChart>
        )}
      </ContentOverviewBase>
    </>
  );
}

export default ContentOverview;
