import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { H1, H2, P } from "components/Content/ContentTypography";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  align-items: center;
`;

const MainText = styled.h1`
  font-size: 4rem;
  max-width: 1200px;
`;

const ButtonLink = styled.a``;
function Home() {
  return (
    <Wrapper>
      <p style={{ textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            transform: "scale(0.4)"
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="172"
            height="172"
            fill="none"
            viewBox="0 0 172 172"
          >
            <g fill="#231F20" clipPath="url(#clip0)">
              <path d="M85.8 47.4C38.5 47.4 0 75.3 0 109.5s38.5 62 85.8 62 85.8-27.8 85.8-62c.1-34.2-38.4-62.1-85.8-62.1zm0 113c-41.2 0-74.7-22.8-74.7-50.9 0-28.1 33.5-50.9 74.7-50.9s74.7 22.8 74.7 50.9c0 28.1-33.5 50.9-74.7 50.9z" />
              <path d="M98.4 48.1c-2 3.6-4.1 7.2-6.2 10.7-14.7 23.8-33 45-54.2 62.9l-20.4 8.4c1 1.6 2.2 3.2 3.5 4.7 1.2 1.5 2.6 2.9 4 4.2l91.625-37.779a4 4 0 002.475-3.698V6.379c0-1.14-1.649-1.407-2.024-.33A270.926 270.926 0 0198.4 48.1zm-49.3 69c.1-.1.1 0 0 0l.1-.1c.1 0-.1.1-.1.1z" />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H171.7V171.5H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <b>
          <span style={{ lineHeight: 1.5 }}>
            We're on a mission to help builders make meaningful use of data to
            fulfill their mission.
          </span>
        </b>
        <br />
        <br />
        Coming soon.
      </p>
      {/* <Link to="/summary">Go to Summary</Link> */}
    </Wrapper>
  );
}

export default Home;
