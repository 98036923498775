//experimental
import React, { useEffect, useState } from "react";
import { theme } from "contexts/Theme";
import { BarStack } from "@visx/shape";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { withParentSize } from "@visx/responsive";
import {
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  scaleUtc,
  scaleTime
} from "@visx/scale";
import {
  getMaxValueInMatrix,
  getMaxStackedValue,
  getMinStackedValue
} from "components/Chart/functions";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { getStringFromLargeNumber } from "components/Chart/functions";
import { ticks } from "d3-array";
var randomColor = require("randomcolor");
const ChartToRender = ({
  data: InputData,
  config,
  parentWidth,
  parentHeight
}) => {
  let data;
  if (InputData.length > 200) {
    data = InputData.slice(-200, InputData.length);
  } else {
    data = InputData;
  }

  const axisLeftOffset = 16;
  // console.log(config, 'config');

  const [colorArray, setColorArray] = useState([]);
  let checkIfDate = value => {
    var date = Date.parse(value);
    if (isNaN(date)) return value;
    else return new Date(value).toDateString();
  };
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;
  let InnerWidth = chartWidth - chartML - chartMR;
  let InnerHeight = chartHeight - chartMT - chartMB;

  //defining keys
  let keys = [];
  if (config.dataCols) {
    // this array is mentioned in the config
    keys = [...config.dataCols];
  } else {
    //console.log(data);
    // if not, add keys other than the first column
    Object.keys(data[0]).map((key, index) => {
      if (index > 0) {
        // add it to the keys
        keys.push(key);
      }
    });

    //console.log(keys);
  }
  useEffect(() => {
    // waits for rendering but not other calculations
    setColorArray(getColors(keys));
  }, []);
  // a function that gives color to the bars
  function getColors(keyArr) {
    let colorArr = [];
    keyArr.map(element => {
      // check if config has declared this key to be transparent
      if (
        config?.transparentCols?.includes(element) ||
        element === "Empty" ||
        element === "empty"
      ) {
        // this needs to be transparent
        colorArr.push("transparent");
      } else if (element === "Increase" || element === "increase") {
        // this needs to be transparent
        colorArr.push("#04B35D");
        // colorArr.push("#74F2B3");
      } else if (element === "Decrease" || element === "decrease") {
        // this needs to be transparent
        colorArr.push("#ff4e38");
        // colorArr.push("#F7AA9E");
      } else if (element === "Value" || element === "value") {
        // this needs to be transparent
        colorArr.push("#ddd");
        // colorArr.push("#c456ff");
        // colorArr.push("#AFD3FF");
      } else {
        // put random
        colorArr.push(
          randomColor({
            luminosity: "bright"
          })
        );
      }
    });

    return colorArr;
  }

  //accessor
  let getName;

  //scales
  let xScale;
  let tickScale;

  if (config.xAxisDate) {
    xScale = scaleBand({
      domain: data.map(value => value[config.xAxisCol]),
      paddingInner: 0.2
    });
    xScale.rangeRound([0, InnerWidth - axisLeftOffset - 8]);

    getName = d => d[config.xAxisCol];

    tickScale = scaleTime({
      domain: [
        Date.parse(data[0][config.xAxisCol]),
        Date.parse(data[data.length - 1][config.xAxisCol])
      ],
      range: [0, InnerWidth - axisLeftOffset - 8],
      nice: true
    });
  } else {
    xScale = scaleBand({
      domain: data.map(value => value[config.xAxisCol]),
      paddingInner: 0.2
    });
    xScale.rangeRound([0, InnerWidth - axisLeftOffset - 8]);

    getName = d => d[config.xAxisCol];

    tickScale = xScale;
  }

  let yScale = scaleLinear({
    domain: [getMinStackedValue(data, keys), getMaxStackedValue(data, keys)],
    range: [InnerHeight, 0],
    nice: true
  });
  let colorScale = scaleOrdinal({
    domain: keys,
    range: colorArray
  });

  // tooltip systems
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "white"
  };
  let tooltipTimeout;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true
  });

  return (
    <div style={{ position: "relative" }}>
      <svg ref={containerRef} width={chartWidth} height={chartHeight}>
        <Group top={chartMT} left={chartML}>
          <rect
            fill="teal"
            opacity={0}
            width={InnerWidth}
            height={InnerHeight}
          />
          {/* <AxisLeft */}
          {/*   tickFormat={value => getStringFromLargeNumber(value)} */}
          {/*   scale={yScale} */}
          {/*   stroke={"purple"} */}
          {/*   tickStroke={"purple"} */}
          {/*   tickLabelProps={() => ({ */}
          {/*     fill: "purple", */}
          {/*     fontSize: 11, */}
          {/*     textAnchor: "end" */}
          {/*   })} */}
          {/* /> */}
          <AxisLeft
            labelClassName={config?.yAxisLabelClassName ?? " "}
            scale={yScale}
            label={config.yAxisLabel}
            labelProps={{
              fill: "#978695",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "14px",
              textAnchor: "middle",
              backgroundFill: "#ff0000",
              dx: -18,
              dy: (chartMT + chartMB) / 2 - 3
            }}
            left={chartWidth - chartML - chartMR + axisLeftOffset}
            tickLength={chartWidth - chartML - chartMR}
            tickFormat={d => getStringFromLargeNumber(d)}
            stroke={theme.chart.colors.stroke}
            tickStroke={theme.chart.colors.tickStroke}
            tickLabelProps={(value, index) => ({
              fill: "#a391a0",
              fontSize: "12px",
              fontWeight: 500,
              textAnchor: "end",
              dx: -8,
              dy: 4
            })}
            hideAxisLine={true}
          />
          {config.xAxisDate ? (
            // <AxisBottom
            //   top={InnerHeight}
            //   scale={tickScale}
            //   stroke={"purple"}
            //   tickStroke={"purple"}
            //   tickLabelProps={() => ({
            //     fill: "purple",
            //     fontSize: 11,
            //     textAnchor: "middle"
            //   })}
            // />
            <AxisBottom
              labelClassName={config?.yAxisLabelClassName ?? " "}
              label={config.xAxisLabel}
              labelProps={{
                fill: "#978695",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "14px",
                textAnchor: "middle",
                y: chartHeight + chartMB - 6,
                dx: -chartML - axisLeftOffset / 2
              }}
              top={0}
              tickLength={chartHeight - chartMT - chartMB}
              tickFormat={checkIfDate}
              scale={tickScale}
              stroke={theme.chart.colors.stroke}
              tickStroke={theme.chart.colors.tickStroke}
              tickLabelProps={(value, index) => ({
                fill: "#a391a0",
                fontSize: "12px",
                fontWeight: 500,
                textAnchor: "middle",
                dy: 10
              })}
              left={axisLeftOffset}
              hideAxisLine={true}
            />
          ) : (
            <AxisBottom
              labelClassName={config?.yAxisLabelClassName ?? " "}
              label={config.xAxisLabel}
              labelProps={{
                fill: "#978695",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "14px",
                textAnchor: "middle",
                y: chartHeight + chartMB - 6,
                dx: -chartML - axisLeftOffset / 2
              }}
              top={0}
              tickLength={chartHeight - chartMT - chartMB}
              tickFormat={checkIfDate}
              scale={xScale}
              stroke={theme.chart.colors.stroke}
              tickStroke={theme.chart.colors.tickStroke}
              tickLabelProps={(value, index) => ({
                fill: "#a391a0",
                fontSize: "12px",
                fontWeight: 500,
                textAnchor: "middle",
                dy: 10
              })}
              left={axisLeftOffset}
              hideAxisLine={true}
            />
          )}
          <Group left={axisLeftOffset}>
            {/* <Grid */}
            {/*   xScale={xScale} */}
            {/*   yScale={yScale} */}
            {/*   width={InnerWidth} */}
            {/*   height={InnerHeight} */}
            {/*   stroke="black" */}
            {/*   strokeOpacity={0.1} */}
            {/* /> */}
            <BarStack
              data={data}
              keys={keys}
              x={getName}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {barStacks =>
                barStacks.map(barStack =>
                  barStack.bars.map(bar => (
                    <rect
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      height={Math.abs(bar.height)}
                      width={bar.width}
                      fill={bar.color}
                      rx={6}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      onMouseMove={event => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        // TooltipInPortal expects coordinates to be relative to containerRef
                        // localPoint returns coordinates relative to the nearest SVG, which
                        // is what containerRef is set to in this example.
                        const eventSvgCoords = localPoint(event);
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: eventSvgCoords?.y,
                          tooltipLeft: left
                        });
                      }}
                    />
                  ))
                )
              }
            </BarStack>
          </Group>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && tooltipData.key != "Empty" && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]} Users</div>
          <div>
            <small>{checkIfDate(getName(tooltipData.bar.data))}</small>
          </div>
        </TooltipInPortal>
      )}
    </div>
  );
};

let StackBarChart = withParentSize(ChartToRender);

export default StackBarChart;
