import styled from "styled-components";
import { transitionDefault } from "styles/Styles";

const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0 0 0;
`;

const Button = styled.button`
  position: relative;
  width: ${props => (props.fixedWidth ? "128px" : "auto")};
  height: 48px;
  margin: 0;
  padding: 0 24px;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  /*background: rgba(42, 0, 12, 0.04);*/
  /*color: #ffffff;*/
  color: rgba(0, 0, 0, 0.66);
  background: #ffeded;
  /*background: url('/button-primary.jpg') no-repeat center center;*/
  /*background: linear-gradient(180deg, #FF7557 0%, #F1649A 100%);*/
  /*background: url('/button-primary-blue.jpg') no-repeat center center;*/
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.33),
      rgba(255, 255, 255, 0.33)
    ),
    linear-gradient(180deg, #57ffc2 0%, #63f26d 100%);
  background-size: cover;

  border: none;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important;

  cursor: pointer;
  ${transitionDefault};
`;

const SuccessMessage = styled.p`
  margin: 0 0 0 32px;
  color: ${props => props.theme.colors.colorGreen};
  opacity: ${props => (props.shouldRender ? 1 : 0)};
  ${transitionDefault}
`;

function ButtonPrimary({
  children,
  action,
  onClick,
  isFormElement,
  hasSubmitted,
  messageSuccess
}) {
  return (
    <ContainerButton>
      <Button action={action} onClick={onClick}>
        {children}
      </Button>
      <SuccessMessage shouldRender={isFormElement && hasSubmitted}>
        {messageSuccess}
      </SuccessMessage>
    </ContainerButton>
  );
}

export default ButtonPrimary;
