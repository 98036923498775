import styled from 'styled-components';

const TableHeaderItem = styled.h4`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : '#000000')};
  opacity: ${(props) => (props.opacity ? props.opacity : 0.5)};
`;

export default TableHeaderItem;