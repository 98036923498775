import { useState, useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import Table from "./Table";
import TableHeader from "./TableHeader";
import TableHeaderItem from "./TableHeaderItem";
import TableContent from "./TableContent";
import TableContentRow from "./TableContentRow";
import TableContentRowItem from "./TableContentRowItem";
import TableExpandCollapse from "../TableExpandCollapse";
import { getStringFromLargeNumber } from "components/Chart/functions";
import { transitionSpring } from "Constants";
import SparkLine from "./SparkLine";
import { IconLeftArrow, IconDown } from "components/Icons";
import * as _ from "lodash";

function ComparativeTable({ data: tempData, config: tempConfig }) {
  // initial data is as recieved
  const [data, setData] = useState(tempData);
  const [config, setConfig] = useState({
    ...tempConfig,
    showCols: tempConfig.showCols ?? Object.keys(tempData[0]),
  });
  const [sortConfig, setSortConfig] = useState({ sortBy: null, asc: false });
  const [searchBar, setSearchBar] = useState("");

  useEffect(() => {
    // we want to check if we will need to parse the config.columnStyling

    if (config.columnStyling && typeof config.columnStyling === "string") {
      // we need to parse it
      let newJson = config.columnStyling.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
      newJson = newJson.replace(/'/g, '"');

      config.columnStyling = JSON.parse(newJson);
    }
  }, []);

  useEffect(() => {
    // run on change in sort config
    // if filterBy is null, keep the original sorting
    switch (sortConfig["sortBy"]) {
      case null:
        // if null reset sorting to CSV, this happens the first time
        setData(tempData);
        break;
      case "contribution":
        // if contribution
        setData(
          _.orderBy(
            data,
            (o) => Math.abs(o.contribution),
            sortConfig["asc"] ? "asc" : "desc"
          )
        );
        break;

      default:
        setData(
          _.orderBy(
            data,
            sortConfig["sortBy"],
            sortConfig["asc"] ? "asc" : "desc"
          )
        );
        break;
    }

    console.log("changed sorting order");
  }, [sortConfig]);

  useEffect(() => {
    setData(
      _.filter(tempData, function (o) {
        return (
          // this is for the searchbar
          searchBar.length > 0
            ? JSON.stringify(o).toLowerCase().includes(searchBar.toLowerCase())
            : true
        );
      })
    );
  }, [searchBar]);

  const theme = useContext(ThemeContext);
  const flexMap = [1, 1, 1.2, 1, 1, 1, 1, 1, 1, 1];
  const [rowCount, setRowCount] = useState(6);
  const animateTableRows = useAnimation();

  const valueManager = (value, index) => {
    return (
      <>
        <TableContentRowItem
          justifyContent={
            config.showCols[index] === config.currentValueCol
              ? "flex-end"
              : undefined
          }
          fontWeight={
            config.showCols[index] === config.currentValueCol ? 600 : undefined
          }
          color={
            config.columnStyling[index]?.color
              ? value >= 0
                ? "green"
                : "red"
              : undefined
          }
          flex={flexMap[index]}
        >
          <div
            style={{
              width: config.columnStyling[index]?.sparkline ? "100%" : "auto",
            }}
          >
            {getStringFromLargeNumber(
              value,
              config.columnStyling[index]?.factor ?? null
            )}

            {config.columnStyling[index]?.sparkline && (
              <SparkLine data={value} />
            )}
          </div>
        </TableContentRowItem>
        {config.showCols[index] === config.currentValueCol && (
          <TableContentRowItem flex={"min-content"}>
            <IconLeftArrow
              style={{ marginRight: "10px", marginLeft: "10px" }}
              width={25}
              height={25}
              color="#000"
              opacity={0.66}
            />
          </TableContentRowItem>
        )}
      </>
    );
  };

  const handleRowExpand = () => {
    setRowCount(data.length);
    animateTableRows.start({
      maxHeight: 60 * data.length - 4,
      transition: transitionSpring,
    });
  };

  const handleRowCollapse = () => {
    setRowCount(6);
    animateTableRows.start({
      maxHeight: 60 * 6 - 4,
      transition: transitionSpring,
    });
  };

  return (
    <Table>
      <div>
        {config.showSearchBar && (
          <>
            <Input
              type="text"
              placeholder="Filter Rows Manually"
              value={searchBar}
              onChange={(e) => setSearchBar(e.target.value)}
            />
            <span
              style={{ marginLeft: "-30px", opacity: 0.4, cursor: "pointer" }}
              onClick={() => setSearchBar("")}
            >
              x
            </span>
          </>
        )}
      </div>

      <TableHeader>
        {config.showCols.map((element, index) => (
          <TableHeaderItem
            onClick={() =>
              setSortConfig((prev) => {
                if (element === prev.sortBy) {
                  // the element is the same
                  return { sortBy: element, asc: !prev["asc"] };
                } else {
                  // new element clicked
                  return { sortBy: element, asc: false };
                }
              })
            }
            flex={flexMap[index]}
          >
            {config.labels ? config.labels[index] : element}{" "}
            {sortConfig.asc !== null && (
              <IconDown
                width={16}
                height={16}
                color={"#000000"}
                style={{
                  marginLeft: "6px",
                  transform: sortConfig.asc ? "" : "rotate(-180deg)",
                }}
                opacity={element === sortConfig.sortBy ? 0.8 : 0.3}
              />
            )}
          </TableHeaderItem>
        ))}
      </TableHeader>
      <TableContent>
        <motion.div
          animate={animateTableRows}
          initial={{ maxHeight: 60 * 6 - 4, overflow: "hidden" }}
        >
          {data
            .map((element, index) => (
              <div key={"top_level_row_" + index}>
                <TableContentRow
                  as={motion.div}
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ type: "just", duration: 0.2 * index }}
                >
                  {config.showCols.map((el, index) => {
                    // now each element needs to be shown according to the order
                    return valueManager(element[el], index);
                  })}
                </TableContentRow>
              </div>
            ))
            .filter((ele) => typeof ele !== "undefined")
            .slice(0, rowCount)}
        </motion.div>
      </TableContent>
      {data.length > 6 && (
        <TableExpandCollapse
          isCollapsed={rowCount <= 6}
          handleRowExpand={handleRowExpand}
          handleRowCollapse={handleRowCollapse}
        />
      )}
    </Table>
  );
}

export default ComparativeTable;

// remaining constant stuff

const Input = styled.input`
  font-size: 20px;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  color: #262626;
  padding: 7px 33px;
  border-radius: 3px;
  color: #999;
  cursor: text;
  font-size: 14px;
  font-weight: 300;
  background: #fafafa;
  margin-left: 10px;
  margin-bottom: 10px;
  text-align: left;
  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
`;
