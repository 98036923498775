import styled from 'styled-components';

const ItemGroupContent = styled.div`
	margin: 0 0 4px 0;
	padding: 2px 0;

	&:last-child {
		margin: 0;
	}
`;

export default ItemGroupContent;
