import styled from 'styled-components';

const ContainerUnorderedList = styled.div`
	margin: 0 0 24px 0;

	&:last-child {
		margin: 0;
	}

	p:first-of-type {
		/*margin: 0;*/
		${(props) => props.text != null && 'margin: 0 0 -4px 0;'} /* for closer association */
	}
`;

export default ContainerUnorderedList;
