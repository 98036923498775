import styled from 'styled-components';
// import ContentBulletText from './ContentBulletText';
// import ContentBulletContentWrapper from './ContentBulletContentWrapper';
import ContentBulletContent from './ContentBulletContent';
import ContentBulletContentText from './ContentBulletContentText';
import ContentBulletContentContent from './ContentBulletContentContent';

function ContentBullet({ text, content, children }) {
	return (
		<ContentBulletContent className={content ? 'content-bullet__content' : ''}>
			<ContentBulletContentContent>
				{text && (
					<ContentBulletContentText className="content-bullet__content__text">
						{text}
					</ContentBulletContentText>
				)}
				{content}
			</ContentBulletContentContent>
		</ContentBulletContent>
	);
	// if (text) {
	// 	return <ContentBulletText>{children}</ContentBulletText>;
	// } else if (content) {
	// 	return (
	// 		<ContentBulletContent className="content-bullet__content">
	// 			<ContentBulletContentWrapper>{children}</ContentBulletContentWrapper>
	// 		</ContentBulletContent>
	// 	);
	// } else {
	// 	return <></>;
	// }
}

export default ContentBullet;
