import styled from 'styled-components';
import ContainerUnorderedList from './ContainerUnorderedList';
import UnorderedListStartingText from './UnorderedListStartingText';
import UnorderedListContent from './UnorderedListContent';
import { P } from 'components/Content/ContentTypography';
import ContentBullet from 'components/Content/ContentBullet';

function ContentUnorderedList({ startingText, children }) {
	return (
		<ContainerUnorderedList startingText={startingText}>
			{startingText && <UnorderedListStartingText>{startingText}</UnorderedListStartingText>}
			<UnorderedListContent>{children}</UnorderedListContent>
		</ContainerUnorderedList>
	);
}

// {children &&
// children.map((grandchild, index) => {
// 	console.log('contentUL', grandchild, index);
// 	if (grandchild && grandchild.props && grandchild.props.children != null) {
// 		return (
// 			<ContentBullet startingText={startingText}>
// 				{grandchild.props.children}
// 			</ContentBullet>
// 		);
// 	}
// })}
export default ContentUnorderedList;
