import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const SidebarItemActive = styled.div`
	width: 1px;
	height: 26px;
	margin: -2px 0 0 -16px;
	padding: 0;
	background: ${(props) => (props.colorActive ? props.colorActive : '#000000')};
	border-radius: 2px;
	overflow: hidden;

	opacity: ${(props) => (props.isGroup ? 0 : 1)};

	${transitionDefault}
`;

export default SidebarItemActive;
