import React from "react";
import { Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useAuth } from "contexts/AuthContext";
import LoadingScreen from "components/Loading";
const RouteWrapper = ({
  component: Component,
  layout: Layout,
  isPrivate = false,
  isSidebarHidden,
  location,
  ...rest
}) => {
  const { loading, currentUser, logout } = useAuth();

  if (isPrivate) {
    // console.log('Route is private!');

    // TODO: check auth status (usestate) for an auth function (Firebase listener)
    // if auth:true => render component else use Redirect to redirect to login

    // if loading for the first time on mounting, wait for it
    if (loading) {
      // show a loading screen
      return <LoadingScreen />;
    }
    // loading completed, auth user found
    else if (!loading && currentUser) {
      return (
        <Layout isSidebarHidden={isSidebarHidden}>
          {/* <h1>{currentUser.email}</h1> */}
          {/* <button onClick={() => logout()}>Logout</button> */}
          <TransitionGroup>
            <CSSTransition
              key={location ? location.key : ""}
              timeout={300}
              classNames="transition--fade"
            >
              <Route {...rest} render={(props) => <Component {...props} />} />
            </CSSTransition>
          </TransitionGroup>
        </Layout>
      );
    }
    // loading completed, auth user not found. Redirect now
    else if (!loading && !currentUser) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    }
  } else {
    // not a private route

    return (
      <Layout isSidebarHidden={isSidebarHidden}>
        <Route {...rest} render={(props) => <Component {...props} />} />
      </Layout>
    );
  }
};

export default RouteWrapper;
