import styled from 'styled-components';

	// top: ${(props) => (props.top ? props.top : 0)}px;
const TOCCard = styled.div`
	position: relative;
	top: 0;
	margin: 0;
	padding: 0;
	cursor: pointer;

	a {
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: #000000;
	}
	/*background: red;
	&:nth-child(even) {
		background: blue;
	}*/
`;

export default TOCCard;
