import styled from 'styled-components';

const SynopsisCard = styled.div`
	margin: 0 0 48px 0;
	padding: 16px 24px;
	${(props) => props.sentiment == 'POSITIVE' && 'border: 2px solid #0CD319;'};
	${(props) => props.sentiment == 'NEUTRAL' && 'border: 2px solid #cccccc;'};
	${(props) => props.sentiment == 'NEGATIVE' && 'border: 2px solid #FF2A68;'};
	${(props) => props.sentiment == 'POSITIVE' && 'background: rgba(12, 211, 25, 0.04);'};
	${(props) => props.sentiment == 'NEUTRAL' && 'background: rgba(204, 204, 204, 0.04);'};
	${(props) => props.sentiment == 'NEGATIVE' && 'background: rgba(255, 42, 104, 0.04);'};
	border-radius: 8px;
`;

export default SynopsisCard;
