import { useState } from 'react';
import styled from 'styled-components';
import { H1, H2, H5, P } from 'components/Content/ContentTypography';
import {
	Spacer8,
	Spacer12,
	Spacer16,
	Spacer20,
	Spacer24,
	Spacer32,
	Spacer40,
	Spacer48,
} from 'components/Spacer';

import ContainerPoint from './ContainerPoint';
import PointIcon from './PointIcon';
import PointText from './PointText';

import { sentiment } from 'Constants';

function ContentPoint({ subtitle, title, config, description, children }) {
	return (
		<ContainerPoint>
			<PointIcon>
				{config.sentiment == sentiment.NEGATIVE && (
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<mask
							id="mask0"
							mask-type="alpha"
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="20"
							height="20"
						>
							<rect width="20" height="20" fill="#C4C4C4" />
						</mask>
						<g mask="url(#mask0)">
							<path
								d="M6.42245 2.98867C7.89655 0.0404749 12.1038 0.0404754 13.5779 2.98867L18.7724 13.3777C20.1022 16.0373 18.1682 19.1666 15.1947 19.1666H4.80564C1.83211 19.1666 -0.101879 16.0373 1.22792 13.3777L6.42245 2.98867Z"
								fill="#FF2A68"
							/>
						</g>
					</svg>
				)}
				{config.sentiment == sentiment.NEUTRAL && (
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect width="18" height="18" rx="4" fill="#FF9500" />
					</svg>
				)}
				{config.sentiment == sentiment.POSITIVE && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="18"
						height="18"
						fill="none"
						viewBox="0 0 18 18"
					>
						<rect width="18" height="18" fill="#0CD319" rx="12" />
					</svg>
				)}
			</PointIcon>
			<PointText>{children}</PointText>
		</ContainerPoint>
	);
}

export default ContentPoint;
