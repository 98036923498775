import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

const ContentBulletContent = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	/*flex-direction: column;*/
	/*margin: 8px 0 0 0;*/
	margin: 12px 0 0 0;
	padding: 0;
	/*padding: 0 0 0 16px;*/

	/*font-family: Inter;*/
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;

	color: ${(props) => (props.color ? props.color : '#000000')};
	opacity: 1;

	${transitionOpacity}

	&::before {
		content: ' ';
		display: inline-block;
		flex: 0 6px;
		min-width: 6px;
		width: 6px;
		height: 6px;
		margin: 13px 16px 0 16px;
		background: #000000;
		border-radius: 50%;
		opacity: 0.66;
	}

	&.content-bullet__content {
		/*// Last child no longer works here like Overall Story*/

		.content-bullet__content__text {
			/*border: 1px solid blue;*/
			/*border: 1px solid red;*/

			/*THIS IS THE FIRST TEXT
			IF THE COMPONENT HOLDS CONTENT_ARRAY
			STYLE IF NEEDED*/
		}

		.chart,
		.graph,
		.table {
			margin: 32px 0;

			&:last-child {
				margin: 32px 0;
			}
		}

		&:last-child {
			.chart,
			.graph,
			.table {
				&:last-child {
					margin: 32px 0 0 0;
					/*border: 1px solid blue;*/
				}
			}
		}

		/*// For nested graphs, which become small*/
		.content-bullet__content {
			.chart,
			.graph,
			.table {
				margin: 16px 0;

				&:last-child {
					margin: 16px 0 0 0;
				}
			}
		}
	}

	/*p {
		margin: 0 0 16px 0;
	}*/
`;

export default ContentBulletContent;
