import styled from 'styled-components';

const Segments = styled.div`
	display: inline-grid;
	grid-gap: 32px;
	/*grid-auto-flow: column;*/
	/*grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));*/
	grid-template-columns: repeat(2, 1fr);
	margin: 40px 0 0 0;
`;

export default Segments;
