import { useAuth } from 'contexts/AuthContext';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import LayoutAuth from 'layouts/PublicLayout/LayoutAuth';
import LayoutAuthHeader from 'layouts/PublicLayout/LayoutAuthHeader';
import LayoutAuthContent from 'layouts/PublicLayout/LayoutAuthContent';

import FormOmnipresent from 'components/Form/FormOmnipresent';
import InputEmail from 'components/Input/InputEmail';
import InputPassword from 'components/Input/InputPassword';
import ButtonPrimary from 'components/Button/ButtonPrimary';

import { H0, H5, P1, P2 } from 'components/Content/ContentTypography';
import { Spacer16, Spacer48 } from 'components/Spacer';
import ContentDivider from 'components/Content/ContentDivider';
import Background from 'components/Background';
import ButtonGoogle from 'components/Button/ButtonGoogle';

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();
  let { currentUser, loginWithEmail, signInWithGooglePopup } = useAuth();

  let { from } = location.state || { from: { pathname: '/summary' } };

  useEffect(() => {
    if (currentUser) {
      // we have a current user already logged in
      // we can redirect the user
      history.push(from);
    }
  }, [currentUser]);

  //   let handleSubmit = async (e) => {
  //     e.preventDefault();
  //
  //     try {
  //       setError('');
  //       setLoading(true);
  //       await loginWithEmail(emailRef.current.value, passwordRef.current.value);
  //       history.push(from);
  //     } catch (error) {
  //       setError('error logging in');
  //       console.log('error in logging in');
  //     }
  //     setLoading(false);
  //   };

  const handleSubmit = async (formData) => {
    try {
      setError('');
      await loginWithEmail(formData.email, formData.password);
      history.push(from);
    } catch (error) {
      setError('Wrong email or password. Please try again.');
      console.log('error in logging in', error);
    }
  };

  return (
    <>
      <Background />
      <LayoutAuth>
        <LayoutAuthHeader>
          <H0 alignment="right">Log In</H0>
        </LayoutAuthHeader>
        <LayoutAuthContent>
          <ButtonGoogle action="submit" onClick={() => signInWithGooglePopup()}>
            Sign In With Google
          </ButtonGoogle>
          <Spacer16 />
          <P2 opacity={0.66} alignment="center">
            or
          </P2>
          {/* <ContentDivider /> */}
          <FormOmnipresent onSubmit={null} handleSubmit={handleSubmit}>
            <InputEmail
              name="email"
              label="Your company email"
              placeholder="What's your work email?"
              disabled={false}
              isGWEnabled={false}
              hasGWConnected={false}
              isTransparent={true}
              required={true}
              error={error ? true : false}
            />
            <InputPassword
              name="password"
              label="Your password"
              placeholder="Enter your secret password"
              defaultValue={null}
              disabled={false}
              allowChangePassword={false}
              isTransparent={true}
              required={true}
              error={error}
            />
            <ButtonPrimary action="submit" fixedWidth>
              Log In
            </ButtonPrimary>
          </FormOmnipresent>
          <Spacer48 />
          <P2 opacity={0.66}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </P2>
          <ContentDivider />
          <P2 opacity={0.66}>
            Don't have an account? <Link to="/sign-up">Create an Account</Link>
          </P2>
        </LayoutAuthContent>
      </LayoutAuth>
    </>
  );
}
