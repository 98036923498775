import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

// top: ${(props) =>
// 	props.deepDive && props.activeIndex
// 		? props.activeIndex * 92
// 		: props.activeIndex
// 		? props.activeIndex * 72
// 		: 0}px;
const TOCActive = styled.div`
	position: absolute;
	top: 0;
	/*width: 312px;*/
	width: 100%;
	height: 100%;
	background: #ffffff;
	opacity: 0.66;
	box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.07), 0px 1.8px 2.7px -6px rgba(0, 0, 0, 0.042),
		0px 0.5px 0.8px -6px rgba(0, 0, 0, 0.028);
	border-radius: 12px;
	transform-origin: 0 0;

	${transitionDefault}
`;

export default TOCActive;
