import styled from 'styled-components';

const PointIcon = styled.div`
	flex: 0 20px;
	margin: 0 16px 0 0;

	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default PointIcon;