import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const TableContentRow = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  margin: 0 0 4px 0;
  padding: 0 24px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  /*cursor: pointer;*/

  &:hover {
    background: rgba(0, 0, 0, 0.07);
  }

  ${transitionDefault}
`;

export default TableContentRow;