import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { H1 } from "components/Content/ContentTypography";
import fetchData from "util/fetchData/getChartOrTableData";
import { useLocation, useHistory } from "react-router-dom";
import ReactSelect, { components } from "react-select";
import Flag from "react-world-flags";
import {
  IconEarth,
  IconAndroid,
  IconApple,
  IconRefresh,
} from "components/Icons";
import * as _ from "lodash";
const lookup = require("country-code-lookup");
const Navigate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavigateTitle = styled.div`
  flex: 0 auto;
  padding: 0 48px 0 0;

  h1 {
    margin: 0;
  }
`;

const SegmentInput = styled.input`
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  border-style: none;
  font-size: 1rem;
`;

const SelectButton = styled.button`
  margin-left: 10px;
  white-space: nowrap;
  color: white;
  background-color: black;
  outline: none;
  &:hover {
    opacity: 0.75;
  }

  border-style: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 1rem;
`;

const DeleteButton = styled.button`
  color: black;
  background-color: white;
  outline: none;
  &:hover {
    opacity: 0.75;
  }
  border-style: none;
  border-radius: 100%;
  padding: 10px 20px;
  font-size: 1rem;
`;

const DataList = styled.datalist``;

const FlagFallback = (code) => {
  switch (code.toLowerCase()) {
    case "overall":
      return (
        <IconEarth
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    case "male":
      return <span>👨</span>;
    case "female":
      return <span>👩</span>;
    case "apple":
      return (
        <IconApple
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    case "android":
      return (
        <IconAndroid
          width={20}
          height={20}
          color="black"
          opacity={1}
          style={{ marginRight: "10px" }}
        />
      );
    default:
      return <span>❔</span>;
      break;
  }
};

const singleOption = (props) => (
  <components.Option {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      {props.data.value && (
        <Flag
          height="24"
          width="24"
          style={{ marginRight: "10px" }}
          code={props.data.value.split("_")[0]}
          fallback={FlagFallback(props.data.value)}
        />
      )}
      {props.data.label ?? props.data.value}
    </div>
  </components.Option>
);

const singleValue = (props) => (
  <components.SingleValue {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      {props.data.value && (
        <Flag
          height="24"
          width="24"
          style={{ marginRight: "10px" }}
          code={props.data.value.split("_")[0]}
          fallback={FlagFallback(props.data.value)}
        />
      )}
      {props.data.label ?? props.data.value}
    </div>
  </components.SingleValue>
);
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgb(0,0,0,0.2)",
    background: state.isSelected ? "black" : "white",
    fontColor: state.isSelected ? "white" : "black",
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const color = "black";
    return { ...provided, opacity, transition, color };
  },
};

function QuickNavigate({ title, description }) {
  let location = useLocation();
  let history = useHistory();
  const [segmentList, setSegmentList] = useState(["Default"]);
  const [currentSegment, setCurrentSegment] = useState(null);

  useEffect(() => {
    //fetch the data
    async function fetchChartData() {
      let result = await fetchData("top_level_datasets/analyzability_df.csv");
      //console.log(result);
      const filteredArr = result.reduce(
        (a, o) => (
          o["analyzable"] === "True" &&
            a.push({
              value: o["segment"],
              label: o["full_segment"],
            }),
          a
        ),
        []
      );

      setSegmentList(filteredArr);
    }
    fetchChartData();
    //TODO: set the loading to false
  }, []);

  const handleSegmentChange = (newValue) => {
    setCurrentSegment(newValue);
  };

  const handleSelection = () => {
    console.log("changed segment to: " + currentSegment.value);
    let base = location.pathname.split("/")[1];
    //console.log(base);
    history.push(`/segment?seg=${currentSegment.value}&view=${base}`);
  };

  return (
    <Navigate>
      <NavigateTitle>
        <H1>Quick navigate</H1>
      </NavigateTitle>

      <ReactSelect
        onChange={handleSegmentChange}
        styles={customSelectStyles}
        className="basic-single"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        components={{
          Option: singleOption,
          SingleValue: singleValue,
        }}
        maxMenuHeight={200}
        name="color"
        options={segmentList}
      />

      <SelectButton onClick={handleSelection}>
        {currentSegment
          ? `Check for ${currentSegment.label} `
          : "See Segment Story"}
      </SelectButton>
    </Navigate>
  );
}

export default QuickNavigate;
