import { useState, useEffect } from 'react';
import axios from 'axios';

import GridContainer from 'layouts/PrivateLayout/GridContainer';
import GridContainerContent from 'layouts/PrivateLayout/GridContainerContent';
import GridContainerAside from 'layouts/PrivateLayout/GridContainerAside';
import ContentHeader from 'components/Content/ContentHeader';

import { type, sentiment, loading } from 'Constants';
import StoryEngine from 'components/Engines/StoryEngine';
import TableOfContents from 'components/TableOfContents';
import { useValidationTool } from 'contexts/ValidationTool';
import getStoryJson from 'util/fetchData/getStoryJson';

function Stickiness() {
  const [contentData, setContentData] = useState(loading.overallStory);
  const [hasDataInitialized, setHasDataInitialized] = useState(false);

  let { validationToolConfig } = useValidationTool();

  useEffect(() => {
    console.log(validationToolConfig.currentSegment);
    //fetch the data
    async function fetchData() {
      if (validationToolConfig.currentSegment == 'none') {
        // draw default
        let result = await getStoryJson(`stickiness_module/statements/pm_view/Overall.json`);
        console.log(result);
        setContentData(result.content);
        setHasDataInitialized(true);
      } else {
        // draw default
        let result = await getStoryJson(
          `stickiness_module/statements/pm_view/${validationToolConfig.currentSegment}.json`
        );
        console.log(result);
        setContentData(result.content);
        setHasDataInitialized(true);
      }
    }

    fetchData();
  }, [validationToolConfig]);

  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader key="contentHeader" subtitle="Stickiness" title="Overall Story" />
        <StoryEngine data={contentData} title="Overall Story" />
      </GridContainerContent>
      <GridContainerAside>{hasDataInitialized && <TableOfContents data={contentData} />}</GridContainerAside>
    </GridContainer>
  );
}

export default Stickiness;
