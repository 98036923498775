import React from "react";

import GridContainer from "layouts/PrivateLayout/GridContainer";
import GridContainerContent from "layouts/PrivateLayout/GridContainerContent";
import GridContainerAside from "layouts/PrivateLayout/GridContainerAside";
import ContentHeader from "components/Content/ContentHeader";
import ContentCard from "components/Content/ContentCard";
import Table from "components/Table";

function RetentionTopDrivers() {
  return (
    <GridContainer>
      <GridContainerContent>
        <ContentHeader
          key="contentHeader"
          subtitle="Retention"
          title="Top Drivers"
        />
        <ContentCard>
          <Table
            config={{
              breakdownLevelCol: "breakdown_level",
              analysisForSegmentCol: "analysis_for_segment",
              segmentCol: "segment",
              currentValueCol: "t1",
              oldValueCol: "t2",
              breakdownCol: "breakdown_level",
              timeDeltaCol: "change_time_days",
              intervalCol: "interval",
              showCols: ["full_segment", "t1", "t2", "contribution"],
              labels: [
                "Segments",
                "Retention current",
                "Retention Old",
                "Contribution"
              ],
              subtype: "DRIVER_TABLE",
              path:
                "retention_module/drivers/complete_weekly_retention_driver_data.csv",
              columnStyling:
                "[{}, {}, {}, { factor: '%', color: true , sparkline: 'true' }, {}]"
            }}
            text={"Weekly Retention Drivers"}
          />
        </ContentCard>
      </GridContainerContent>
      <GridContainerAside>
        <></>
      </GridContainerAside>
    </GridContainer>
  );
}

export default RetentionTopDrivers;
