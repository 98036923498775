import styled from "styled-components";

const ContainerChart = styled.div`
	/*margin: 16px 0 16px 0;*/
	margin: 24px 0 40px 0;
	padding: ${props => (props.fullContainer ? "0" : "0 0 28px 28px")};
	${props =>
		props.background == true &&
		`
			background: url(/chart-background.jpg) no-repeat center center;
			background-size: cover;
		`}

	${props =>
		props.backgroundSentiment == true &&
		`
			background: url(/chart-background-scatter.jpg) no-repeat center center;
			background-size: cover;
		`}
	border-radius: 8px;
	overflow: hidden;
	/*border: 1px solid blue;*/
	svg {
		overflow: visible;
		border-radius: 0;

		* {
			font-family: Work Sans, "Work Sans", Inter, Helvetica, Arial, sans-serif;
			line-height: 1;
			/*fill: rgba(255, 0, 0, 0.33);*/
		}
	}
`;

export default ContainerChart;
