import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

const JumpCardIcon = styled.div`
	flex: 0 32px;
	display: flex;
	align-items: center;
	padding: 0 0 0 16px;

	path {
		${transitionOpacity}
	}
`;

export default JumpCardIcon;
