import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const SynopsisCardText = styled.p`
	margin: 0;
	padding: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;

	${(props) => props.sentiment == 'POSITIVE' && 'color: #0CD319;'};
	${(props) => props.sentiment == 'NEUTRAL' && 'color: rgba(0, 0, 0, 0.75);'};
	${(props) => props.sentiment == 'NEGATIVE' && 'color: #FF2A68;'};
	opacity: 1;

	${transitionDefault}
`;

export default SynopsisCardText;
