import styled from 'styled-components';

const JumpCard = styled.div`
	margin: 0 0 16px 0;

	a {
		display: flex;
		padding: 14px 24px;
		background: rgba(42, 0, 12, 0.05);
		border-radius: 8px;
		cursor: pointer;

		color: ${(props) => (props.color ? props.color : '#000000')};
		text-decoration: none;
	}

	&:last-child {
		margin: 0;
	}
`;

export default JumpCard;
