import styled from 'styled-components';

const ItemGroupBar = styled.div`
	position: absolute;
	top: 0;
	left: 1px;
	width: 1px;
	height: 100%;
	background: #000000;
	border-radius: 2px;
	opacity: 0.1;
`;

export default ItemGroupBar;