import { useState, useEffect } from "react";
import styled from "styled-components";
import { H5 } from "components/Content/ContentTypography";
import { stylesNoSelect, transitionDefault } from "styles/Styles";

import Input from "../Input";
import InputLabel from "../InputLabel";
import InputField from "../InputField";
import InputError from "../InputError";
import InputErrorText from "../InputErrorText";

const SelectOption = styled.div`
	max-width: ${props => (props.maxWidth ? "672px" : "100%")};

	display: flex;
	align-items: center;

	width: 100%;
	height: 56px;

	margin: 0 0 8px 0;
	padding: 0 20px 0 18px;

	font-size: 18px;
	line-height: 32px;
	font-weight: 400;

	background: ${props =>
		props.disabled ? "rgba(255, 255, 255, 0.12)" : "rgba(255, 255, 255, 0)"};
	color: ${props =>
		props.disabled ? "rgba(0, 0, 0, 0.33)" : "rgba(0, 0, 0, 0.5)"};

	${props =>
		props.disabled &&
		props.defaultValue != null &&
		"background: rgba(255, 255, 255, 1) !important;"}

	border: ${props =>
		props.error
			? `1px solid ${props.theme.colors.colorRed}`
			: props.disabled
			? "1px solid rgba(33, 0, 0, 0.12)"
			: "1px solid rgba(33, 0, 0, 0.2)"};

	${props =>
		props.isSelected &&
		`
			border: 1px solid ${props.theme.colors.colorGreen} !important;
			color: rgba(0, 0, 0, 0.75) !important;
		`};

	border-radius: 8px;
	outline: none;
	cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

	&:hover {
		background: ${props =>
			props.isTransparent
				? "rgba(255, 255, 255, 0.15)"
				: "rgba(255, 237, 237, 0.33)"};
		border: 1px solid rgba(33, 0, 0, 0.33);
	}

	${stylesNoSelect}
	${transitionDefault}

	&:last-of-type {
		margin: 0;
	}
`;

const SelectOptionRadio = styled.div`
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 16px 0 0;
	border: ${props =>
		props.isSelected
			? `1px solid ${props.theme.colors.colorGreen}`
			: "1px solid rgba(0, 0, 0, 0.33)"};
	border-radius: 50%;

	&:after {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: ${props => props.theme.colors.colorGreen};
		opacity: ${props => (props.isSelected ? 1 : 0)};
		${transitionDefault}
	}

	${transitionDefault}
`;

function InputSelectVertical({
	name,
	label,
	data,
	error,
	onChange,
	defaultValue,
	isTransparent,
	isFaded,
	required
}) {
	const [value, setValue] = useState(defaultValue);
	const [valueIndex, setValueIndex] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, []);

	useEffect(() => {
		if (error != "" && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	const onChangeHelper = (value, index) => {
		setValue(value);
		setValueIndex(index);
		onChange(name, value);
	};

	return (
		<Input isFaded={isFaded}>
			<InputLabel required={required}>
				<H5 opacity={0.5}>{label}</H5>
			</InputLabel>
			{data.map((option, index) => (
				<SelectOption
					isTransparent={isTransparent}
					isSelected={index == valueIndex}
					onClick={() => onChangeHelper(option.text, index)}
				>
					<SelectOptionRadio isSelected={index == valueIndex} />
					{option.text}
				</SelectOption>
			))}
			<InputError error={error}>
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
		</Input>
	);
}

export default InputSelectVertical;
