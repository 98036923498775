import { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";

import { H4 } from "components/Content/ContentTypography";
import { Spacer8, Spacer32 } from "components/Spacer";

import ContainerContentHeader from "./ContainerContentHeader";
import ContentHeaderTitle from "./ContentHeaderTitle";

function ContentHeader({ page, subpage, subtitle, title }) {
  const theme = useContext(ThemeContext);
  const colorData = {
    growth: theme.colors.accentGrowth,
    stickiness: theme.colors.accentStickiness,
    retention: theme.colors.accentRetention,
    none: "#000000"
  };

  return (
    <ContainerContentHeader>
      <H4
        color={colorData[subtitle ? subtitle.toLowerCase() : "none"]}
        opacity={1}
      >
        {subtitle}
      </H4>
      <Spacer8 />
      <ContentHeaderTitle
        color={colorData[subtitle ? subtitle.toLowerCase() : "none"]}
        opacity={1}
      >
        {title}
      </ContentHeaderTitle>
    </ContainerContentHeader>
  );
}

export default ContentHeader;
