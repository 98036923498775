import { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { H5, H6 } from "components/Content/ContentTypography";
import { transitionDefault, transitionDefaultFast } from "styles/Styles";
import { Spacer8 } from "components/Spacer";

import Input from "../Input";
import InputLabel from "../InputLabel";
import InputField from "../InputField";
import InputError from "../InputError";
import InputErrorText from "../InputErrorText";
import ChangePassword from "./ChangePassword";
import PeekPassword from "./PeekPassword";

function InputPassword({
	name,
	description,
	label,
	placeholder,
	defaultValue,
	onChange,
	onFocus,
	onBlur,
	index,
	isFaded,
	required,
	error,
	disabled,
	allowChangePassword,
	isTransparent
}) {
	const theme = useContext(ThemeContext);
	const [value, setValue] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);
	const [isHovering, setIsHovering] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, []);

	useEffect(() => {
		if (error != "" && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	const onChangeHelper = value => {
		setValue(value);
		onChange(name, value);
	};

	const onMouseOver = () => {
		setIsHovering(true);
		// console.log('mouse enter input field');
	};

	const onMouseLeave = () => {
		setIsHovering(false);
		// console.log('mouse LEAVE input field');
	};

	const onToggleIsPasswordVisible = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	return (
		<Input isFaded={isFaded}>
			<InputLabel error={error} required={required}>
				<H5
					opacity={0.5}
					// opacity={error ? 1 : 0.5}
					// color={error && theme.colors.colorRed}
				>
					{label}
				</H5>
			</InputLabel>
			<InputField
				type={isPasswordVisible ? "text" : "password"}
				placeholder={placeholder}
				onChange={e => onChangeHelper(e.target.value)}
				onFocus={() => onFocus(index)}
				onBlur={() => onBlur(index)}
				// onMouseEnter={onMouseEnter}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				isHovering={isHovering}
				value={value}
				required={required}
				isFaded={isFaded}
				error={error != null && error != "" && valueOnDelayedError == value}
				disabled={disabled}
				defaultValue={defaultValue}
				isTransparent={isTransparent}
			/>
			{description && (
				<>
					<Spacer8 />
					<H6 opacity={0.5}>{description}</H6>
				</>
			)}
			{allowChangePassword ? (
				<ChangePassword isHovering={isHovering}>Change Password</ChangePassword>
			) : (
				<PeekPassword
					onClick={onToggleIsPasswordVisible}
					isPasswordVisible={isPasswordVisible}
				/>
			)}
			<InputError error={error}>
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
		</Input>
	);
}

export default InputPassword;
