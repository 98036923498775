import React from "react";
import ReactDOM from "react-dom";
import "styles/index.css";
//import App from "./App";
import AppRoutes from "./AppRoutes";
//import reportWebVitals from "./reportWebVitals";
import Theme from "contexts/Theme";
import { AuthProvider } from "contexts/AuthContext";
import { ValidationToolProvider } from "contexts/ValidationTool";

ReactDOM.render(
  <React.StrictMode>
    <ValidationToolProvider>
      <AuthProvider>
        <Theme>
          <AppRoutes />
        </Theme>
      </AuthProvider>
    </ValidationToolProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
