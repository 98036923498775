import { useState } from 'react';
import SynopsisCard from './SynopsisCard';
import SynopsisCardText from './SynopsisCardText';

function ContentSynopsis({ sentiment, children }) {
	return (
		<SynopsisCard sentiment={sentiment}>
			<SynopsisCardText sentiment={sentiment}>{children}</SynopsisCardText>
		</SynopsisCard>
	);
}

export default ContentSynopsis;
