import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

// absolute position
// calculate how much width this adds
// then update the width via framer motion
const Deep = styled.button`
  position: relative;
  height: 48px;
  margin: 24px 0 0 0;
  padding: 0;

  background: rgba(42, 0, 12, 0.04);
  border: none;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important;

  cursor: pointer;
  ${transitionDefault};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 48px 0 24px;
    text-decoration: none;
  }
`;

export default Deep;