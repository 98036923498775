//experimental
import React, { useState } from "react";
import { theme } from "contexts/Theme";
import { scaleLinear, scaleUtc } from "@visx/scale";
import { LinePath, Bar, Line } from "@visx/shape";
import * as _ from "lodash";
import { Group } from "@visx/group";
import { withParentSize } from "@visx/responsive";
import { AxisLeft, AxisBottom } from "@visx/axis";
import {
  getMaxValueInMatrix,
  getMinValueInMatrix,
  getStringFromLargeNumber,
  getShortenedMonth,
} from "components/Chart/functions";
import {
  useTooltip,
  useTooltipInPortal,
  TooltipWithBounds,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "d3-array";
var randomColor = require("randomcolor");

const ChartToRender = ({ data, config, parentWidth, parentHeight }) => {
  const bisectArray = bisector((array) => array[config.xAxisCol]).left;

  //usetooltip hook
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const [noData, setNoData] = useState(false);

  const axisLeftOffset = 16;

  // tooltip handler
  const handleTooltip = (event, datum) => {
    // happens everytime pointer position changes inside portal

    let coords = localPoint(event);
    coords.x = coords.x - chartML - axisLeftOffset;
    let tooltipTopArray = [];
    let tooltipDataArray = [];
    config.dataCols.map((element, idx) => {
      let index = bisectArray(data, xScale.invert(coords.x));
      tooltipTopArray.push(yScale(data[index][element]));
      tooltipDataArray.push({
        value: data[index][element],
        label: config.labels ? config.labels[idx] : element,
      });
    });

    // update if there is no data
    setNoData(tooltipDataArray.every((el) => el.value === ""));

    showTooltip({
      tooltipLeft: coords.x,
      //tooltipTop: coords.y - chartMT,
      tooltipTop: tooltipTopArray,
      tooltipData: tooltipDataArray,
    });
  };

  //convert date column to date object
  if (config.xAxisDate) {
    data.forEach(
      (element) =>
        (element[config.xAxisCol] = new Date(element[config.xAxisCol]))
    );
  }
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  // let chartHeight = 338;
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;

  // console.table(chartWidth, chartHeight, chartML, chartMR, chartMT, chartMB);
  // getter functions for scale

  // const getX = d => d[config.xAxisCol];
  const getX = (d) => d[config.xAxisCol];
  const getY = (d, element) => d[element];

  // scales
  //y scale
  const yScale = scaleLinear({
    domain: [
      config?.yStartValue ?? getMinValueInMatrix(data, config.dataCols),
      config?.yEndValue ?? getMaxValueInMatrix(data, config.dataCols),
    ],
    range: [chartHeight - chartMB - chartMT, 0],
    nice: true,
  });

  // x scale

  let xScale;

  if (config.xAxisDate) {
    xScale = scaleUtc({
      domain: [
        data[0][config.xAxisCol],
        data[data.length - 1][config.xAxisCol],
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset],
    });
  } else {
    // x axis is linear
    xScale = scaleLinear({
      domain: [
        config?.yStartValue ?? 0,
        config?.yEndValue ?? getMaxValueInMatrix(data, [config.xAxisCol]),
      ],
      range: [0, chartWidth - chartML - chartMR - axisLeftOffset],
      nice: config?.xScaleNice ?? false,
    });
  }

  // console.log("Margins", chartML, chartMR);

  let lineColorsData = [
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#0000ff",
    "#ff0000",
    "#00ff00",
    "#0000ff",
  ];

  const lineColors = (index) => {
    return lineColorsData[index];
  };

  return (
    <svg
      ref={containerRef}
      // className="chart"
      width={chartWidth}
      height={chartHeight}
      // style={{ overflow: "visible" }}
    >
      <Group top={chartMT} left={chartML}>
        <rect
          fill={theme.chart.colors.background}
          opacity={0}
          // opacity={theme.chart.colors.backgroundOpacity}
          // opacity={0.05}
          // width={chartWidth}
          width={chartWidth - chartML - chartMR}
          height={chartHeight - chartMT - chartMB}
          rx={6}
        />
        <AxisLeft
          labelClassName={config?.yAxisLabelClassName ?? " "}
          scale={yScale}
          label={config.yAxisLabel}
          labelProps={{
            fill: "#978695",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "14px",
            textAnchor: "middle",
            backgroundFill: "#ff0000",
            dx: -18,
            dy: (chartMT + chartMB) / 2 - 3,
          }}
          left={chartWidth - chartML - chartMR + axisLeftOffset}
          tickLength={chartWidth - chartML - chartMR}
          numTicks={5}
          tickFormat={(d) => getStringFromLargeNumber(d, config.yTickFormat)}
          stroke={theme.chart.colors.stroke}
          tickStroke={theme.chart.colors.tickStroke}
          tickLabelProps={(value, index) => ({
            fill: "#a391a0",
            fontSize: "12px",
            fontWeight: 500,
            textAnchor: "end",
            dx: -8,
            dy: 4,
          })}
          hideAxisLine={true}
          // tickClassName={"opacity-75"}
          // labelClassName={"opacity-75"}
        />
        <AxisBottom
          labelClassName={config?.yAxisLabelClassName ?? " "}
          label={config.xAxisLabel}
          labelProps={{
            fill: "#978695",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "14px",
            textAnchor: "middle",
            y: chartHeight + chartMB - 6,
            dx: -chartML - axisLeftOffset / 2,
            // backgroundFill: "#ff0000"
          }}
          // top={chartHeight - chartMT - chartMB}
          top={0}
          tickLength={chartHeight - chartMT - chartMB}
          tickFormat={(d) => getShortenedMonth(d, config.xAxisDate)}
          scale={xScale}
          stroke={theme.chart.colors.stroke}
          tickStroke={theme.chart.colors.tickStroke}
          tickLabelProps={(value, index) => ({
            fill: "#a391a0",
            fontSize: "12px",
            fontWeight: 500,
            textAnchor: "middle",
            dy: 10,
            // dx: axisLeftOffset
          })}
          left={axisLeftOffset}
          // tickClassName="chart__grid-tick"
          hideAxisLine={true}
          // tickLabelProps={{
          //   fill: "#555555",
          //   fontSize: "16px",
          //   textAnchor: "middle"
          // }}
          // tickClassName={"opacity-75"}
          // labelClassName={"opacity-75 "}
        />
        <Group left={axisLeftOffset}>
          {!noData &&
            tooltipOpen &&
            tooltipTop?.map((element, index) => (
              <>
                <TooltipInPortal
                  // set this to random so it correctly updates with parent bounds
                  key={Math.random()}
                  top={element}
                  left={tooltipLeft + 50}
                >
                  {tooltipData[index].label}{" "}
                  <strong>{tooltipData[index].value.toFixed(2)}</strong>
                </TooltipInPortal>
                <circle
                  cx={tooltipLeft}
                  cy={element + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={element}
                  r={4}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </>
            ))}

          {config.dataCols.map((element, index) => (
            <LinePath
              defined={(d) =>
                d[element] !== 0 && d[element] !== "" && d[element] !== null
              }
              key={element}
              data={data}
              // x={d => xScale(getX(d))}
              // y={d => yScale(getY(d, element))}
              // // stroke={randomColor({
              // //   luminosity: "dark",
              // //   hue: "blue"
              // // })}
              // stroke={theme.chart.colors.lines[index]}
              // // stroke={"#ff0000"}
              // // stroke="#000"
              // // stroke={lineColorsData[index]}
              // strokeWidth={1}
              // strokeOpacity={1}

              strokeWidth={1.3}
              x={(d) => xScale(getX(d))}
              // x={d => xScale(getX(d)) + 16}
              y={(d) => yScale(getY(d, element))}
              // stroke={randomColor({
              //   luminosity: "bright",
              //   seed: index + element
              // })}
              stroke={theme.chart.colors.lines[index]}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          ))}
          {!noData && tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: -2 }}
                to={{ x: tooltipLeft, y: chartHeight - chartMT - chartMB + 4 }}
                strokeWidth={1}
                stroke="#80787b"
                pointerEvents="none"
                // strokeDasharray="2,6"
              />
            </g>
          )}
          {/* this is a bar that gives the onclick event location for tooltip */}
          <Bar
            width={chartWidth - chartML - chartMR}
            height={chartHeight - chartMT - chartMB}
            fill="transparent"
            // rx={8}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>
      </Group>
    </svg>
  );
};

let LineChart = withParentSize(ChartToRender);

export default LineChart;
