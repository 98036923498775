import styled from "styled-components";

const ContainerPerson = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const PersonPicture = styled.div`
	display: flex;
	align-items: center;
	width: 24px;
	height: 24px;
	text-align: center;
	border-radius: 24px;
	background: rgba(0, 0, 0, 0.66);
`;

const PersonPictureInitial = styled.h5`
	margin: -1px auto 0 auto;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	opacity: 0.8;
	font-weight: 500;
	color: #ffffff;
`;

const PersonName = styled.h5`
	margin: 0 0 0 8px;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	color: #000000;
	opacity: ${props => (props.isPersonExpanded ? 0.75 : 0.66)};
`;

function Person({ name }) {
	return (
		<ContainerPerson>
			<PersonPicture>
				<PersonPictureInitial>
					{name && name.length > 0 ? name.slice(0, 1) : ""}
				</PersonPictureInitial>
			</PersonPicture>
			<PersonName>{name}</PersonName>
		</ContainerPerson>
	);
}

export default Person;
