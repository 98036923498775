//experimental
import React, { useState } from "react";
import { theme } from "contexts/Theme";
import { Line, Circle } from "@visx/shape";
import { Group } from "@visx/group";
import { withParentSize } from "@visx/responsive";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";
var randomColor = require("randomcolor");

const ChartToRender = ({ data, config, parentWidth, parentHeight }) => {
  // chart dimension
  let chartWidth = parentWidth;
  let chartHeight =
    parentWidth * (config?.chartRatio ?? theme.chart.chartRatio);
  let chartML = config?.marginLeft ?? theme.chart.marginLeft;
  let chartMR = config?.marginRight ?? theme.chart.marginRight;
  let chartMT = config?.marginTop ?? theme.chart.marginTop;
  let chartMB = config?.marginBottom ?? theme.chart.marginBottom;
  // let InnerWidth = chartWidth - chartML - chartMR;
  // let InnerHeight = chartHeight - chartMT - chartMB;
  let InnerWidth = chartWidth;
  let InnerHeight = chartHeight + 4;

  let circlePosX = data[0].x_cord + 2;
  let circlePosY = 2 - data[0].y_cord;

  chartML = 0; // override by @JAYMO
  chartMR = 0; // override by @JAYMO

  // tooltip systems
  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    maxWidth: 300,
    backgroundColor: "rgba(0,0,0,0.66)",
    color: "white",
    lineHeight: 1.5,
    padding: "12px 16px",
    borderRadius: 8
  };
  let tooltipTimeout;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true
  });

  // console.log("QUADRANT config", config);

  return (
    <div style={{ position: "relative" }}>
      <svg ref={containerRef} width={chartWidth} height={chartHeight}>
        {/* <Group top={chartMT} left={chartML}> */}
        <Group>
          <rect
            fill="black"
            opacity={0}
            // opacity={0.03}
            width={InnerWidth}
            height={InnerHeight}
            // rx={8}
          />
          <Line
            from={{ x: 0, y: 0 }}
            to={{
              x: InnerWidth,
              y: 0
            }}
            strokeWidth={1.3}
            stroke="black"
            opacity={0.07}
            pointerEvents="none"
            // strokeDasharray="12,8"
          />
          <Line
            from={{ x: InnerWidth / 2, y: 0 }}
            to={{
              x: InnerWidth / 2,
              y: InnerHeight
            }}
            strokeWidth={1.3}
            stroke="black"
            opacity={0.15}
            pointerEvents="none"
            strokeDasharray="12,8"
          />
          <Line
            from={{ x: 0, y: InnerHeight / 2 }}
            to={{
              x: InnerWidth,
              y: InnerHeight / 2
            }}
            strokeWidth={1.3}
            stroke="black"
            opacity={0.15}
            pointerEvents="none"
            strokeDasharray="12,8"
          />
          <Text
            x={InnerWidth / 4}
            y={InnerHeight / 4 - 8}
            // width={100}
            textAnchor={"middle"}
            verticalAnchor={"start"}
            fontSize={16}
            lineHeight={24}
            fontWeight={500}
            opacity={0.5}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: {
                  title: "Leaky Bucket",
                  subTitle:
                    "The company is growing but not retaining users, which is not sustainable"
                },
                tooltipTop: eventSvgCoords?.y + 20,
                tooltipLeft: eventSvgCoords?.x
              });
            }}
          >
            Leaky Bucket
          </Text>
          <Text
            x={(InnerWidth * 3) / 4}
            y={InnerHeight / 4 - 8}
            // width={100}
            textAnchor={"middle"}
            verticalAnchor={"start"}
            fontSize={16}
            lineHeight={24}
            fontWeight={500}
            opacity={0.5}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: {
                  title: "Sustainable Growth",
                  subTitle:
                    "The company is growing while retaining users, which is sustainable."
                },
                tooltipTop: eventSvgCoords?.y + 20,
                tooltipLeft: eventSvgCoords?.x
              });
            }}
          >
            Sustainable Growth
          </Text>
          <Text
            x={InnerWidth / 4}
            y={(InnerHeight * 3) / 4 - 8}
            // width={100}
            textAnchor={"middle"}
            verticalAnchor={"start"}
            fontSize={16}
            lineHeight={24}
            fontWeight={500}
            opacity={0.5}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: {
                  title: "Death Spiral",
                  subTitle:
                    "The company is not growing, and it is also not retaining users."
                },
                tooltipTop: eventSvgCoords?.y + 20,
                tooltipLeft: eventSvgCoords?.x
              });
            }}
          >
            Death Spiral
          </Text>
          <Text
            x={(InnerWidth * 3) / 4}
            y={(InnerHeight * 3) / 4 - 12 - 8}
            width={150}
            textAnchor={"middle"}
            verticalAnchor={"start"}
            fontSize={16}
            lineHeight={24}
            fontWeight={500}
            opacity={0.5}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: {
                  title: "Small Market or End of Life",
                  subTitle:
                    "Retention is good, but the product is no longer growing."
                },
                tooltipTop: eventSvgCoords?.y + 20,
                tooltipLeft: eventSvgCoords?.x
              });
            }}
          >
            Small Market or End of Life
          </Text>
          <Circle
            cx={(circlePosX * InnerWidth) / 4}
            cy={(circlePosY * InnerHeight) / 4}
            r={8}
            strokeWidth={1}
            fill={"#17D48E"}
            onMouseLeave={() => {
              tooltipTimeout = window.setTimeout(() => {
                hideTooltip();
              }, 300);
            }}
            onMouseMove={event => {
              if (tooltipTimeout) clearTimeout(tooltipTimeout);
              const eventSvgCoords = localPoint(event);
              showTooltip({
                tooltipData: {
                  title: config.dotTitle ?? "Picsart",
                  subTitle: config.dotSubTitle ?? "Picsart is currently here."
                },
                tooltipTop: eventSvgCoords?.y,
                tooltipLeft: eventSvgCoords?.x
              });
            }}
          />
          <Text
            x={(circlePosX * InnerWidth) / 4}
            y={(circlePosY * InnerHeight) / 4 + 20}
            width={150}
            textAnchor={"middle"}
            verticalAnchor={"start"}
            fontSize={16}
            lineHeight={24}
            fontWeight={600}
            opacity={0.75}
          >
            {config?.dotTitle ?? "PicsArt"}
          </Text>
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div style={{ color: "white", fontSize: 16, marginBottom: 4 }}>
            <strong>{tooltipData.title}</strong>
          </div>
          <div>{tooltipData.subTitle}</div>
        </TooltipInPortal>
      )}
    </div>
  );
};

let QuadrantChart = withParentSize(ChartToRender);

export default QuadrantChart;
