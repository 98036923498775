import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IconRight } from "components/Icons";
import { H3 } from "components/Content/ContentTypography";

import Deep from "./Deep";
import DeepContent from "./DeepContent";
import DeepContentIcon from "./DeepContentIcon";

function ContentAnchorDeep(props) {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  // isValid = true;

  return (
    <Deep
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isHovering={isHovering}
    >
      <Link to={props.url}>
        <DeepContent isHovering={isHovering}>
          {props.children}
          <DeepContentIcon isHovering={isHovering}>
            <IconRight color="#000000" opacity={0.75} />
          </DeepContentIcon>
        </DeepContent>
      </Link>
    </Deep>
  );
}

export default ContentAnchorDeep;
