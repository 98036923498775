import styled from 'styled-components';

const TableContentRowItem = styled.p`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : '#000000')};
  opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};
`;

export default TableContentRowItem;