import styled from 'styled-components';
import { transitionOpacity } from 'styles/Styles';

const ContentNumberIndex = styled.span`
	display: inline-block;
	flex: 0 24px;
	width: 24px;
	/*background: rgba(255, 0, 0, 0.1);*/
	margin: 0 7px 0 7px;
	opacity: 0.66;
	text-align: center;

	${transitionOpacity}

	/*border: 1px solid blue;*/
`;

export default ContentNumberIndex;
