import React from 'react';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import styled from 'styled-components';

let Wrapper = styled.div`
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  padding-top: 20vh;

  /*justify-content: space-between;*/
`;

function PublicLayout({ children }) {
  return (
    <>
      <Navbar />
      <Wrapper>
        {children}
        {/* <Footer /> */}
      </Wrapper>
    </>
  );
}

export default PublicLayout;
