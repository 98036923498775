import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const InputErrorText = styled.p`
	position: relative;
	top: 8px;
	/*margin: 8px 0 0 0;*/
	margin: 0;
	color: ${(props) => props.theme.colors.colorRed};
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	/*border: 1px solid green;*/
	/*background: rgba(0, 0, 255, 0.2);*/
	${transitionDefault}
`;
// filter: ${(props) => (props.isFaded ? 'blur(0.5px)' : 'blur(0px)')};

export default InputErrorText;
