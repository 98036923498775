import styled from 'styled-components';

const SubsectionSideTrailLine = styled.div`
	position: relative;
	top: ${(props) => (props.fullLine ? 0 : 16)}px;
	width: 2px;
	height: ${(props) => (props.fullLine ? '100%' : 'calc(100% - 16px)')};
	margin: 0 auto;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 2px;
`;

export default SubsectionSideTrailLine;