import styled from 'styled-components';
import { transitionDefault } from 'styles/Styles';

const ContainerTOC = styled.div`
	position: -webkit-sticky;
	position: sticky;
	top: 40px;
	${transitionDefault}
`;

export default ContainerTOC;
