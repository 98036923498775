import styled from 'styled-components';

// max-width: ${(props) => (props.viewportWidth ? props.viewportWidth + 'px' : '100%')};
const LayoutGrid = styled.main`
	width: 100%;
	margin: 0 auto;
	padding: 0;

	display: grid;
	grid-template-columns: [sidebar-start] 256px [sidebar-end] 0px [container-start] 752px [sideContent-start] 1fr [container-end];

	/*background: rgba(255, 0, 0, 0.2);
	border: 1px solid blue;*/
`;

export default LayoutGrid;
