import styled from 'styled-components';

const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;

	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;

	background: #ffeded;
	background: url('/background.jpg') no-repeat center center;
	/*background: #000 !important;*/
	background-size: cover;
`;

export default Background;
