import styled from 'styled-components';

const GridContainerContent = styled.div`
	grid-column-start: content-start;
	grid-column-end: content-end;

	margin: 0;
	padding: 64px 0;
	/*border: 1px solid green;*/
`;

export default GridContainerContent;