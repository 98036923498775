import styled from 'styled-components';
import { H3 } from 'components/Content/ContentTypography';
import { Spacer8 } from 'components/Spacer';
import ContainerChart from './ContainerChart';

const Placeholder = styled.div`
	width: 100%;
	height: 256px;
	margin: 0;
	padding: 0;
	background: rgba(0, 0, 0, 0.07);
	border-radius: 8px;
`;

export default function ContentChart({ title }) {
	return (
		<ContainerChart className="chart graph table">
			{title && (
				<>
					<H3 alignment="center">{title}</H3>
					<Spacer8 />
				</>
			)}
			<Placeholder />
		</ContainerChart>
	);
}
