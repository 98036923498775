import { motion, useAnimation } from "framer-motion";
import { IconDown } from "components/Icons";
import { transitionSpring } from "Constants";

import ExpandCollapse from "./ExpandCollapse";
import ExpandCollapseIcon from "./ExpandCollapseIcon";

const TableExpandCollapse = ({
  handleRowExpand,
  handleRowCollapse,
  isCollapsed
}) => {
  const animateExpandCollapse = useAnimation();

  const handleOnClick = () => {
    if (isCollapsed) {
      handleRowExpand();
      animateExpandCollapse.start({
        position: "sticky",
        bottom: 48,
        transition: transitionSpring
      });
    } else {
      handleRowCollapse();
      animateExpandCollapse.start({
        position: "relative",
        bottom: 0,
        transition: transitionSpring
      });
    }
  };

  return (
    <motion.div
      animate={animateExpandCollapse}
      initial={{ position: "relative" }}
    >
      <ExpandCollapse isCollapsed={isCollapsed} onClick={handleOnClick}>
        {isCollapsed ? "More Rows" : "Less Rows"}
        <ExpandCollapseIcon
          className="expand-collapse__icon"
          isCollapsed={isCollapsed}
        >
          <IconDown
            width={16}
            height={16}
            color={isCollapsed ? "#000000" : "#ffffff"}
            opacity={isCollapsed ? 0.75 : 1}
          />
        </ExpandCollapseIcon>
      </ExpandCollapse>
    </motion.div>
  );
};

export default TableExpandCollapse;
