import styled from 'styled-components';
import { stylesNoSelect } from 'styles/Styles';

const GhostItemGroup = styled.div`
	position: absolute;
	opacity: 0;
	${stylesNoSelect}
`;

export default GhostItemGroup;
