import { useState, useEffect, useRef, createRef, useCallback } from 'react';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { motion, useAnimation } from 'framer-motion';
import debounce from 'lodash.debounce';
// import throttle from 'lodash.throttle';

import { type, sentiment, transitionSpring, transitionTween } from 'Constants';
import { Spacer4 } from 'components/Spacer';
import { H5 } from 'components/Content/ContentTypography';
import { transitionDefault } from 'styles/Styles';

import ContainerTOC from './ContainerTOC';
import TOCActive from './TOCActive';
import TOCCard from './TOCCard';
import TOCCardContent from './TOCCardContent';

function TableOfContents({ data }) {
	const [activeIndex, setActiveIndex] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);
	const animateContainer = useAnimation();
	const animateActive = useAnimation();
	const refContainer = useRef();

	const cleanData = (preCleaning) => {
		let postCleaning = [];

		preCleaning.map((block, index) => {
			if (block.type == type.SECTION) {
				if (index == 0 && block.subtitle == null) {
					postCleaning.push({
						subtitle: 'Introduction',
						title: block.title,
						href: '#introduction',
					});
				} else if (block.subtitle != null) {
					postCleaning.push({
						subtitle: block.subtitle,
						title: block.title,
						href: `#${block.subtitle.replace(' ', '-').toLowerCase()}`,
					});
				}
			}
		});

		return postCleaning;
	};

	let dataCleaned = cleanData(data);
	const refsTOC = useRef(dataCleaned.map(() => createRef()));

	useEffect(() => {
		animateContainer.start({ opacity: 1, transition: transitionTween });

		// Update height for when text is too long
		if (refsTOC && refsTOC.current[0] && refsTOC.current[0].current) {
			let targetBoundingBox = refsTOC.current[0].current.getBoundingClientRect();
			animateActive.start({ height: targetBoundingBox.height });
		}

		window.addEventListener('scroll', onScrollDebounced);

		return () => {
			window.removeEventListener('scroll', onScrollDebounced);
		};
	}, []);

	// useEffect(() => {
	// 	console.log('State updated?');
	// 	console.log('isAnimating', isAnimating);
	// });

	const animateChange = async (targetBoundingBox, containerBoundingBox) => {
		// animateActive.start({ y: targetBoundingBox.y - 40, height: targetBoundingBox.height });
		await animateActive.start({
			y: targetBoundingBox.y - containerBoundingBox.y,
			height: targetBoundingBox.height,
			transition: transitionSpring,
		});
		return await setIsAnimating(false);
	};

	const onChangeChapter = (newIndex) => {
		// console.log('jump to new index onChangeChapter', newIndex);
		setActiveIndex(newIndex);
		setIsAnimating(true);

		let targetBoundingBox = { y: 40, height: 72 };
		// console.log('ref', refsTOC);
		if (refsTOC && refsTOC.current[newIndex] && refsTOC.current[newIndex].current) {
			// console.log('RefFound1', refsTOC.current[newIndex]);
			targetBoundingBox = refsTOC.current[newIndex].current.getBoundingClientRect();
			// console.log('RefFound2', targetBoundingBox);
		}

		let containerBoundingBox = { y: 40 };
		if (refContainer && refContainer.current) {
			containerBoundingBox = refContainer.current.getBoundingClientRect();
			// console.log('containerBoundingBoxFound', containerBoundingBox);
		}

		// console.log('------------------');
		animateChange(targetBoundingBox, containerBoundingBox);
	};

	const onScroll = () => {
		if (!isAnimating) {
			if (refsTOC && refsTOC.current && refsTOC.current.length > 0) {
				let activeIndexTemp = 0;

				if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 48) {
					console.log('WindowscrollY', window.scrollY);
					console.log('scrollHeight', document.body.scrollHeight);
					activeIndexTemp = refsTOC.current.length - 1;
				} else {
					for (let i = 0; i < refsTOC.current.length; i++) {
						if (
							dataCleaned[i] &&
							dataCleaned[i].href &&
							document.querySelector(dataCleaned[i].href) &&
							document.querySelector(dataCleaned[i].href).getBoundingClientRect().top <= 0
						) {
							activeIndexTemp = i;
						}
					}
				}
				// NOTE FROM JAY MO
				// I don't know why the below line works, but not if it has the if clause.
				// maybe I'm just too brain tired right now
				// but in terms of performance it seems.. fine. so moving on
				onChangeChapter(activeIndexTemp);
				// if (activeIndexTemp != activeIndex) {
				// 	onChangeChapter(activeIndexTemp);
				// }
			}
		} else {
			console.log('DISABLED but scrolling');
		}
	};

	const debouncedScroll = useCallback(debounce(onScroll, 250), []);

	const onScrollDebounced = () => {
		// const debouncedScroll = debounce(() => onScroll, 1000);
		debouncedScroll();
	};

	return (
		<ContainerTOC ref={refContainer}>
			<motion.div animate={animateContainer} initial={{ opacity: 0 }}>
				<motion.div
					animate={animateActive}
					initial={{
						position: 'absolute',
						y: 0,
						width: '100%',
						height: 76,
						transformOrigin: '0 0',
					}}
				>
					<TOCActive />
				</motion.div>
				{dataCleaned.map((chapter, index) => (
					<TOCCard ref={refsTOC.current[index]} key={`TOCCard--${index}`}>
						<AnchorLink href={chapter.href}>
							<TOCCardContent onClick={() => onChangeChapter(index)}>
								<H5 opacity={index == activeIndex ? 0.33 : 0.15}>{chapter.subtitle}</H5>
								<Spacer4 />
								<H5 opacity={index == activeIndex ? 0.75 : 0.5}>{chapter.title}</H5>
							</TOCCardContent>
						</AnchorLink>
					</TOCCard>
				))}
			</motion.div>
		</ContainerTOC>
	);
}

export default TableOfContents;
